<template>
<div class="comment-container">
  <div class="header-container">
    <initials-avatar :color="this.initialsColor" :initials="this.initials"></initials-avatar>
    <div style="margin-left: 10px; display: flex; flex-direction: column-reverse;">
      <span style="font-weight: normal;">{{this.comment.author}}</span>
      <span style="color:grey; font-size: 12px">{{this.comment.creationDate}}</span>
    </div>
  </div>
  <div class="commentWrapper">
    <span>{{this.comment.text}}</span>
  </div>
  </div>
  <div class="likeButton">
    <span @click="this.likeComment">{{this.$t('like')}}</span>
    <div class="iconWrapper">
      <svg v-if="likes > 0" @click="this.likeComment"  class="thumbs-up" xmlns="http://www.w3.org/2000/svg" height="22px" viewBox="0 0 24 24" width="22px" fill="#000000"><path d="M0 0h24v24H0V0zm0 0h24v24H0V0z" fill="none"/><path d="M9 21h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-2c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 1 7.58 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2zM9 9l4.34-4.34L12 10h9v2l-3 7H9V9zM1 9h4v12H1z"/></svg>
      <span v-if="likes > 0" style="margin-left:5px;cursor:default">{{likes}}</span>
    </div>
  </div>

</template>

<script>

import InitialsAvatar from "../avatar/InitialsAvatar";
import SweetAlerts from "@/mixins/sweet-alerts/SweetAlerts";

export default {
  name: "SingleComment",
  components: {InitialsAvatar},
  mixins:[SweetAlerts],
  data(){
    return{
      comment: this.commentProp
    }
  },
  props:{
    commentProp: Array,
    courseId: String,
    course: {},
  },

  computed:{
    likes(){
      return this.comment.likedBy ? this.comment.likedBy.length : 0
    },
    initials(){
      return this.comment.author.substring(0,2);
    },
    initialsColor(){
      // a random integer between 0 to 5;
      const rndNumber = Math.floor(Math.random() * 5).toString();
      switch (rndNumber){
        case "0":
          return "#5c6bc0";
        case "1":
          return "#7e57c2";
        case "2":
          return "#00796b";
        case "3":
          return "#2a42d0";
        case "4":
          return "#d352b8";
        case "5":
          return "#8894d9";
        default:
          return "#5c6bc0";
      }
    },
  },

  methods:{
    // Liking the comment
    likeComment(){
      // the switch will be set false if the user already liked the comment.
      let switcher = true;

      // Iterating through the likes of the comment, if the user already like the comment,
      // we set the switch to false and show a sweet-alert.
      if(this.comment.likedBy){
        for(let userLiked of this.comment.likedBy){
          if(userLiked === this.$store.getters.username){
            switcher = false;
            this.showWarningMessage(this.$t('commentAlreadyLiked'));
          }
        }
      }
      // It true, liking the comment and posting it to the server
      if(switcher){

        const object= {
          courseId: this.course.id,
          commentId: this.comment.id
        }

        this.$store.commit("updateCourseComment", object);

        const postObject = {
          "course": this.course,
          "commentId": this.comment.id,
        }

        this.$store.dispatch("postRequest",{
          "param":"likeComment",
          "payload": postObject,
        }).then((res) =>{
          console.log(res)
        })
      }
    },
  },
  mounted(){
    this.likes = this.comment.likedBy ? this.comment.likedBy.length : 0;
  },
}
</script>

<style scoped>
  .header-container{
    display: flex;
    align-items: center;
    position: relative;
    width: 300px;
  }
  .comment-container{
    border-bottom:1px solid lightgrey;
    margin-top: 5px;
    padding:10px;
    display: flex;
    flex-direction: row;
  }

  .commentWrapper{
    margin-left: 10px;
    padding: 10px;
    background-color: #f1f2f3;
    display: block;
    width: 100%;
  }

  .iconWrapper{
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .likeButton{
    font-size: 12px;
    display: inline;
  }

  .likeButton :hover{
    cursor:pointer;
  }

  .thumbs-up{
    margin-left: 5px;
  }

  @media screen and (max-width: 1000px) {
    .comment-container{
      display: block;
    }
  }
</style>