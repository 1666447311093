<template>
  <div class="main-content-container">
    <div class="apart">
      <h1>{{ $t("nav.groups") }} ({{groupTableRows.length}})</h1>
    </div>
    <div style="display:flex;justify-content: space-between;align-items: center;">
      <PrimaryBtn v-if="role === 'admin'" @click="openModal(true)" :val="$t('group.add')"></PrimaryBtn>
      <secondary-btn v-if="role === 'admin'" style="" @click="this.generateLearningInfo(false)" :val="$t('generateLearningInfo')"></secondary-btn>
    </div>

    <MaterialTable @item-viewed="openGroupInfo" @item-edited="editGroup" @item-deleted="deleteGroup"
                   :item-delete="itemDelete" :item-edit="itemEdit" :item-view="itemView"
                   :table-headers="groupTableHeaders" :table-rows="groupTableRows" :inside-group-table="false"></MaterialTable>
  </div>
  <Modal :val="this.$t('back')" @close-form="this.closeModal" :heading="this.$options.groupsModalHeader"
         :modal-is-open=modalIsOpen>
    <AddGroupForm :group="this.selectedGroup" :is-new-group="this.isNewGroup" @group-updated="this.groupUpdated"
                  @close-form="this.closeModal"></AddGroupForm>
  </Modal>
  <Modal :val="this.$t('back')" @close-form="this.closeGroupInfo" :heading="$t('nav.persons')"
         :modal-is-open="groupInfoIsOpen">
        <span class="clickable" style="margin-left: 10px">
        <secondary-btn @click="this.generateLearningInfo(true)" :val="$t('generateLearningInfo')"></secondary-btn>
      </span>
    <MaterialTable @item-viewed="this.openLearningInfo" :item-view="itemView" :table-headers="personTableHeaders"
                   :table-rows="personTableRows" :inside-group-table="true"></MaterialTable>
    <LearningInfo :course-options="this.userCourseOptions" :selectedUserCourses="this.selectedUserCourses"
                  @course-assigned="courseAssigned"
                  :selectedPersonId="selectedPersonId" @modal-closed="closeLearningInfo"
                  :modalIsOpen=learningInfoIsOpen></LearningInfo>

  </Modal>
  <Loader :show="this.showLoader"></Loader>
</template>

<script>
import Modal from "../components/modal/Modal";
import PrimaryBtn from "../components/buttons/PrimaryBtn";
import AddGroupForm from "../components/admin/groups/AddGroupForm";
import SweetAlerts from "../mixins/sweet-alerts/SweetAlerts";
import MaterialTable from "@/components/material-table/MaterialTable";
import LearningInfo from "@/components/user/learning-info/LearningInfo";
import Loader from "@/components/loader/Loader";
import SecondaryBtn from "@/components/buttons/SecondaryBtn";
import * as XLSX from "xlsx";

export default {
  components: {Loader, MaterialTable, AddGroupForm, Modal, PrimaryBtn, LearningInfo, SecondaryBtn},
  mixins: [SweetAlerts],
  groupsModalHeader: "",

  data: () => ({
    modalIsOpen: false,
    selectedGroup: {},
    isNewGroup: true,
    groupInfoIsOpen: false,
    persons: [],
    learningInfoIsOpen: false,
    selectedUserCourses: [],
    totalCourseOptions: [],
    selectedPersonId: null,
    itemView: true,
    itemEdit: true,
    itemDelete: true,
    showLoader: false,
  }),

  methods: {
    editGroup(group) {
      this.selectedGroup = group;
      this.openModal(false);
      this.$options.groupsModalHeader = this.$t("group.edit");
    },

    /**
     * We can generate a learning info for all users included inside this group.
     * In order to do this, we submit a get request to the server, getting all
     * user objects and then matching the included course ids with another
     * course titles request to generate readable data.
     */
    async generateLearningInfo(generateForGroup){
        this.$swal({
          title: this.$t('exportToExcel'),
          text: this.$t('exportToExcelText'),
          confirmButtonColor: "#007AC2",
          confirmButtonText: this.$t('confirm'),
          showCancelButton: true,
          cancelButtonText: this.$t('button.cancel'),
        }).then(async (res) => {
          if (res.isConfirmed) {
            this.showLoader = true;

            const courseTitleResponse = await this.$store.dispatch("getRequest", "getCoursesTitle");
            const courseTitles = JSON.parse(courseTitleResponse.body);
            let usersProgressList;
            if(generateForGroup){
              const ids = this.getUserIds();
              usersProgressList = await this.$store.dispatch("getRequest", "getUserCoursesLists&query=" + ids);
            }else{
              usersProgressList = await this.$store.dispatch("getRequest", "getAllUserCoursesLists");
            }


            const data = JSON.parse(usersProgressList.body);

            const ws = XLSX.utils.json_to_sheet(this.createUserProgressExcelFormat(data, courseTitles));
            const wb = XLSX.utils.book_new();

            XLSX.utils.book_append_sheet(wb, ws, "data");
            const exportFileName = "learning_progress_"+ new Date().getTime()+ ".xls";
            XLSX.writeFile(wb, exportFileName);
            this.showLoader = false;
          }
        })
    },

    createUserProgressExcelFormat(userInfo, coursesMap){
      let data = [];
      let innerData = {};

      // per row, we will have one finished user object
      for (let user of userInfo){
        innerData["Username"] = user[0].friendlyName;
        innerData["PersonNumber"] = user[0].personNumber;

        for (let courseTitles of coursesMap){
          innerData[courseTitles.courseTitle] = "offen";
        }

        for (let course of user){
            if (course["completionTimestamp"]){
              for (let map of coursesMap){
                if (map.courseId === course.courseId){
                  const completedDateString = new Date(parseInt(course["completionTimestamp"])).toLocaleDateString();
                  innerData[map.courseTitle] = "abgeschlossen am: " + completedDateString;
                }
              }
            } else if (course["learningProgress"]){
              for (let map of coursesMap){
                if (map.courseId === course.courseId){
                  innerData[map.courseTitle] = "begonnen: " + this.getCourseCompletedPercentage(course) ;
                }
              }
            }
        }

        data.push(innerData);
        innerData = {};
      }

      return data;
    },

    getCourseCompletedPercentage(course){
      let percentage = 0;

      if (course["totalCourseTemplates"]){
        if (course["totalCourseTemplates"] > 0){
          percentage = Math.round(parseInt(course["learningProgress"]) / course["totalCourseTemplates"] * 100);
        }
      }
      return percentage + "%";
    },

    getUserIds(){
      let data = [];
      for(let item of this.persons){
          if (item.group === this.selectedGroup.name){
            data.push(item.id);
          }
      }
      return data;
    },


    openModal(isNewGroup) {
      this.isNewGroup = isNewGroup;
      this.modalIsOpen = true;
      this.$options.groupsModalHeader = this.$t("group.add");
    },

    closeModal() {
      this.modalIsOpen = false;
      this.selectedGroup = {}
    },

    //Updates the group
    groupUpdated(event) {
      this.showLoader = true;
      this.$store.commit("updateGroup",{
        "isNewGroup":this.isNewGroup,
        "group":event,
      })

      if(this.isNewGroup){
        this.showSuccessMessage(this.$t('form.group') + this.$t('addSuccess'));
      }else{
        this.showSuccessMessage(this.$t('form.group') + " " + this.$t('swal.updateSuccess'));
      }
      this.showLoader = false;
    },

    // Deleting the group
    deleteGroup(item) {
      let counter = 0;
      for(let person of this.persons){
        if(person.group === item.name){
          counter++;
        }
      }
      if(counter > 0){
        this.showErrorMessage(this.$t('cantDeleteGroup'))
      }else {
        // Asking the user to confirm the deletion of the group.
        this.showConfirmDialogue(this.$t('group.delete'),
            this.$t('swal.confirmDelete')).then((res) => {
          // Firing a dispatch into or store to delete the group.
          if (res) {
            this.showLoader = true;
            this.$store.dispatch("deleteGroup", item).then(() => {
              this.showSuccessMessage(this.$t('form.group') + " " + this.$t('deleteSuccess'));
              this.showLoader = false;
            })
          }
        })
      }
    },

    // Opens the LearningInfo Modal
    openLearningInfo(item) {
      this.selectedPersonId = item.id;
      this.showLoader = true;
      // getting the courses of the clicked user
      this.$store.dispatch("getRequest","getUsersCourses&query=" + item.id).then(response => {
        this.selectedUserCourses = JSON.parse(response.body);

        this.$store.dispatch("getRequest","getCoursesTitle").then(response => {
          this.showLoader = false;
          this.totalCourseOptions = JSON.parse(response.body);
        })
      })

      this.learningInfoIsOpen = true;
    },

    // Assigns a course to the current user we are looking at.
    courseAssigned(params) {
      const courseId = params.courseId;
      const userId = params.id;
      this.showLoader = true;

      this.$store.dispatch("postRequest",{
        "param":"subscribeToCourse",
        "payload":{
          "courseId": courseId,
          "userId": userId,
          "subscriptionTimestamp": new Date().getTime().toString()}
      }).then(() => {
        this.showLoader = false;
        this.closeLearningInfo();
        this.openLearningInfo(params);
      })
    },

    // closes the learningInfo Modal and empties data.
    closeLearningInfo() {
      this.learningInfoIsOpen = false;
      this.selectedUserCourses = [];
      this.totalCourseOptions = [];
    },

    // Opens the currently clicked groupInfo Modal.
    openGroupInfo(group) {
      this.selectedGroup = group;
      this.groupInfoIsOpen = true;
    },

    // Closes the Modal with the groupInfo
    closeGroupInfo() {
      this.groupInfoIsOpen = false;
    },
  },

  computed: {

    role(){
      return this.$store.getters.role;
    },

    groups(){
      return this.$store.getters.groups;
    },
    // creating the headers for the group table
    groupTableHeaders() {
      return [{label: this.$t('form.group')}, {label: this.$t('form.description')},
        {label: this.$t('responsible')}, {label: this.$t('form.company')}]
    },

    // creating the rows for the group table
    groupTableRows() {
      let data = [];
      for (let group of this.groups) {
        const groupName = group.name;
        const groupDescription = group.description;
        const groupCompany = group.company;
        const groupResponsible = group.responsible;
        const id = group.id;
        const external = group.external;

        let cells = [];

        cells.push(groupName, groupDescription, groupResponsible, groupCompany);

        data.push({
          "id": id,
          "external": external,
          "name": groupName,
          "description": groupDescription,
          "company": groupCompany,
          "responsible": groupResponsible,
          cells: cells
        });

      }
      return data;
    },

    // creating the headers for the persons table
    personTableHeaders() {
      return [{label: this.$t('form.person.email')}, {label: this.$t('form.person.friendlyName')}, {label: this.$t('form.group')}, {label: this.$t('form.person.role')}]
    },

    // creating the rows for the persons table
    personTableRows() {
      let data = [];
      for (let person of this.persons) {
        // shows only the persons that are inside the group
        if (person.group === this.selectedGroup.name) {
          const personName = person.friendlyName;
          const personGroup = person.group;
          const personRole = person.role;
          const personEmail = person.email;
          const id = person.id

          let cells = [];

          cells.push(personEmail, personName, personGroup, personRole);

          data.push({"id": id,"friendlyName":personName, cells: cells});
        }
      }
      return data;
    },

    userCourseOptions() {
      const options = [];

      for (let option of this.totalCourseOptions) {
        if (!this.selectedUserCourses["courseIdList"].includes(option.courseId)) {
          options.push(option);
        }
      }
      return options;
    },
  },

  mounted() {
    this.showLoader = true;
    if(this.role) if (this.role.toLowerCase() === "admin" || this.role.toLowerCase() === "admin_light"){
      this.$store.dispatch("getGroups");

      this.$store.dispatch("getRequest","getUsers").then(res => {
        this.showLoader = false;
        this.persons = JSON.parse(res.body);
      })
    }
  },

}
</script>

<style scoped>
h1 {
  margin-bottom: 24px;
}

.apart {
  display: flex;
  justify-content: space-between;
}

/*noinspection CssUnusedSymbol*/
.asc:after {
  content: "\25B2";
  font-size: 10px;
}

/*noinspection CssUnusedSymbol*/
.desc:after {
  content: "\25BC";
  font-size: 10px;
}

table {
  border-collapse: collapse;
  width: 100%;
}

input {
  line-height: 48px;
}

th, td {
  text-align: left;
  padding: 10px;
}

th {
  border-bottom: 2px solid var(--darkgrey);
}

tr {
  border-bottom: 1px solid var(--brightgrey);
}

/*search filter styling*/
input {
  outline: none;
  border: none;
}

input[type=search] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  font-family: inherit;
  font-size: 100%;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button {
  display: none;
}

input[type=search] {
  background: url(https://static.tumblr.com/ftv85bp/MIXmud4tx/search-icon.png) no-repeat 9px center;
  border: solid 1px var(--brightgrey);
  padding: 5px 10px 5px 32px;


  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;

  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  transition: all .5s;
}

input[type=search]:focus {
  border-color: var(--primary-color);
}

input:-moz-placeholder {
  color: #999;
}

input::-webkit-input-placeholder {
  color: #999;
}

input[type=search] {
  padding-right: 10px;
  color: black;
  cursor: pointer;
}

input[type=search]:hover {
  background-color: #fff;
}

input[type=search]:focus {
  color: #000;
  background-color: #fff;
  cursor: auto;
}


</style>
