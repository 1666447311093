<template>
  <div class="form-container">
    <div class="form-wrapper-tags">
      <div class="wrapper-left">
        <AppInput :hasError="v$.tagName.$error" :value="this.tagName"  @input-changed="nameChanged"
                  type="text" :label="$t('form.title.tag')" :placeholder="this.$t('placeholderTagName')"/>
      </div>
      <div class="wrapper-right">
        <AppInput :hasError="v$.tagDescription.$error"  :value="this.tagDescription" @input-changed="descriptionChanged"
                  type="text" :label="$t('form.description')" :placeholder="this.$t('placeholderDescription')"/>
      </div>
    </div>
    <div class="form-btm-wrapper primary-btn-wrapper">
      <primary-btn class="course-submit-btn" @click="updateTag" type="submit" :val="$t('confirm')"></primary-btn>
    </div>
  </div>
</template>
<script>

import AppInput from "../../form/inputs/AppInput";
import PrimaryBtn from "../../buttons/PrimaryBtn";
import Uuidv4Generator from "../../../mixins/uuid/Uuidv4Generator";
import {required} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
  name: 'AddTagForm',
  mixins: [Uuidv4Generator],
  components: {
    AppInput,
    PrimaryBtn
  },

  setup(){
    return{
      v$: useVuelidate()
    }
  },

  validations(){
    return{
      tagName: {required},
      tagDescription:{required}
    }
  },

  data(){
    return {
      tagName: this.tag.tag,
      tagDescription: this.tag.description
    }
  },

  props:{
    tag: Object,
    isNewTag: Boolean
  },

  methods: {
    cancelForm(){
      this.$emit("close-form");
    },

    emitTagUpdatedEvent(tagData){
      this.$emit("tag-updated", tagData);
    },

    updateTag() {
      this.v$.$validate();
      if (!this.v$.$error){
        const tagData = {
          "tag": this.tagName,
          "description": this.tagDescription,
          "id": this.isNewTag ? this.createUuidv4() : this.tag.id
        }

        this.$store.dispatch("postRequest",{
          "param":"updateTag",
          "payload":tagData,
        }).then(()=>{
          // now we can inform our parent and then close the form.
          this.emitTagUpdatedEvent(tagData);
          this.cancelForm();
        });
      }
    },

    nameChanged(event){
      this.tagName = event.target.value;
    },

    descriptionChanged(event){
      this.tagDescription = event.target.value;
    },

  },
}
</script>

<style scoped>

.wrapper-left{
  position: relative;
  width: 100%;
  margin-right: 20px;
}

.wrapper-right{
  position: relative;
  width: 100%;
  margin-left: 20px;
}

.form-container{
  background-color: var(--brightgrey);
  border-right: 4px;
  padding: 40px;
  border-radius: 4px;
  margin-bottom: 24px;
}

.primary-btn-wrapper{
  position: fixed;
  right: 64px;
  bottom: 64px;
}

.form-btm-wrapper{
  display: flex;
  justify-content: right;
}

label{
  font-weight: 300;
}

input:focus, select:focus, textarea:focus-visible, textarea:focus {
  outline: none;
  border: 1px solid var(--primary-color);
  border-radius: 4px;
}

input, select, textarea{
  height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
  border: 1px solid var(--brightgrey);
  background-color: var(--bright);
}

input:focus + label,
select:focus + label,
textarea:focus + label {
  color: var(--primary-color);
}

.course-submit-btn{
  position: fixed;
  right: 64px;
  bottom: 64px;
}

.form-wrapper-tags{
  display: flex;
}


@media screen and (max-width: 800px) {
  .form-wrapper-tags{
    display: block;
  }

  .wrapper-right{
    margin-left: 0;
  }
}

@media screen and (max-width: 600px) {
  .form-container{
    padding: 0;
    background-color: transparent;
  }

  .primary-btn-wrapper{
    right: 40px;
    bottom: 40px;
  }

  .course-submit-btn{
    right: 40px;
    bottom: 40px;
  }
}

</style>