<template>
  <div id="progress-bar-line">
    <svg
        id="line-progress"
        :style="lineStyleSvgFrame"
        width="100%"
    >
      <line
          x1="0"
          y1="50%"
          x2="100%"
          y2="50%"
          :stroke="backgroundColor"
          :stroke-width="defaultOptions.layout.height"
      />
      <line
          x1="0"
          y1="50%"
          :x2="progressValue"
          y2="50%"
          :stroke="progressColor"
          :stroke-width="lineProgressHeight"
      />
      <text
          :style="textStyle"
          :x="horizontalTextAlignP"
          :y="verticalTextAlignP"
      >
        {{ value }}%
      </text>
    </svg>
  </div>
</template>

<script>
import { ProgressBarMixin } from './ProgressBarMixin'

export default {
  name: 'ProgressBarLine',
  mixins: [ProgressBarMixin],
  props: {
    options: {
      type: Object,
      required: false,
      default: () => {}
    },
    value: {
      type: Number,
      required: false,
      default: 0
    }
  }
}
</script>
<style>
#progress-bar-line, #line-progress{
  border-radius: 4px;
  stroke-width: 20px;
}

@media screen and(max-width: 600px){
  #line-progress{width: 100%;}
}
</style>
