<template>
  <div :style="{'background-color':this.color}" class="circle">
    <span class="initials">{{ this.initials }}</span>
  </div>
</template>

<script>

export default {
  name: "InitialsAvatar",
  props:{
    initials:String,
    color:String,
  }
}
</script>

<style scoped>
/* http://caniuse.com/#search=variables */

:root {
  --avatar-size: 24rem;
  /* change this value anything, e.g., 100px, 10rem, etc. */
}

.circle {
  background-color: #ccc;
  border-radius: 50%;
  height: var(--avatar-size);
  text-align: center;
  width: var(--avatar-size);
}

.initials {
  font-size: calc(var(--avatar-size) / 2); /* 50% of parent */
  line-height: 1;
  padding:10px;
  color:white;
  position: relative;
  top: calc(var(--avatar-size) / 4); /* 25% of parent */
}


div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  font-family: sans-serif;
  justify-content: center;
  margin: 0;
}
</style>