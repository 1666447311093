<template>
  <div class="question-card">
    <h3>{{ this.template.editorData.question }}</h3>
    <div class="parent" v-for="answer in this.template.editorData.answerList" :key="answer">
      <div class="checkbox-wrapper">
        <label>
          <input :disabled="disableChoice" @click="(event) => answerOptionClicked(answer,event)"
                 v-model="answer.isChecked" type="checkbox">
        </label>
        <span>{{ answer.answer }}</span>
      </div>
    </div>
    <div class="retry-submit-btn-container">
      <button class="retryBtn" @click="resetAnswerSelection" :disabled="noRemainingAttempts">
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#007AC2FF"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 5V1L7 6l5 5V7c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6H4c0 4.42 3.58 8 8 8s8-3.58 8-8-3.58-8-8-8z"/></svg>
        <span>{{ this.$t('retry') }}</span>
      </button>
      <button :disabled="disableChoice || noRemainingAttempts" class="submitAnswerBtn" @click="checkAnswer">
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#007ac2"><rect fill="none" height="24" width="24"/><path d="M17.66,9.53l-7.07,7.07l-4.24-4.24l1.41-1.41l2.83,2.83l5.66-5.66L17.66,9.53z M4,12c0-2.33,1.02-4.42,2.62-5.88L9,8.5v-6H3 l2.2,2.2C3.24,6.52,2,9.11,2,12c0,5.19,3.95,9.45,9,9.95v-2.02C7.06,19.44,4,16.07,4,12z M22,12c0-5.19-3.95-9.45-9-9.95v2.02 c3.94,0.49,7,3.86,7,7.93c0,2.33-1.02,4.42-2.62,5.88L15,15.5v6h6l-2.2-2.2C20.76,17.48,22,14.89,22,12z"/></svg>
        <span>{{ this.$t('submitAnswer') }}</span>
      </button>
    </div>
    <div class="countTriesWrapper"
         v-if="this.template.editorData.maxAttempts">
      <span style="color: grey; margin-right: 5px"> {{ this.$t('remainingAttempts') }}</span>
      <span style="font-weight: bold"> {{ this.remainingAttempts }} </span>
    </div>
    <div v-if="showFeedback">
      <div class="feedback-wrapper" style="border: 2px solid black" v-for="item in this.questionFeedbackList" :key="item">
                <span v-if="isAnswerCorrect && this.remainingAttempts > 0">
                    <svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 0 24 24" width="18px" fill="green"><path
                        d="M0 0h24v24H0z" fill="none"/><path
                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/></svg>
                  </span>
        <span v-if="!isAnswerCorrect || this.remainingAttempts === 0 && isAnswerCorrect">
                    <svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 0 24 24" width="18px" fill="red"><path
                        d="M0 0h24v24H0z" fill="none"/><path
                        d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"/></svg>
                   </span>
        <span>{{item.answerOption.feedback}}
                  </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuestionTemplate",
  props:{
    template:Object,
    remainingAttemptsProp: Number,
    questionFeedbackListProp:Array,
    isAnswerCorrectProp:Boolean,
    showFeedbackProp:Boolean,
    disableChoiceProp:Boolean,
    noRemainingAttemptsProp:Boolean,
    courseData:Object,
  },
  data(){
    return{
      remainingAttempts:this.remainingAttemptsProp,
      questionFeedbackList:this.questionFeedbackListProp,
      isAnswerCorrect:this.isAnswerCorrectProp,
      showFeedback:this.showFeedbackProp,
      disableChoice:this.disableChoiceProp,
      noRemainingAttempts:this.noRemainingAttemptsProp,
      status: "failed",
    }
  },
  methods:{
    checkAnswer() {

      /**
       * This counter reflects the maximum amount of correct answer options.
       * @type {number}
       */
      let maximumCounter = 0;

      /**
       * This counter shows the current correctly marked answers.
       * @type {number}
       */
      let counter = 0;

      let clickedAnswer = 0;

      // if an answer was marked as correct, the counter will increase,
      // otherwise decrease.
      for (let item of this.questionFeedbackList) {
        item.answerOption.isCorrectAnswer ? counter++ : counter--;
        clickedAnswer ++;
      }
      if(clickedAnswer !== 0) {
        this.showFeedback = true;
        this.$emit('showFeedback',this.showFeedback);
        // iterate through all answer List objects and gather the maximum
        // count of correct answers.
        for (let items of this.template.editorData.answerList) {
          if (items.isCorrectAnswer) maximumCounter++;
        }

        // now we check if the amount of correctly marked answers (counter)
        // and the maximum amount of correct answers are matching.
        if (counter === maximumCounter) {
          this.isAnswerCorrect = true;
          this.status = "passed";
          this.$emit('answerIsCorrect',this.isAnswerCorrect)
        } else {
          this.isAnswerCorrect = false;
        }

        // after we have submitted a feedback, we disable the choice until we
        // click on retry.
        this.disableChoice = true;
        this.$emit('disableChoice',this.disableChoice);

        if (!this.isAnswerCorrect) this.remainingAttempts--;

        if(this.remainingAttempts < 1){
          this.isAnswerCorrect = true;
          this.$emit('answerIsCorrect',this.isAnswerCorrect)
          this.status = "failed";
        }

        this.setRemainingAttempts();

        const postObject = {
          "remainingAttempts": this.remainingAttempts,
          "courseId": this.courseData.id,
          "questionId": this.template.id,
          "status": this.status,
          "userId": this.$store.getters.username,
        }

        this.$store.dispatch("postRequest", {
          "param": "submitQuestionResult",
          "payload": postObject
        }).then(() => {
          this.$store.commit("updateQuestion", {
            "courseId": this.courseData.id,
            "questionObject": {
              "remainingAttempts":postObject.remainingAttempts,
              "id":postObject.questionId,
              "status":postObject.status
            }})
        })
      }
    },

    setRemainingAttempts() {

      //  Do we have the questions array inside courseData.progress ?
      if (this.courseData.progress.questions) {


        // Yes, now we iterate through the questions.
        let foundItem = false;
        for (let question of this.courseData.progress.questions) {

          // Checking if the questionId equals the templateId
          if (question.id === this.template.id) {

            // We set the remainingAttempts as the current remaining attempts of this question.
            foundItem = true;
            question.remainingAttempts = this.remainingAttempts;
            break;
          }
        }
        if (!foundItem) {

          // The questionId doesn't exist or is currently undefined, so we create and push a new array with the data

          // eslint-disable-next-line vue/no-mutating-props
          this.courseData.progress.questions.push({
            "remainingAttempts": this.remainingAttempts,
            "id": this.template.id,
            "status": this.status,
          })
        }

        // The questions array doesn't exist, so we create a new array with the data
      } else {
        // eslint-disable-next-line vue/no-mutating-props
        this.courseData.progress.questions = [{
          "remainingAttempts": this.remainingAttempts,
          "id": this.template.id,
          "status": this.status,
        }]
      }
    },

    resetAnswerSelection() {

      // Resetting all necessary Booleans and arrays.
      this.disableChoice = false
      this.showFeedback = false;
      this.isAnswerCorrect = false;
      this.questionFeedbackList = [];

      for (let items of this.template.editorData.answerList) {
        items.isChecked = false;
      }

      if(this.remainingAttempts < 1){
        this.disableChoice = true
        this.isAnswerCorrect = true;
      }

    },

    // if we are inside a question template, answers can be selected.
    answerOptionClicked(answerOption, event) {
      // first we need to check if the input was checked or not.
      const isChecked = event.target.checked;

      let counter = 0;

      // if it is checked, then we need to add it, otherwise remove it.
      if (isChecked) {
        for (let item of this.template.editorData.answerList) {
          if (item.id === answerOption.id) {
            item.isChecked = true;
          }
        }
        this.questionFeedbackList.push({answerOption})
      } else {
        for (let answer of this.questionFeedbackList) {
          if (answerOption.id === answer.answerOption.id) {
            this.questionFeedbackList.splice(counter, 1);
            break;
          }
          counter++;
        }
      }
    },
  },
  watch:{
    remainingAttemptsProp(){
      this.remainingAttempts = this.remainingAttemptsProp;
    },
    questionFeedbackListProp(){
      this.questionFeedbackList = this.questionFeedbackListProp;
    },
    isAnswerCorrectProp(){
      this.isAnswerCorrect = this.isAnswerCorrectProp;
    },
    // These watchers are needed to guaranty that the next question shows feedback and allows the user to click answers.
    showFeedbackProp(){
      this.showFeedback = this.showFeedbackProp;
    },
    disableChoiceProp(){
      this.disableChoice = this.disableChoiceProp;
    },
    noRemainingAttemptsProp(){
      this.noRemainingAttempts = this.noRemainingAttemptsProp;
    },
  }
}
</script>

<style scoped>

.question-card {
  padding: 40px;
  background-color: var(--brightgrey);
  border-radius: 4px;
  width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 65vh;
  overflow: auto;
}

.question-card h3 {
  margin-bottom: 24px;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  padding: 15px;
}

.parent:nth-child(even) {
  background-color: var(--bright);
}


.feedback-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  border: 2px solid;
  padding: 5px;
  border-radius: 4px;
  background-color: var(--brightgrey);
}

.retry-submit-btn-container {
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: space-between;
}

.retry-submit-btn-container .submitAnswerBtn {
  border-radius: 4px;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  background-color: transparent;
  display: flex;
  align-items: center;
}

.retry-submit-btn-container .retryBtn {
  display: flex;
  align-items: center;
}

.countTriesWrapper{
  display: flex;
  margin-top: 10px;
  align-items: center;
  font-size: 16px;
}

@media screen and (max-width: 600px) {

  .question-card {
    width: 100%;
    height: 100%;
    position: unset;
    transform: none;
    padding: 20px;
  }

}

</style>