<template>
  <div class="login-nav">
    <div>
      <SelectLanguage></SelectLanguage>
    </div>
    <div class="link-wrapper">
      <span class="a" @click="this.route('aboutUs')">
        {{ $t('footer.about') }}
      </span>
      <span class="a" @click="this.route('terms')">
        {{ $t('footer.terms') }}
      </span>
    </div>
  </div>
</template>

<script>

import SelectLanguage from "../elemental/selectLanguage";
import {Config} from "../../config";

export default {
  components: {SelectLanguage},
  data(){
    return{
      aboutUs: Config.AboutUs.link,
      terms: Config.TermsOfUse.link
    }
  },
  methods:{
    route(intent){
      let path = "";

      if (intent === "aboutUs"){
        path = this.aboutUs;
      } else if (intent === "terms"){
        path = this.terms;
      }

      const tab = window.open(path);
      tab.focus();
    },
  }
}
</script>

<style scoped>
.login-nav{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 24px 24px 24px;
}

.link-wrapper{
  display: flex;
  gap:20px;
}

.a{
  text-decoration: none;
  color: var(--primary-color);
  font-size: 14px;
  cursor: pointer;
}

.a:hover {
  text-decoration: underline;
}

</style>

