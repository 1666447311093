<template>
  <div v-if="reloadNecessary" class="reload-button">
    <span>{{this.$t('reloadPageText')}}</span>
    <secondary-btn style="margin-left: 20px;" :val="this.$t('reload')" @click="reloadSite"></secondary-btn>
    <button class="close-button" @click="closeReloadNotification">X</button>
  </div>
  <div class="bottom-footer">
    <div class="footer-wrapper">
      <select-language></select-language>
    </div>
    <div v-if="notInsideIFrame" class="footer-wrapper hide-footer-details">
      <router-link to="/about">
        {{ $t('footer.about') }}
      </router-link>
      <router-link to="/help">
        {{ $t('footer.help') }}
      </router-link>
      <router-link to="/terms-of-use">
        {{ $t('footer.terms') }}
      </router-link>
    </div>
  </div>
</template>

<script>

import SelectLanguage from "../elemental/selectLanguage";
import SecondaryBtn from "@/components/buttons/SecondaryBtn";

export default {
  components: {SecondaryBtn, SelectLanguage},

  // Getting reloadNecessary from store.
  computed:{
    reloadNecessary(){
      return this.$store.getters.reloadNecessary;
    },
    // Returns true if we aren't inside an iFrame.
    notInsideIFrame(){
      return window.location === window.parent.location
    }
  },
  methods:{
    // Reloads the page.
    reloadSite(){
      location.reload();
    },
    // Closes the notification the user gets when not reloading for 2h. Resets the timer aswell.
    closeReloadNotification(){
      this.$store.commit("resetReloadNecessary");
      localStorage.removeItem('reloadNecessary')
      const reloadTimer = new Date().getTime() + 28800000;
      localStorage.setItem('reloadNecessary',reloadTimer)

    }
  }
}
</script>

  <style scoped>
  .bottom-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 0;
    background-color: var(--bright);
    border-top: 1px solid var(--brightgrey);
    width: 99.8%;
    z-index: 1000;
  }

  .reload-button{
    padding: 5px;
    text-align: center;
    position: fixed;
    bottom: 38px;
    width: 99.8%;
    z-index: 1;
    background-color: var(--bright);
    border-top: 1px solid var(--brightgrey);
  }

  .close-button{
    float:right;
    background-color: var(--bright);
    border: 1px solid var(--brightgrey);
  }

    .footer-wrapper{
      padding: 10px 24px;
  }

  .footer-wrapper a{
    padding-left: 10px;
    font-size: 14px;
    color: var(--darkgrey);
    text-decoration: none;
  }

  .footer-wrapper a:hover{
    color: var(--primary-color);
  }

  .hide-footer-details{
    display: block;
  }

  @media screen and (max-width: 600px) {
    .hide-footer-details{
      display: none;
    }
  }
</style>

