/**
 * The config file manages variables that change depending on the domain.
 * @type {{Names: {appName: string}, PrivacyRemark: {link: string}, BackgroundImage: {color: string, link: string},
 * TermsOfUse: {link: string}, Cookies: {domain: string}, AboutUs: {link: string}, Logo: {link: string, video: string},
 * Contact: {link: string}}}
 */
export const Config = {
    Cookies:{
        domain: "https://academy.gleason.com"
    },
    Names:{
        appName: "Gleason E-Learning",
        domain: "gleason"
    },
    BackgroundImage:{
        link: "https://public-storage-codevance.s3.eu-central-1.amazonaws.com/gleason/image.jpg",
        color: ""
    },
    Logo:{
        link: "https://public-storage-codevance.s3.eu-central-1.amazonaws.com/gleason/logo.png"
    },
    AboutUs:{
        link: "https://www.gleason.com/de",
        email: "e-academy@gleason.com"
    },
    TermsOfUse: {
        link : "https://www.gleason.com/de/terms-and-conditions"
    },
    Certificate:{
        fullCompanyName: "Gleason-Pfauter Maschinenfabrik GmbH",
        city: "Ludwigsburg"
    },
    Access:{
        has3DRoom: true
    }
};
