<template>
  <div>
    <button v-show="!isMobile && this.hasRoomAccess" class="button" @click="openModal">{{this.$t('open3DRoom')}}</button>
    <Modal :val="this.$t('back')" id="elem" @close-form="galleryClosed" :remove-padding-prop="true"
           :modal-is-open=modalIsOpen>
      <div id="canvasZone" style="width: 100%; height: 100%;">
        <canvas style="z-index: 10000; width: 100%; height: 100%;" id="renderCanvas"/>
      </div>
    </Modal>
  </div>
</template>

<script>
import {createScene, removeScene} from "@/mixins/CSS3D/CSS3D";
import Modal from "@/components/modal/Modal";
import {Config} from "../../config";

export default {
  name: "Home2",
  components: {Modal},
  data(){
    return{
      modalIsOpen:false,
      hasRoomAccess: Config.Access.has3DRoom
    }
  },
  computed:{
    isMobile() {
      return screen.width <= 800;
    },
  },
  methods:{
    openModal(){
      this.modalIsOpen = true;
      setTimeout(()=>{
        this.startScene()
      },500)
    },
    startScene(){
      const bjsCanvas = document.getElementById("renderCanvas");
      if (bjsCanvas) {
        this.$swal({
          title: this.$t('loading'),
          timer: 20000,
          timerProgressBar: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
        })
        // Creates the scene of the 3D-room inside the canvas.
        createScene(bjsCanvas).then(()=>{
          this.$swal.close()
        })
        // Timer that will show when the 3D-room is being created.
      }
    },
    galleryClosed(){
      this.modalIsOpen = false;
      removeScene();
      let canvasZone = document.getElementById("canvasZone");
      for (let child of canvasZone.children){
        if(child.id !== "renderCanvas"){
          canvasZone.removeChild(child)
        }
      }
    },
  },
};
</script>

<style scoped>

button{
  background-color: white;
  border: 2px solid var(--primary-color);
  padding: 5px 12px;
  font-size: 14px;
  /*font-weight: bold;*/
  color: black;
  border-radius: 4px;
}
button:hover{
  cursor: pointer;
  box-shadow: 0 0 5px rgb(169 191 203);
}
</style>