
const awsGleasonCH = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:0d9f6579-6173-4d94-8b5f-198533472560",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_K0zxrpu6J",
    "aws_user_pools_web_client_id": "3pb4m8c6677s0oibqbkbk84mic",
    "oauth": {},
    "Storage": {
        "AWSS3": {
            "bucket": 'gleason-elearning-storage', //REQUIRED -  Amazon S3 bucket name
            "region": 'eu-central-1', //OPTIONAL -  Amazon service region
        }
    },
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsGleasonCH;
