<template>
  <div class="countTriesWrapper" v-if="this.template.editorData.maxAttempts" style="position: absolute; top:-35px; right:0">
    <span style="color: grey; margin-right: 5px"> {{ this.$t('remainingAttempts') }}</span>
    <span style="font-weight: bold"> {{ this.remainingAttempts }} </span>
  </div>
  <img class="image" ref="clickableImage" @click="checkPosition" :src="imageSrc" alt="">
</template>

<script>
import SweetAlerts from "@/mixins/sweet-alerts/SweetAlerts";

export default {
  name: "ImageMarker",
  mixins:[SweetAlerts],
  props:{
    remainingAttemptsProp: Number,
    templateProp: Object,
    imageSrcProp: String,
    courseDataProp:Object,
    isAnswerCorrectProp:Boolean,
    clickedProp: Boolean,
  },

  data(){
    return{
      clicked: this.clickedProp,
      startHeight: 0,
      endHeight: 0,
      startWidth: 0,
      endWidth: 0,
      remainingAttempts: this.remainingAttemptsProp,
      courseData:this.courseDataProp,
      template:this.templateProp,
      isAnswerCorrect:this.isAnswerCorrectProp,
      imageSrc:this.imageSrcProp,
      status: "failed",
    }
  },

  methods:{
    checkPosition(event) {

      // Setting the Width & Height of the correct part of the image.
      this.startHeight = this.template.editorData.dimensions.heightStartPercentage;
      this.endHeight = this.template.editorData.dimensions.heightEndPercentage;
      this.startWidth = this.template.editorData.dimensions.widthStartPercentage;
      this.endWidth = this.template.editorData.dimensions.widthEndPercentage;

      // Have we clicked somewhere inside the image ?
      if (!this.clicked) {

        // Checking if the remainingAttempts is bigger than 0
        if (this.remainingAttempts > 0) {

          // setting the image height/width according to the client height/width.
          let imageHeight = this.$refs.clickableImage.clientHeight;
          let imageWidth = this.$refs.clickableImage.clientWidth;

          // gives the currently clicked position in %.
          let percentageHeight = (imageHeight - event.offsetY) / imageHeight * 100;
          let percentageWidth = (imageWidth - event.offsetX) / imageWidth * 100;

          let currentSelectedHeight = (100 - percentageHeight).toFixed(2);
          let currentSelectedWidth = (100 - percentageWidth).toFixed(2);

          // Checking if the clicked position is inside the correct part of the image.
          if (parseFloat(this.startWidth) <= currentSelectedWidth && currentSelectedWidth <= parseFloat(this.endWidth) &&
              parseFloat(this.startHeight) <= currentSelectedHeight && currentSelectedHeight <= parseFloat(this.endHeight)) {

            // We clicked inside the correct part.
            this.clicked = true;
            this.isAnswerCorrect = true;
            this.status = "passed"
            this.$emit('clicked',this.clicked)
            this.$emit('answerIsCorrect',this.isAnswerCorrect)
            this.showSuccessMessage(this.$t('question.correct'));
          } else {

            //We clicked outside the correct part.
            this.$emit("change-remaining-attempts",this.remainingAttempts--);
            this.showWarningMessage(this.$t('question.wrong'));
            if(this.remainingAttempts < 1){
              this.isAnswerCorrect = true;
              this.$emit('answerIsCorrect',this.isAnswerCorrect)
            }
          }
        } else {
          // We have no remaining attempts and failed this template
          this.showErrorMessage(this.$t('noRemainingAttempts'));
          this.status = "failed"
          this.isAnswerCorrect = true;
        }
      }

      //Sets the remaining attempts for the ImageMarker template.
      this.setRemainingAttempts();

      // Creating a postObject and submitting the object to the server.
      const postObject = {
        "remainingAttempts": this.remainingAttempts,
        "courseId": this.courseData.id,
        "questionId": this.template.id,
        "status": this.status,
        "userId": this.$store.getters.username
      }

      this.$store.dispatch("postRequest",{
        "param":"submitQuestionResult",
        "payload":postObject
      }).then(() => {
        this.$store.commit("updateQuestion", {
          "courseId": this.courseData.id,
          "questionObject": {
            "remainingAttempts":postObject.remainingAttempts,
            "id":postObject.questionId,
            "status":postObject.status
          }})
      })
    },
    setRemainingAttempts() {

      //  Do we have the questions array inside courseData.progress ?
      if (this.courseData.progress.questions) {


        // Yes, now we iterate through the questions.
        let foundItem = false;
        for (let question of this.courseData.progress.questions) {

          // Checking if the questionId equals the templateId
          if (question.id === this.template.id) {

            // We set the remainingAttempts as the current remaining attempts of this question.
            foundItem = true;
            question.remainingAttempts = this.remainingAttempts;
            break;
          }
        }
        if (!foundItem) {

          // The questionId doesn't exist or is currently undefined, so we create and push a new array with the data

          // eslint-disable-next-line vue/no-mutating-props
          this.courseData.progress.questions.push({
            "remainingAttempts": this.remainingAttempts,
            "id": this.template.id,
            "status": this.status,
          })
        }

        // The questions array doesn't exist, so we create a new array with the data
      } else {
        // eslint-disable-next-line vue/no-mutating-props
        this.courseData.progress.questions = [{
          "remainingAttempts": this.remainingAttempts,
          "id": this.template.id,
          "status": this.status,
        }]
      }
    },
  },
  watch:{
    remainingAttemptsProp(){
      this.remainingAttempts = this.remainingAttemptsProp;
    },
    templateProp(){
      this.template = this.templateProp;
    },
    imageSrcProp(){
      this.imageSrc = this.imageSrcProp;
    },
    courseDataProp(){
      this.courseData = this.courseDataProp;
    },
    isAnswerCorrectProp(){
      this.isAnswerCorrect = this.isAnswerCorrectProp;
    },
    clickedProp(){
      this.clicked = this.clickedProp;
    }
  }
}
</script>

<style scoped>


.countTriesWrapper{
  display: flex;
  margin-top: 10px;
  align-items: center;
  font-size: 16px;
}

.image {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 4px;
}

</style>