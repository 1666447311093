import {S3FileManager} from "@/mixins/s3-file-manager/s3-file-manager";
import {createStore} from "vuex";
import {Auth} from "aws-amplify";
import requestModule from "@/store/modules/requestModule";
import personStore from "@/store/modules/person/personStore";
import groupStore from "@/store/modules/group/groupStore";
import homeStore from "@/store/modules/home/homeStore";
import tagStore from "@/store/modules/tags/tagStore";


// Getting the image source for the course.
function  getTitleImgSrc(course) {
  if (course) {
    return new Promise((resolve) => {
      const prefix = "course/" + course.id + "/";
      const s3FileManager = new S3FileManager();
      s3FileManager.checkIfListExists(prefix).then(res => {
        if (res.length > 0) {
          s3FileManager.getSignedURL(prefix + "image.jpeg").then(url => {
            resolve(url);
          })
        } else {
          resolve("https://annsvg.com/the-content/uploads/2021/03/E-Learning_gr-1.jpg");
        }
      })
    })
  }
}


const state = {
  courses: [],
  myCourses: [],
  userEvents: [],
  username: null,
  jwtToken: null,
  friendlyName: null,
  role: null,
  group:null,
  email:null,
  courseDeleted:false,
  reloadNecessary:null,
  company:null,
  domain:null,
}

const getters = {
  courses: (state) => state.courses,
  myCourses: (state) => state.myCourses,
  events: (state) => state.userEvents,
  role: (state) => state.role,
  jwtToken: (state) => state.jwtToken,
  friendlyName: (state) => state.friendlyName,
  username: (state) => state.username,
  group: (state) => state.group,
  email: (state) => state.email,
  courseDeleted: (state) => state.courseDeleted,
  reloadNecessary: (state) => state.reloadNecessary,
  company: (state) => state.company,
  domain: (state) => state.domain,
}

const actions = {
  getCredentials(context) {
    return new Promise((resolve,reject) => {

      // Looking for the current session and user info
      Auth.currentSession().then(session => {
        Auth.currentUserInfo().then(userInfo => {

          // make sure we have a valid user info object.
          if (userInfo && Object.keys(userInfo).length !== 0) {
            const tokenExpiration = session.getAccessToken().getExpiration() * 1000;




            // setting localStorage with userInfo and token.
            localStorage.setItem("token",session.getIdToken().getJwtToken())
            localStorage.setItem("username",userInfo.username);
            localStorage.setItem("friendlyName",userInfo.attributes['custom:friendlyName'])
            localStorage.setItem("role", userInfo.attributes["custom:role"])
            localStorage.setItem("group", userInfo.attributes["custom:group"])
            localStorage.setItem("expiresAt",tokenExpiration.toString())
            localStorage.setItem("email",userInfo.attributes.email);


            // giving credentials to the mutation setCredentials
            context.commit("setCredentials",{
              "jwtToken": session.getIdToken().getJwtToken(),
              "username": userInfo.username,
              "friendlyName": userInfo.attributes['custom:friendlyName'],
              "role": userInfo.attributes["custom:role"],
              "email":userInfo.attributes.email,
              "group":userInfo.attributes["custom:group"],
            })
            resolve();
          } else{
            reject();
          }
        });
      });
    })
  },

  // Gets all courses and commits a mutation.
  getCourses(context){
    context.dispatch("getRequest","getCourses").then(courses =>{
      const list = JSON.parse(courses.body);
      context.commit("setCourses",list)
    })
  },

  //Gets all events and commits a mutation
  getEvents(context){
    context.dispatch("getRequest","getMyEvents").then(response => {
      let userObject = JSON.parse(response.body);
      context.commit("setEvents",userObject.events)
    });
  },

  // Updates the course we are currently going to.
  updateSingleCourse(context,courseId){
    context.dispatch("getRequest","getCourseById&query=" +  courseId).then((resp) => {
      const data = JSON.parse(resp.body);
      if(data) {
        context.commit('updateCourse', data);
        context.commit("updateLearningProgress", data.progress);
        context.commit("updateCourseDeleted",false);
      }else{
        context.commit("updateCourseDeleted",true);
      }
    })
  },

  signout(context){
    // we set all credentials in the store back to null
    context.commit("setCredentials",{
      "username": null,
      "jwtToken": null,
      "friendlyName": null,
      "role": null,
      "email":null,
      "group":null
    })

    // removing all items in localStorage
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    localStorage.removeItem("friendlyName");
    localStorage.removeItem("role");
    localStorage.removeItem("expiresAt");
    localStorage.removeItem("email");
    localStorage.removeItem("group");

    context.commit("emptyStateArrays");
    context.commit("emptyPosts");
    context.commit("emptyPerson");
    context.commit("emptyGroups");
    context.commit("emptyTags");
  },

  getCredentialsFromLocalStorage(context){

    // getting the localStorage items
    const token = localStorage.getItem("token");
    const username = localStorage.getItem("username")
    const friendlyName = localStorage.getItem("friendlyName")
    const role = localStorage.getItem("role")
    const email = localStorage.getItem("email")
    const group = localStorage.getItem("group")
    const expiration = localStorage.getItem("expiresAt")
    const company = localStorage.getItem("company")

    if(company) context.commit("setCompany",company);

    // time left until token is expired
    const timeLeft = Number(expiration) - new Date().getTime();

    // if time is below 0 the token is expired
    if( timeLeft < 0){
        return context.dispatch("signout");
    }



    // checking if token and username exists
    if(token && username){
      // token and username exists, now we commit "setCredentials" with all necessary data
      context.commit("setCredentials",{
        "jwtToken": token,
        "username": username,
        "friendlyName": friendlyName,
        "role": role,
        "email":email,
        "group":group,
      })
    }
  }
}

const mutations= {

  emptyStateArrays(state){
    state.courses = [];
    state.userEvents = [];
    state.myCourses = [];
  },


  // Setting credentials for state.
  setCredentials(state, payload){
    state.jwtToken = payload.jwtToken;
    state.role = payload.role;
    state.username = payload.username;
    state.friendlyName = payload.friendlyName;
    state.email = payload.email;
    state.group = payload.group;
    // still needed?? if(payload.company) state.company = payload.company;
  },

  // Setting company and domain for state.
  setCompany(state,payload){
    state.company = payload;
    if(payload.toLowerCase().includes("gleason")){
      state.domain = payload.toLowerCase().includes("gpm") ? "gleason" : "gleason_ch";
    }else{
      state.domain = payload;
    }

    localStorage.setItem("company",payload);
  },

  // If the user hasn't reloaded the page for 2 hours we set reloadNecessary true.
  reloadNecessary(state,payload){
    const reloadNecessary = localStorage.getItem('reloadNecessary');
    if(payload >= reloadNecessary){
      state.reloadNecessary = true;
    }
  },

  // The user closed the notification that informs the user to reload the page, so we set reloadNecessary to false
  resetReloadNecessary(state){
    state.reloadNecessary = false;
  },

  // Adds the course that the user enrolled into my courses.
  addMyCourse(state, course){
    if (Object.keys(state.myCourses).length !== 0 && !course.course.isCompulsory) {
      state.myCourses.push(course);
    }
  },

  // Iterating through all courses and setting the corresponding image for that course.
  setCourses(state, courses){
  for(let item of courses){
    const titleImgSrc = getTitleImgSrc(item.course);
    titleImgSrc.then(signedUrl => {
      item.course["iconSrc"] = signedUrl;
      state.courses.push(item);
    })
  }
},

  // Iterating through all courses in my courses and setting the corresponding image for that course.
  setMyCourses(state, courses){
  for(let item of courses){
    const titleImgSrc = getTitleImgSrc(item.course);
    titleImgSrc.then(signedUrl => {
      item.course["iconSrc"] = signedUrl;
      state.myCourses.push(item);
    })
  }
},

  // Gives us true if the course was not found (deleted) and false if not.
  updateCourseDeleted(state,payload){
    state.courseDeleted = payload;
  },

  // Setting all events the user has in userEvents
  setEvents(state, events){
    if(Object.keys(events).length !== 0) {
      for (let item of events) {
        state.userEvents.push(item);
      }
    }
  },

  // Adding new events into userEvents
  addNewEvent(state,event){
    if(state.userEvents.length !== 0) state.userEvents.push(event);
  },

  // Deletes the event in the store.
  deleteEvent(state,event){
    let counter = 0;
    for(let item of state.userEvents){
      if(event.eventId === item.id){
        state.userEvents.splice(counter,1);
      }
      counter++;
    }
  },

  // Adding the comment inside the courseObject.
  addComment(state,payload){
    const courseObject = state.courses.find((foundCourse) => foundCourse.id === payload.courseId);

    courseObject.course.comments.push(payload.comment);
  },

  // Updates the likedBy-array of the comment.
  updateCourseComment(state,payload){
    const courseObject = state.courses.find((foundCourse) => foundCourse.id === payload.courseId);

    const comment = courseObject["course"]["comments"].find((foundComment) => foundComment.id === payload.commentId);

    comment.likedBy.push(state.username);
  },

  // Deleting the comment from the course.
  deleteComment(state,payload){
    let counter = 0;
    const courseObject = state.courses.find((foundCourse) => foundCourse.id === payload.id);
    for(let comment of courseObject.course.comments){
      if(comment.id === payload.comment.id){
        courseObject.course.comments.splice(counter,1);
      }
      counter++;
    }
  },

  learningProgressChanged(state,progress){
    const courseObject = state.courses.find((foundCourse) => foundCourse.id === progress.courseId);
    const myCourseObject = state.myCourses.find((foundCourse) => foundCourse.id === progress.courseId);

    if(courseObject) courseObject.progress.learningProgress = progress.learningProgress;
    // Changing the progress if the course exists in myCourses.
    if(myCourseObject) myCourseObject.learningProgress = progress.learningProgress;
  },

  // Updating the learning progress of the current course.
  updateLearningProgress(state, progress){
    const courseObject = state.courses.find((foundCourse) => foundCourse.id === progress.courseId);
    const myCourseObject = state.myCourses.find((foundCourse) => foundCourse.id === progress.courseId);

    if(courseObject) courseObject.progress = progress;
    // Changing the progress if the course exists in myCourses.
    if(myCourseObject) myCourseObject.learningProgress = progress.learningProgress;
  },

  completionTimestamp(state,payload){
    const courseObject = state.courses.find((foundCourse) => foundCourse.id === payload);
    if(courseObject) courseObject.progress["completionTimestamp"] = new Date().getTime().toString();
  },

  // Updates the users questionProgress of the current course
  updateQuestion(state,payload){
    // Finding current course in courses.
    const courseObject = state.courses.find((foundCourse) => foundCourse.id === payload.courseId);
    // Finding current question in the course.
    let questionObject = courseObject.progress.questions.find((foundQuestion) => foundQuestion.id === payload.questionObject.id);
    // Setting users answer into the questionObject
    if(questionObject) questionObject = payload.questionObject;
  },

  // Triggers when courses get updated or added.
  updateCourse(state,payload){
  // getting the titleImage source for the course
  const titleImgSrc = getTitleImgSrc(payload.course);

  titleImgSrc.then(signedUrl => {
    const course = payload.course;
    course.iconSrc = signedUrl;

    // looking if the course is a new course or already exists
      if (payload.isNewCourse) {
        // course is new.
        state.courses.unshift({
          "course": course,
          "id": payload.course.id,
          "domain":state.domain,
        });

        /**
         * If we are an admin and we create a course which is then compulsory,
         * we will not see it, unless we modify the myCourses object directly.
         */
        if(payload.course.isCompulsory && Object.keys(state.myCourses).length !== 0) {
          state.myCourses.unshift({
            "course": course,
            "id": payload.course.id,
          });
        }
    } else {
        // Iterating through courses and looking if the id matches.
        const courseObject = state.courses.find((foundCourse) => foundCourse.id === payload.course.id);

        // Assign values for course.
        if(courseObject) {
          courseObject.course = payload.course;
          courseObject.course.iconSrc = signedUrl;
        }

        // Checking if my Courses exists.
        // now also update my courses list.
        if (Object.keys(state.myCourses).length !== 0) {
          // Iterating through courses and looking if the id matches.
          const courseObject = state.myCourses.find((foundCourse) => foundCourse.id === payload.course.id);
          // Assign values for course.
          if(courseObject !== undefined){
            courseObject.course = payload.course;
            courseObject.course.iconSrc = signedUrl;
          }
        }
    }
  });
},

  // Deleting course
  deleteCourse(state, course){
    let index = 0;
    // iterating through courses
    for(let deleteCourse of state.courses){
      if(deleteCourse.id === course.id){
        // Ids match, now we splice the course from state.courses.
        state.courses.splice(index,1);
      }
      // increasing index everytime the ids don't match.
      index++;
    }
    // Looking if my courses isn't empty.
    if(Object.keys(state.myCourses).length !== 0){
      // resetting index count.
      index = 0;
      // iterating through myCourses.
      for(let deleteMyCourse of state.myCourses){
        if(deleteMyCourse.id === course.id){
          // Ids match, now we splice the course of myCourses.
          state.myCourses.splice(index,1);
        }
        // increasing index everytime the ids don't match.
        index++;
      }
    }
  },
}

const store = createStore({
  modules:{
    modules: requestModule,
             personStore,
             groupStore,
             homeStore,
             tagStore
  },
  state,
  mutations,
  actions,
  getters,
})

export default store;
