
// Getting the image source for the course.
import {S3FileManager} from "@/mixins/s3-file-manager/s3-file-manager";

function getTitleImgSrc(newsId){
    return new Promise((resolve)=>{
        const prefix = "news/" + newsId + "/";
        const s3FileManager = new S3FileManager();
        s3FileManager.checkIfListExists(prefix).then(res => {
            if(res.length > 0){
                s3FileManager.getSignedURL("news/" + newsId + "/image.jpeg").then(url=>{
                    resolve(url);
                })
            } else {
                resolve("https://kashmirobserver.net/wp-content/uploads/2020/11/Online-News.jpg");
            }
        })
    })
}

const state = {
    posts: [],
}

const getters = {
    posts : (state) => state.posts,
}

const actions = {
    getPosts(context){
        context.dispatch("getRequest","getNews").then(news => {
            const newsList = JSON.parse(news.body);
            context.commit("setPosts",newsList);
        });
    },

    deletePost(context,payload){
        // delete the dynamodb entry
        context.dispatch("postRequest",{
            "param":"deletePost",
            "payload":payload.post
        }).then(() => {
            context.commit("deletePost",payload.post.id)
        });
    },

}

const mutations = {

    emptyPosts(state){
        state.posts = [];
    },

    setPosts(state,payload) {
        for (let post of payload) {
            const image = getTitleImgSrc(post.post.id)
            image.then(signedUrl => {
                post["iconSrc"] = signedUrl;
                state.posts.push(post);
            })
        }
    },

    updatePost(state,payload) {

        const titleImg = getTitleImgSrc(payload.id);
        titleImg.then(src => {
            let foundPost = false;
            for (let item of state.posts) {
                if (item.post.id === payload.id) {
                    const index = state.posts.indexOf(item);
                    state.posts[index].post = payload;
                    state.posts[index].iconSrc = src;
                    foundPost = true;
                    break;
                }
            }
            if (!foundPost) {
                state.posts.unshift({
                    "iconSrc": src,
                    "id": payload.id,
                    "post": payload
                })
            }
        })
    },

    deletePost(state,postId){
        for (let item of state.posts){
            if (item.post.id === postId){
                const index = state.posts.indexOf(item);
                state.posts.splice(index,1);
                break;
            }
        }
    },
}

const homeStore = {
    state,
    getters,
    actions,
    mutations,
}

export default homeStore;