<!--
Within the course popup, we can create or edit new courses. The details section
is included inside the tab course details, and inside modules, we can create modules
and associated templates.
-->

<template>
  <div>
    <div>
      <!--
        The tab navigation can either be set to course details or modules.
      -->
      <TabNav :selected="selected" :tabs="[this.$t('courseDetails'), this.$t('modules'), this.$t('rating')]"
              @selected="setSelected">
        <Tab :is-selected="selected === this.$t('courseDetails')">
          <AddCourseDetails @course-changed="this.updateCourseDetails"
                            :is-new-course="this.isNewCourse" :course="this.courseDetails"
                            :status-is-empty="this.statusIsEmpty"
                            :duration-is-negative="this.durationIsNegative"
                            :title-is-empty="this.titleIsEmpty"
                            :course-has-end-date="courseHasEndDate"></AddCourseDetails>
        </Tab>
        <Tab :is-selected="selected === this.$t('modules')">
          <CourseModules @modules-updated="this.updateCourseModules" :course="this.course"></CourseModules>
        </Tab>
        <Tab :is-selected="selected === this.$t('rating')">
          <CourseRating :hasRating="courseHasRating" :ratingPercentage="this.ratingPercentage"
                        :has-certificate="courseHasCertificate" :max-attempts="maxAttempts"
                        @rating-toggle-changed="this.changeCourseRatingState"
                        @certificate-toggle-changed="this.changeCourseCertificateState"
                        @rating-percentage-changed="this.changeRatingPercentage"
                        @max-attempts-changed="this.changeMaxAttempts"></CourseRating>
        </Tab>
      </TabNav>
    </div>
    <div class="btn-wrapper">
      <PrimaryBtn class="course-submit-btn" @click="submitCourse" type="submit" :val="$t('confirm')"></PrimaryBtn>
    </div>
  </div>
  <Loader :show="this.showLoader"></Loader>
</template>

<script>
import TabNav from "../../tabs/TabNav";
import Tab from "../../tabs/Tab";
import AddCourseDetails from "./course-tab-content/AddCourseDetails";
import CourseModules from "./course-tab-content/CourseModules";
import PrimaryBtn from "../../buttons/PrimaryBtn";
import CourseRating from "@/components/course/course-manager/course-tab-content/CourseRating";
import Loader from "../../loader/Loader";
import SweetAlerts from "@/mixins/sweet-alerts/SweetAlerts";

export default {
  name: 'AddCourseForm',
  components: {
    CourseRating,
    CourseModules,
    AddCourseDetails,
    Tab,
    TabNav,
    PrimaryBtn,
    Loader
  },
  mixins:[SweetAlerts],

  props: {
    isNewCourse: Boolean,
    course: Object,
  },

  data() {
    return {
      selected: this.$t('courseDetails'),
      modalIsOpen: false,
      courseDetails: this.course,
      courseModules: this.course["courseModules"],
      titleIsEmpty: false,
      statusIsEmpty: false,
      courseModulesIsEmpty: false,
      durationIsNegative: false,
      courseHasEndDate: false,
      courseHasRating: this.course.rating ? this.course.rating.hasRating : false,
      courseHasCertificate: this.course.rating ? this.course.rating.hasCertificate : false,
      ratingPercentage: this.course.rating ? this.course.rating.ratingPercentage : 0,
      maxAttempts:  this.course.rating ? this.course.rating.maxAttempts : 0 ,
      showLoader: false
    }
  },

  methods: {
    setSelected(tab) {
      this.selected = tab;
    },

    updateCourseModules(courseModules) {
      this.courseModules = courseModules;
    },

    updateCourseDetails(courseDetails) {
      this.courseDetails = courseDetails;
    },

    emitCourseChangedEvent() {
      this.$emit("course-changed", this.courseDetails);
    },

    changeCourseRatingState(hasRating) {
      this.courseHasRating = hasRating;
    },
    changeCourseCertificateState(hasCertificate) {
      this.courseHasCertificate = hasCertificate;
    },

    changeRatingPercentage(percentage) {
      this.ratingPercentage = percentage;
    },

    changeMaxAttempts(maxAttempts) {
      this.maxAttempts = maxAttempts
    },
    // If the course is live e.g. publicationStatus = 20, we check if courseModules exists and if the course has modules and templates.
    // If their length equals 0 we know its empty and set courseModulesIsEmpty = true.
    checkIfCourseModulesIsEmpty(){
      if(this.courseDetails["publicationStatus"] === "20"){
        if(this.courseModules === undefined || this.courseModules === null || this.courseModules === ""){
          this.courseModulesIsEmpty = true;
          this.showErrorMessage(this.$t('liveCourseModulesIsEmpty'));
        }
        if(this.courseModules){
          if(this.courseModules.length === 0){
            this.showErrorMessage(this.$t('liveCourseModulesIsEmpty'));
            this.courseModulesIsEmpty = true;
          }else if(this.courseModules[0].courseTemplates.length === 0){
            this.showErrorMessage(this.$t('liveCourseModulesIsEmpty'));
            this.courseModulesIsEmpty = true;
          }else{
            this.courseModulesIsEmpty = false;
          }
        }
      }else{
        this.courseModulesIsEmpty = false;
      }
    },

    submitCourse() {
      // Checking if status is set or empty
      this.statusIsEmpty = this.courseDetails["publicationStatus"] === null
          || this.courseDetails["publicationStatus"] === ''
          || this.courseDetails["publicationStatus"] === undefined;

      // Checking if title is set or empty
      this.titleIsEmpty = this.courseDetails["title"] === undefined
          || this.courseDetails["title"] === '';

      // Checking if duration is negative
      this.durationIsNegative = this.courseDetails["duration"] < 0

      // Checking if, course has end date when compulsory
      if(this.courseDetails["isCompulsory"]){
        this.courseHasEndDate = this.courseDetails["courseCompulsoryEndDate"]["date"] === null
            || this.courseDetails["courseCompulsoryEndDate"]["date"] === ''
            || this.courseDetails["courseCompulsoryEndDate"]["date"] === undefined
        || this.courseDetails["courseCompulsoryEndDate"]["date"].length === 0
      }

      // Showing sweet-alert when validated inputs are empty.
      if(this.statusIsEmpty || this.titleIsEmpty || this.courseHasEndDate){
        this.showErrorMessage("Gewisse Pflichtfelder sind noch leer. Bitte schauen Sie in Kurs Details nach")
      }

      // Showing sweet-alert when duration is negative.
      if(this.durationIsNegative){
        this.showErrorMessage("Dauer des Kurses kann nicht negativ sein.")
      }

      this.checkIfCourseModulesIsEmpty()

      // Are title and status and courseHasEndDate not empty and is duration negative?
      if (!this.statusIsEmpty && !this.titleIsEmpty && !this.durationIsNegative && !this.courseHasEndDate && !this.courseModulesIsEmpty) {
        // Yes now we submit the course
        // We set the courseModules of the course
        this.courseDetails["courseModules"] = this.courseModules;

        // Creates the comments-array if it doesn't exist
        if (!this.courseDetails["comments"]) {
          this.courseDetails["comments"] = [];
        }
        // Creates rating if it doesn't exist
        if (!this.courseDetails["rating"]) {
          this.courseDetails["rating"] = {"hasRating": this.courseHasRating};
          this.courseDetails["rating"] = {"ratingPercentage": this.ratingPercentage};
          this.courseDetails["rating"] = {"hasCertificate": this.courseHasCertificate};
          this.courseDetails["rating"] = {"maxAttempts": this.maxAttempts};
        } else {
          this.courseDetails["rating"]["hasRating"] = this.courseHasRating;
          this.courseDetails["rating"]["ratingPercentage"] = this.ratingPercentage;
          this.courseDetails["rating"]["hasCertificate"] = this.courseHasCertificate;
          this.courseDetails["rating"]["maxAttempts"] = this.maxAttempts;
        }

        this.showLoader = true;
        const postObject = {
          "param": "editCourse",
          "payload": this.courseDetails
        };

        this.$store.dispatch("postRequest", postObject).then(() => {
          this.emitCourseChangedEvent();
          this.showLoader = false;
        });
      }
    },
  },
}
</script>

<style scoped>

.thumbnail-empty:hover svg {
  fill: var(--primary-color);
  cursor: pointer;
}

.course-submit-btn {
  position: fixed;
  right: 64px;
  bottom: 64px;
}

@media screen and (max-width: 600px) {
  .course-submit-btn {
    right: 40px;
    bottom: 40px;
  }
}

</style>
