<template>
  <div class="container">
    <div class="card-container">
      <div class="flex">
        <svg xmlns="http://www.w3.org/2000/svg" height="34px" viewBox="0 0 24 24" width="34px" fill="#555"><path d="M0 0h24v24H0z" fill="none"/><path d="M5 13.18v4L12 21l7-3.82v-4L12 17l-7-3.82zM12 3L1 9l11 6 9-4.91V17h2V9L12 3z"/></svg>
        <span class="account-headline">{{ $t('createdCourses') }}</span>
      </div>
      <div>
        <div class="account-details-wrapper">
          <span class="label">({{ $t('unlimited') }})</span>
          <span style="color:green; font-size: 24px">{{this.courseCount}}</span>
        </div>
      </div>
    </div>
    <div class="card-container">
      <div class="flex">
        <svg xmlns="http://www.w3.org/2000/svg" height="34px" viewBox="0 0 24 24" width="34px" fill="#555"><path d="M0 0h24v24H0z" fill="none"/><path d="M10 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2h-8l-2-2z"/></svg>
        <span class="account-headline">{{ $t('usedMemory') }}</span>
      </div>
      <div>
        <div class="account-details-wrapper">
          <span class="label">(2%)</span>
          <span style="color:green; font-size: 24px">1,9 GB</span>
        </div>
      </div>
    </div>
    <div class="card-container">
      <div class="flex">
        <svg xmlns="http://www.w3.org/2000/svg" height="34px" viewBox="0 0 24 24" width="34px" fill="#555"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M8 16h8v2H8zm0-4h8v2H8zm6-10H6c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 18H6V4h7v5h5v11z"/></svg>
        <span class="account-headline">{{ $t('objectCount') }}</span>
      </div>
      <div>
        <div class="account-details-wrapper">
          <span class="label">({{ $t('unlimited') }})</span>
          <span style="color:green; font-size: 24px">{{708}}</span>
        </div>
      </div>
    </div>
    <div class="card-container">
      <div class="flex">
        <svg xmlns="http://www.w3.org/2000/svg" height="34px" viewBox="0 0 24 24" width="34px" fill="#555"><g><path d="M0,0h24v24H0V0z" fill="none"/></g><g><g><circle cx="10" cy="8" r="4"/><path d="M10.67,13.02C10.45,13.01,10.23,13,10,13c-2.42,0-4.68,0.67-6.61,1.82C2.51,15.34,2,16.32,2,17.35V20h9.26 C10.47,18.87,10,17.49,10,16C10,14.93,10.25,13.93,10.67,13.02z"/><path d="M20.75,16c0-0.22-0.03-0.42-0.06-0.63l1.14-1.01l-1-1.73l-1.45,0.49c-0.32-0.27-0.68-0.48-1.08-0.63L18,11h-2l-0.3,1.49 c-0.4,0.15-0.76,0.36-1.08,0.63l-1.45-0.49l-1,1.73l1.14,1.01c-0.03,0.21-0.06,0.41-0.06,0.63s0.03,0.42,0.06,0.63l-1.14,1.01 l1,1.73l1.45-0.49c0.32,0.27,0.68,0.48,1.08,0.63L16,21h2l0.3-1.49c0.4-0.15,0.76-0.36,1.08-0.63l1.45,0.49l1-1.73l-1.14-1.01 C20.72,16.42,20.75,16.22,20.75,16z M17,18c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S18.1,18,17,18z"/></g></g></svg>
        <span class="account-headline">{{ $t('createdUsers') }}</span>
      </div>
      <div>
        <div class="account-details-wrapper">
          <span class="label">({{ $t('unlimited') }})</span>
          <span style="color:green; font-size: 24px">{{this.users.length}}</span>
        </div>
      </div>
    </div>

  </div>


</template>

<script>


export default {
  name: 'AccountDashboard',
  data(){
    return {
      courseCount:0,
      users: [],
    }
  },

  methods:{
    openModal(){
      this.modalIsOpen = !this.modalIsOpen;
    }
  },

  mounted(){
    this.$store.dispatch("getRequest","getTotalCoursesCount").then(res => {
      this.courseCount = res.body;
    });
    this.$store.dispatch("getRequest","getUsers").then(res => {
      this.users = JSON.parse(res.body)
    })
  }
}
</script>

<style scoped>

.container{
  display: flex;
  gap: 20px;
}
.card-container{
  border: 1px solid var(--brightgrey);
  margin-bottom: 24px;
  box-shadow: 0 0 5px rgb(169 191 203);
  border-radius: 4px;
  position: relative;
  z-index: 1;
  background-color: var(--bright);
  padding: 20px;
  max-width:270px;
  justify-content: space-around;
  align-items: center;
  margin-top: 30px;
}

.account-details-wrapper:first-child{
  margin-top: 10px;
}

.account-details-wrapper{
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  justify-content: space-around;
  align-items: center;
  white-space: nowrap;
}

.account-details-wrapper:last-child{
  margin-bottom: 0;
}

.flex{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex > span{
  font-size: 20px;
}

.label{
  font-size: 12px;
}

.account-headline{
  font-size: 22px;
  color: var(--darkgrey);
}

svg{
  margin-right: 3px;
}

@media screen and (max-width: 700px){
  .container{
    display: block;
    gap: 0;
  }
}
</style>
