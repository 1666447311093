<template>
  <div class="main-content-container">
    <div class="apart">
      <h1>{{ $t("nav.persons") }} ({{this.personTableData.length}})</h1>
    </div>
    <PrimaryBtn v-if="this.isAdmin" @click="openModal(true)" :val="$t('person.add')"></PrimaryBtn>

    <MaterialTable @item-viewed="openLearningInfo" @item-edited="editPerson" @item-deleted="deleteUser"
                   :item-delete="itemDelete" :item-edit="itemEdit" :item-view="itemView" :table-headers="tableHeaders"
                   :table-rows="tableRows" :inside-group-table="false"></MaterialTable>
  </div>

  <Modal :val="this.$t('back')" @close-form="this.closeModal" :heading="this.$options.personModalHeader" :modal-is-open=modalIsOpen>
    <AddPersonForm :person="this.selectedPerson" :is-new-person="this.isNewPerson" @person-updated="this.personUpdated"
                   @close-form="this.closeModal"></AddPersonForm>
  </Modal>

  <LearningInfo :courseOptions="this.userCourseOptions" :selectedUserCourses="this.selectedUserCourses" :selectedPersonId="this.selectedPersonId"
                @course-assigned="courseAssigned" @modal-closed="closeLearningInfo" :modalIsOpen=learningInfoIsOpen></LearningInfo>

  <Loader :show="showLoader"></Loader>
</template>

<script>

import Modal from "../components/modal/Modal";
import PrimaryBtn from "../components/buttons/PrimaryBtn";
import AddPersonForm from "../components/admin/persons/AddPersonForm";
import LearningInfo from "@/components/user/learning-info/LearningInfo";
import Uuidv4Generator from "@/mixins/uuid/Uuidv4Generator";
import SweetAlerts from "@/mixins/sweet-alerts/SweetAlerts";
import MaterialTable from "@/components/material-table/MaterialTable";
import Loader from "@/components/loader/Loader";


export default {
  components: {Loader, MaterialTable, LearningInfo, AddPersonForm, Modal, PrimaryBtn},
  mixins:[Uuidv4Generator, SweetAlerts],
  personModalHeader: "",
  data: () => ({
    modalIsOpen: false,
    learningInfoIsOpen: false,
    selectedUserCourses: [],
    selectedPerson: {},
    selectedPersonId: null,
    persons: [],
    isNewPerson: true,
    totalCourseOptions: [],
    itemView: true,
    itemEdit: true,
    itemDelete: true,
    showLoader: false,
    isAdmin: false,
  }),

  methods: {
    switchSelect(event) {
      this.selected = event.target.value;
    },

    openModal(isNewPerson){
      if (this.role === "admin"){
        this.isNewPerson = isNewPerson;
        this.modalIsOpen = true;
        this.$options.personModalHeader = this.$t("person.add");
      } else {
        this.showErrorMessage(this.$t('rightsError'));
      }
    },

    closeModal(){
      this.modalIsOpen = false;
      this.selectedPerson = {};
    },

    editPerson(person){
      this.selectedPerson = person;

      if(this.selectedPerson.code){
        this.showWarningMessage(this.$t('cantEditCreatedUsersWithCode'));
      }else{
        this.openModal(false);
        this.$options.personModalHeader = this.$t("person.edit");
      }
    },

    /**
     * Cb from the add person form.
     * @param event
     */
    personUpdated(event){
      this.showLoader = true
      this.$store.dispatch("updatePerson",{
        "person":event.data,
        "isNewPerson":event.isNewPerson
      }).then(()=>{
        if(event.data.code === true){
          this.$swal({
            title: this.$t('pleaseCopyCredentials'),
            text: this.$t('createdId') +  event.data.id + '\n' + this.$t('createdPassword') + this.password,
            icon: 'warning',
            type: "warning",
            confirmButtonColor: "#007AC2",
            confirmButtonText: this.$t('copyCredentials'),
          }).then((result) => { // <--
            if (result.value && result.isConfirmed) {
              navigator.clipboard.writeText("ID: " + event.data.id + " Password: " + this.password)
            }
          })
        }else if(this.isNewPerson) {
          this.showSuccessMessage(this.$t('form.person') + ' ' + this.$t('addSuccess'));
        }else{
          this.showSuccessMessage(this.$t('form.person') + ' ' + this.$t('swal.updateSuccess'));
        }
      })
      this.showLoader = false;
    },

    openLearningInfo(item){
      this.selectedPersonId = item.id;

      this.showLoader = true;
      this.$store.dispatch("getRequest","getUsersCourses&query=" +  item.id).then(response => {
        this.selectedUserCourses = JSON.parse(response.body);

        this.$store.dispatch("getRequest","getCoursesTitle").then(response => {
          this.showLoader = false;
          this.totalCourseOptions = JSON.parse(response.body);
        })
      })

      this.learningInfoIsOpen = true;
    },

    closeLearningInfo(){
      this.learningInfoIsOpen = false;
      this.selectedUserCourses = [];
      this.totalCourseOptions = [];
      this.selectedPerson = {};
    },

    courseAssigned(params){
      const courseId = params.courseId;
      const userId = params.id;

      this.showLoader = true;
      this.$store.dispatch("postRequest",{
        "param":"subscribeToCourse",
        "payload":{
          "courseId":courseId,
          "userId": userId,
          "subscriptionTimestamp": new Date().getTime().toString()
        }
      }).then(() => {
        this.showLoader = false;
        this.closeLearningInfo();
        this.openLearningInfo(params);
      })
    },

    deleteUser(item){
      if (this.role === "admin"){
        if(item.id === this.$store.getters.username){
          this.showErrorMessage(this.$t('cantDeleteYourself'));
        }else{
          this.showConfirmDialogue(this.$t('deleteUser'), this.$t('swal.confirmDelete')).then(async res => {
            if (res) {
              this.showLoader = true;
              this.$store.dispatch("deleteUser", item).then(() => {
                this.showSuccessMessage(this.$t('form.person') + ' ' + this.$t('deleteSuccess'));
                this.showLoader = false;
              });
            }
          })
        }
      } else {
        this.showErrorMessage(this.$t('rightsError'));
      }
    },
  },

  computed: {

    role(){
      return this.$store.getters.role;
    },

    password(){
      return this.$store.getters.password;
    },

    personTableData(){
      return this.$store.getters.myUsers;
    },

    tableHeaders(){
      return[{label:this.$t('form.person.email')},{label:this.$t('form.person.friendlyName')},{label:this.$t('form.group')},{label:this.$t('form.person.persNum')}, {label:this.$t('form.person.role')}]
    },

    tableRows(){
      let data = [];

      for(let person of this.personTableData){
        let personCode = false;
        const personName = person.friendlyName;
        const personGroup = person.group;
        const personPersNum = person.persNum;
        const personRole = person.role;
        let personEmail = person.email;
        if(person.email.includes("@learningonetimeuser.gl")){
          personEmail = this.$t('oneTimeUser');
          // Setting personCode to true, so we can't edit the user, since he is a oneTimeUser.
          personCode = true;
        }
        const personCourses = person.courses;
        const personDomain = person.domain;
        const personEvents = person.events;
        const id = person.id;


        let cells= [];

        cells.push(personEmail,personName,personGroup,personPersNum, personRole);

        data.push({"id": id,"name":personName,"group":personGroup, "persNum": personPersNum, "role":personRole,"email":personEmail,"domain":personDomain,"courses":personCourses,"events":personEvents,"code":personCode,cells:cells});
      }
      return data;
    },

    userCourseOptions(){
      const options = [];

      for (let option of this.totalCourseOptions){
        if (!this.selectedUserCourses["courseIdList"].includes(option.courseId)){
          options.push(option);
        }
      }
      return options;
    },
  },

  mounted(){
    if(this.role) if (this.role.toLowerCase() === "admin") this.isAdmin = true;

    this.$store.dispatch("getMyUsers");
  }
}
</script>

<style scoped>
  h1{
    margin-bottom: 24px;
  }

  .apart{
    display: flex;
    justify-content: space-between;
  }

  /*table styling*/

  .delete-icon svg:hover{
    fill: red;
    cursor: pointer;
  }

  .edit-icon svg:hover{
    fill: var(--primary-color);
    cursor: pointer;
  }

  .view-icon svg:hover{
    fill: var(--primary-color);
    cursor: pointer;
  }

  /*noinspection CssUnusedSymbol*/
  .asc:after{
    content: "\25B2";
    font-size: 10px;
  }

  /*noinspection CssUnusedSymbol*/
  .desc:after{
    content: "\25BC";
    font-size: 10px;
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }

  input{
    line-height: 48px;
  }

  th, td {
    text-align: left;
    padding: 10px;
  }

  th {
    border-bottom: 2px solid var(--darkgrey);
  }

  tr {
    border-bottom: 1px solid var(--brightgrey);
  }

  /*search filter styling*/
  input {
    outline: none;
    border: none;
  }

  input[type=search] {
    -webkit-appearance: textfield;
    -webkit-box-sizing: content-box;
    font-family: inherit;
    font-size: 100%;
  }

  input::-webkit-search-decoration,
  input::-webkit-search-cancel-button {
    display: none;
  }

  input[type=search] {
    background: url(https://static.tumblr.com/ftv85bp/MIXmud4tx/search-icon.png) no-repeat 9px center;
    border: solid 1px var(--brightgrey);
    padding: 5px 10px 5px 32px;


    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;

    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    transition: all .5s;
  }

  input[type=search]:focus {

    border-color: var(--primary-color);
  }

  input:-moz-placeholder {
    color: #999;
  }
  input::-webkit-input-placeholder {
    color: #999;
  }

  input[type=search] {
    padding-right: 10px;
    color: black;
    cursor: pointer;
  }
  input[type=search]:hover {
    background-color: #fff;
  }
  input[type=search]:focus {
    color: #000;
    background-color: #fff;
    cursor: auto;
  }

</style>
