<template>
  <h3>{{ this.$t('selectPdf') }}</h3>
  <input type="file" accept="application/pdf" @change="convertToImage"/>

  <div id="image-container">
  </div>

</template>

<script>

import {createLoadingTask} from 'vue3-pdfjs/esm';
import SweetAlerts from "@/mixins/sweet-alerts/SweetAlerts";
import {S3FileManager} from "@/mixins/s3-file-manager/s3-file-manager";

export default {
  name: "PdfImageConverter",
  mixins: [SweetAlerts],

  data() {
    return {
      courseModuleData: this.course.courseModules ? this.course.courseModules : [],
    }
  },

  props: {
    module: {},
    course: {},
  },

  methods: {
    async convertToImage(event) {
      if (event.target.files[0] === null || event.target.files[0] === undefined) {
        this.showErrorMessage(this.$t('noFileFound'));
      } else {
        // Getting the uploaded file.
        const file = event.target.files[0];
        // Is the file bigger than 25MB.
        if (file.size > 26214400) {
          // Yes, we show an alert that informs the user.
          this.showWarningMessage(this.$t('fileExceedSize'));
        } else {
          // creating a fileReader that translates the file into a DataURL.
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);

          fileReader.addEventListener("load", () => {
            // We set the file as the translated DataURL.
            const file = fileReader.result;

            const loadingTask = createLoadingTask(file);
            loadingTask.promise.then(async (pdf) => {
              // Iterating through the pages of the file
              for (let i = 1; i <= pdf.numPages; i++) {
                // We get the current page that we iterate through.
                await pdf.getPage(i).then(async(page) => {
                  // setting the viewport of the image.
                  const scale = 5.0;
                  const viewport = page.getViewport({scale: scale});

                  // Prepare canvas using PDF page dimensions
                  const canvasBody = document.createElement('canvas');
                  const canvas = document.getElementById('image-container').appendChild(canvasBody);
                  const context = canvas.getContext('2d');
                  canvas.height = viewport.height;
                  canvas.width = viewport.width;
                  canvas.style.width = "100%"
                  canvas.style.height = "100%"

                  // Render PDF page into canvas context
                  const renderContext = {
                    canvasContext: context,
                    viewport: viewport
                  };

                  const renderTask = page.render(renderContext);
                  const newTemplateObject = {
                    "title": "neu",
                    "type": "image",
                    "description": "",
                    "editorData": {
                      "imgSrc": "",
                      "imgType": ""
                    },
                    "id": new Date().getTime().toString()
                  }

                  for (let module of this.courseModuleData) {
                    if (module.id === this.module.id) {
                      const moduleIndex = this.courseModuleData.indexOf(module);
                      this.courseModuleData[moduleIndex].courseTemplates.push(newTemplateObject);
                    }
                  }

                  await renderTask.promise.then(async () => {
                    await canvas.toBlob(async (blob) => {
                      const path = "course/" + this.course.id + "/" + this.module.id + "/" + newTemplateObject.id + "/image.jpeg";

                      const s3FileManager = new S3FileManager();

                      await s3FileManager.uploadFile(blob, path);
                    })
                  });
                });
              }
            }, (reason) => {
              // PDF loading error
              console.error(reason);
            });
          })
        }
      }
    },
  },
}

</script>

<style scoped>
h3{
  margin-bottom: 10px;
}

</style>