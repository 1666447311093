const state =  {
    tags: [],
}

const getters = {
    tags: (state) => state.tags,
}

const actions = {
    getTags(context){
        context.dispatch("getRequest","getTags").then(resp => {
            const data = JSON.parse(resp.body);
            context.commit("setTags",data);
        })
    },

    deleteTag(context,payload) {
        context.dispatch("postRequest", {
            "param": "deleteTag",
            "payload": payload
        }).then(() => {
            context.commit("deleteTag",payload)
        })
    }
}

const mutations = {

    emptyTags(state){
        state.tags = [];
    },

    setTags(state,payload){
        for (let item of payload){
            state.tags.push(item.tag);
        }
    },

    updateTag(state,payload){

        if (payload.isNewTag){
            state.tags.unshift({
                "id": payload.tag.id,
                "tag": payload.tag.tag,
                "description": payload.tag.description,
            })
        } else {
            // updating the correct tag if ids are identical.
            for (let tag of state.tags){
                if (tag.id === payload.tag.id){
                    const index = state.tags.indexOf(tag);
                    state.tags[index] = payload.tag;
                    break;
                }
            }
        }
    },

    deleteTag(state,payload){
        let counter = 0;
        for(let item of state.tags){
            if(item.id === payload.id){
                state.tags.splice(counter,1);
            }
            counter++;
        }
    }
}

const tagStore = {
    state,
    getters,
    actions,
    mutations,
}

export default tagStore