import {css} from "lit";

export const UploadIndicatorStyles = css`
    .rootDiv{
        position:fixed;
        top:0;
        left:0;
        z-index:100000000;
        width:100vw;
        background-color:lightgrey;
    }
    .outerDiv{
        margin:5px;
        background-color:white;
        box-shadow:  0 0 0 1px rgba(0,0,0,.15), 0 2px 3px rgba(0,0,0,.2);
    }
    .progressIndicator{
       border-radius:5px;
       background-color: #85AEFF;
       padding:3px;
       white-space:nowrap;
    }
    span{
        color:black;
        white-space:nowrap;
        font-family: Arial,serif;
    }
    img{
        float:right;
        cursor:pointer;
    }
    img:hover{
        opacity:0.5;
    }
`;
