<template>
  <div>
    <quill-editor
        ref="quillEditorContentView"
        theme="bubble">
    </quill-editor>
  </div>
</template>
<script>

import {QuillEditor} from "@vueup/vue-quill";

export default {
  components: {QuillEditor},
  props: {
    description: String
  },

  mounted() {
    if(this.description)this.$refs.quillEditorContentView.setContents(this.description.ops);
  }
}

</script>
<style>
</style>
