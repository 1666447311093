<template>
  <button>{{ val }}</button>
</template>

<script>
export default {
  name: 'PrimaryBtn',
  props: {
    val: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  button{
    background-color: var(--primary-color);
    border: none;
    padding: 10px 24px;
    font-size: 14px;
    /*font-weight: bold;*/
    color: white;
    border-radius: 4px;
  }
  button:hover{
    cursor: pointer;
    box-shadow: 0 0 5px rgb(169 191 203);
  }
</style>
