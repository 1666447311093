import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Library from "../views/Library";
import Login from "../views/auth/Login";
import TermsOfUse from "../views/policies/TermsOfUse";
import Help from "../views/help/Help";
import About from "../views/About";
import SingleviewArticle from "../views/SingleviewArticle";
import SingleviewCourse from "../views/SingleviewCourse";
import Courses from "../views/Courses";
import Calendar from "../views/Calendar";
import Persons from "../views/Persons";
import Groups from "../views/Groups";
import Tags from "../views/Tags";
import Settings from "../views/Settings";
import store from "/src/store/index"

const routes = [
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/terms-of-use',
    name: 'Terms of use',
    component: TermsOfUse
  },
  {
    path: '/help',
    name: 'Help',
    component: Help
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta:{
      keepAlive: true
    }
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/news/article/:id',
    name: 'News Id',
    component: SingleviewArticle,
  },
  {
    path: '/library',
    name: 'Library',
    component: Library,
    meta:{
      keepAlive: true
    }
  },
  {
    path: '/library/course/:id',
    name: 'Course Id',
    component: SingleviewCourse
  },
  {
    path: '/my-courses',
    name: 'My courses',
    component: Courses,
    meta:{
      keepAlive: true
    }
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component:Calendar
  },
  {
    path: '/persons',
    name: 'Persons',
    component: Persons
  },
  {
    path: '/groups',
    name: 'Gruppen',
    component: Groups
  },
  {
    path: '/tags',
    name: 'Tags',
    component: Tags
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

/**
 * Before we route, we need to implement a few conditions, as we are only allowed to
 * see certain routes if we have the permission to. The permission depends on
 * the user role that we will get from cognito.
 */
router.beforeEach((to, from, next) => {
  // if our to path is login, we always allow.
  switch (to.path){
    case "/login":
      next();
      break;
    case "/persons":
      if (store.getters.role.toLowerCase() === "admin_light" || store.getters.role.toLowerCase() === "admin"){
        next();
      } else {
        // otherwise, we will stay on the current page.
        next (from.path);
      }
      break;
    case "/tags":
      if (store.getters.role.toLowerCase() === "admin"){
        next();
      } else {
        // otherwise, we will stay on the current page.
        next (from.path);
      }
      break;
    case "/groups":
      if (store.getters.role.toLowerCase() === "admin_light" || store.getters.role.toLowerCase() === "admin"){
        next();
      } else {
        // otherwise, we will stay on the current page.
        next (from.path);
      }
      break;
    default:
      next();
      break;
  }
})

export default router
