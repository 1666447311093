<template>
  <a @click="clickCallback">
    <img :src=iconSrc alt="">
    <a>{{label}}</a>
  </a>
</template>

<script>
/**
 * how to show params  in vue
 * @param {label: String}
 */


export default {
  name: "DropDownOption",
  props:{
    label: String,
    iconSrc: String,
    clickCallback: Function
  }
}


</script>

<style scoped>

</style>
