<template>
  <div class="main-content-container">
    <h1>{{ $t("nav.settings") }}</h1>
    <div>
      <TabNav :selected="selected" :tabs=this.navigationTabs @selected="setSelected" >
        <Tab :is-selected="selected === 'Account'">
          <AccountSettings/>
        </Tab>
        <Tab v-if="isAdmin" :is-selected="selected === this.$t('overview')">
          <AccountDashboard />
        </Tab>
      </TabNav>
    </div>
  </div>
</template>


<style scoped>

</style>

<script>

import TabNav from "../components/tabs/TabNav";
import Tab from "../components/tabs/Tab";
import AccountDashboard from "@/components/user/account/AccountDashboard";
import AccountSettings from "@/components/user/account/AccountSettings";

export default {
  components: {AccountSettings, AccountDashboard, Tab, TabNav},
  data() {
    return{
      selected: 'Account',
      isAdmin: false
    }
  },

  computed:{

    role(){
      return this.$store.getters.role;
    },

    navigationTabs(){
      if (this.isAdmin){
        return ['Account',this.$t('overview')]
      } else {
        return ['Account']
      }
    }
  },

  methods: {
    setSelected(tab){
      this.selected = tab;
    }
  },

  mounted() {
     if(this.role) if (this.role.toLowerCase() === "admin") this.isAdmin = true;
  }

}
</script>
