<template>
  <div>
    <div class="container-bg" v-if="courseTemplate !== null">
      <div v-if="courseTemplate.type === 'text'">
        <RichtextComponent :template="courseTemplate"></RichtextComponent>
      </div>

      <div v-if="courseTemplate.type === 'file'">
        <FileEditor :moduleId="this.moduleId" :courseId ="this.courseId" :step-object="courseTemplate" @step-description-changed="stepDescriptionChanged"
                     @step-title-changed="stepTitleChanged" @editor-changed="stepTextContentChanged"></FileEditor>
      </div>

      <div v-if="courseTemplate.type === 'userCertificate'">
        <UserCertificateUpload :moduleId="this.moduleId" :courseId ="this.courseId" :step-object="courseTemplate" @step-description-changed="stepDescriptionChanged"
                    @step-title-changed="stepTitleChanged" @editor-changed="stepTextContentChanged"></UserCertificateUpload>
      </div>

      <div v-if="courseTemplate.type  === 'video'">
        <VideoEditor :moduleId="this.moduleId" :courseId ="this.courseId" :step-object="courseTemplate" @step-description-changed="stepDescriptionChanged"
                     @step-title-changed="stepTitleChanged" @editor-changed="stepTextContentChanged" >
        </VideoEditor>
      </div>

      <div v-if="courseTemplate.type  === 'image'">
        <ImageEditor :moduleId="this.moduleId" :courseId ="this.courseId" :step-object="courseTemplate" @step-description-changed="stepDescriptionChanged"
                     @step-title-changed="stepTitleChanged" @editor-changed="stepTextContentChanged"></ImageEditor>
      </div>

      <div v-if="courseTemplate.type  === 'question'">
        <question-editor @max-attempts-changed="maxAttemptsChanged" :step-object="courseTemplate" @step-description-changed="stepDescriptionChanged" @step-title-changed="stepTitleChanged"
                         @editor-changed="stepTextContentChanged"></question-editor>
      </div>

      <div v-if="courseTemplate.type === 'imageMarker'">
        <ImageMarker :moduleId="this.moduleId" :courseId ="this.courseId" :step-object="courseTemplate" @step-description-changed="stepDescriptionChanged"
                     @step-title-changed="stepTitleChanged" @max-attempts-changed="maxAttemptsChanged" @editor-changed="stepTextContentChanged"></ImageMarker>
      </div>


      <div v-if="courseTemplate.type  === 'pdf'">
        <PDFEditor :moduleId="this.moduleId" :courseId ="this.courseId" :step-object="courseTemplate" @step-description-changed="stepDescriptionChanged"
                     @step-title-changed="stepTitleChanged" @editor-changed="stepTextContentChanged"></PDFEditor>
      </div>

      <div v-if="courseTemplate.type === 'audio'">
        <AudioEditor :module-id="this.moduleId" :course-id="this.courseId" :step-object="courseTemplate" @step-description-changed="stepDescriptionChanged"
                      @step-title-changed="stepTitleChanged"></AudioEditor>
      </div>

    </div>

  </div>
</template>
<script>

import RichtextComponent from "@/components/text-editor/RichtextComponent";
import QuestionEditor from "../../question-editor/QuestionEditor";
import ImageEditor from "../../image-editor/ImageEditor";
import VideoEditor from "../../video-editor/VideoEditor";
import FileEditor from "@/components/file-editor/FileEditor";
import PDFEditor from "@/components/pdf-editor/PdfEditor";
import ImageMarker from "@/components/image-marker/ImageMarker";
import UserCertificateUpload from "@/components/userCertificate/UserCertificateUpload";
import AudioEditor from "@/components/audio-editor/AudioEditor";

export default {
  name: 'EditTemplate',
  components: {
    AudioEditor,
    ImageMarker,
    PDFEditor,
    QuestionEditor,
    ImageEditor,
    VideoEditor,
    RichtextComponent,
    FileEditor,
    UserCertificateUpload
  },
  props:{
    template:{},
    moduleId:String,
    courseId: String,
  },
  data(){
    return{
      courseTemplate: this.template
    }
  },
  methods:{
    openModal(){
      this.modalIsOpen = !this.modalIsOpen;
    },

    cancelForm(){
      this.$emit("close-form");
    },

    stepTitleChanged(event){
      this.courseTemplate.title = event.target.value;
    },

    stepDescriptionChanged(event){
      this.courseTemplate.description = event.target.value;
    },

    maxAttemptsChanged(maxAttempts){
      this.courseTemplate.editorData.maxAttempts = maxAttempts;
    },

    stepTextContentChanged(data){
      this.courseTemplate.editorData = data;
    },

  },

  watch:{
    template(){
      this.courseTemplate = this.template;
    }
  }

}
</script>
<style scoped>

.container-bg{
  padding: 40px;
  background-color: var(--brightgrey);
  border-radius: 4px;
  margin-top: 24px;
}

@media screen and (max-width: 600px) {

  .container-bg{
    padding: 0;
    background-color: transparent;
  }
}

</style>
