<template>
  <div class="form-wrapper-file">
    <div>
      <label>{{ $t('upload') }}</label>
      <label class="custom-file-upload" style="display: block" :for="label">
        <span style="display: flex; align-items: center;">
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#b4b8be"><path d="M0 0h24v24H0z" fill="none"/><path d="M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z"/></svg>
          <span>{{ label }}</span>
        </span>
      </label>
      <div class="hide">
        <input @change="this.inputChanged" type="file" :placeholder="label" :name="label" :id="label">
      </div>
    </div>
    <div class="image-preview" v-if="imageData.length > 0">
      <img class="preview" :src="imageData">
    </div>
  </div>
</template>

<script>
export default {
  name: 'FileInput',
  data(){
    return{
      imageData: ""  // we will store base64 format of image in this string,
    }
  },
  props: {
    label: String,
  },
  methods:{
    inputChanged(event){
      this.$emit('inputChanged', event); // trigger event on the current instance

      let input = event.target;
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        // create a new FileReader to read this image and convert to base64 format
        let reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          this.imageData = e.target.result;
        }
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0]);
      }
    }
  }
}
</script>

<style scoped>

.hide{
  display: none;
}

.custom-file-upload{
  border: 1px solid #d1d5db;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  color: #b4b8be;
  font-size: 1rem;
  background-color: var(--bright);
}

.custom-file-upload:hover svg{
 fill: var(--primary-color);
}

.custom-file-upload:hover{
  border-color: var(--primary-color);
  color: var(--primary-color);
}

.form-wrapper-file{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.form-wrapper-file:nth-child(odd){
  margin-right: 40px;
}

label{
  font-weight: 300;
}

.image-preview {
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  margin-left: 40px;
}
img.preview {
  width: 100px;
  background-color: white;
  border: 1px solid #DDD;
  padding: 5px;
}

@media screen and (max-width: 600px) {
    .form-wrapper-file{
      display: block;
  }

  .image-preview{
    margin: 0;
  }
}



</style>
