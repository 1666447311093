import Uuidv4Generator from "@/mixins/uuid/Uuidv4Generator";
import {Auth} from "aws-amplify";
import generatePassword from "@/mixins/uuid/generatePassword";

const state = {
    myUsers: [],
    password:''
}

const getters = {
    myUsers: (state) => state.myUsers,
    password: (state) => state.password,
}

const actions = {

    // Getting users and committing a mutation with the data from the getRequest
    getMyUsers(context) {
        context.dispatch("getRequest", "getMyUsers").then(res => {
            const data = JSON.parse(res.body);
            context.commit("setMyUsers", data);
        })
    },


    // Updates the current person or adds a new person.
    updatePerson(context, payload) {
        let duplicateEmail = false;
        // Checking if the person is new
        if (payload.isNewPerson) {
            for (let person of context.state.myUsers) {
                // Looking if the email is already registered. If yes, we don't allow the user to be registered.
                if (person.email.toLowerCase() === payload.person.email.toLowerCase()) {
                    duplicateEmail = true;
                }
            }
             //If the email isn't registered we add the person into our state and dispatch signUpUser.
            if (!duplicateEmail) {
                context.commit("addPerson", payload.person);
                context.dispatch("signUpUser", payload.person);
            }
            duplicateEmail = false;
        // The person isn't new
        } else {
            for (let person of context.state.myUsers) {
                if (person.id === payload.person.id) {
                    // We update the person with the payload
                    const index = context.state.myUsers.indexOf(person);
                    context.state.myUsers[index] = payload.person;
                    context.state.myUsers[index].courses = person.courses;
                    context.state.myUsers[index].events = person.events;
                    // Checking if the person has events/courses and if they don't exist we add an empty array.
                    if (person.events === undefined) person.events = [];
                    if (person.courses === undefined) person.courses = [];
                    context.dispatch("postRequest", {
                        "param": "updateUser",
                        "payload": payload.person
                    }).then(res => {
                        console.log(res);
                    })
                }
            }
        }

    },

    // Signing up the user into our DB.
    signUpUser(context, payload) {
        const domain = payload.domain;
        const role = payload.role;
        const friendlyName = payload.friendlyName;
        const group = payload.group;
        const code = payload.code;

        let password

        password = code ? generatePassword.methods.password(8) : Uuidv4Generator.methods.createUuidv4() + "A1#";

        context.commit("setPassword",password)
            try {
                Auth.signUp({
                    "username": payload.id,
                    "password":password,
                    attributes: {
                        "email":code ?  payload.id + "@learningonetimeuser.gl" : payload.email,
                        "custom:domain": domain,
                        "custom:role": role,
                        "custom:friendlyName": friendlyName,
                        "custom:group": group,
                    }
                }).then((err)=>{
                    console.log(err);
                    context.dispatch("postRequest", {
                        "param": "signUpUser",
                        "payload": payload
                    })
                });
            } catch (error) {
                alert('error signing up: ' + error.message);
            }
    },

    // Deleting the user and committing a mutation to remove the user from our state.
    deleteUser(context, payload) {
        const payloadId = {"userId": payload.id};
        context.dispatch("postRequest", {
            "param": "deleteUser",
            "payload": payloadId
        }).then(() => {
            context.commit("deleteUser", payload);
        })
    }
}

const mutations = {

    emptyPerson(state){
        state.myUsers = [];
    },

    // Sets all users from our getRequest into our state.
    setMyUsers(state, users) {
        for (let user of users) {
            state.myUsers.push(user);
        }
    },

    // Adds a new person into our state.
    addPerson(state, payload) {
        state.myUsers.unshift({
            "id": payload.id,
            "friendlyName": payload.friendlyName,
            "email": payload.email,
            "role": payload.role,
            "group": payload.group,
            "persNum": payload.persNum,
            "code":payload.code
        });
    },


    // Deletes the user from our state
    deleteUser(state, payload) {
        let index = 0;
        for (let user of state.myUsers) {
            if (user.id === payload.id) {
                state.myUsers.splice(index, 1);
            }
            index++;
        }
    },

    setPassword(state,payload){
        state.password = payload;
    }

}

const personStore = {
    state,
    getters,
    actions,
    mutations,
}

export default personStore;