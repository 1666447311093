<template>
  <div class="rating-container">
    <div>
      <toggle-btn :default-state="hasRating" @toggle-changed="hasRatingToggleChanged" id="1" :label-disable-text="$t('courseHasRating')"
                  :label-enable-text="$t('courseHasRating')">
      </toggle-btn>
    </div>
    <div v-if="hasRating">
      <div style="margin-top: 20px">
        <span>{{$t('successfulAttendanceQ')}}</span>
      </div>
        <VueMultiselect
            v-model="ratingPercentageData"
            mode="single"
            :options="percentageOptions"
            @select="ratingPercentageChanged"
        />
    </div>
  </div>
  <div>
    <toggle-btn :default-state="hasCertificate" @toggle-changed="hasCertificateToggleChanged" id="2" :label-disable-text="$t('courseHasCertificate')"
                :label-enable-text="$t('courseHasCertificate')">
    </toggle-btn>
  </div>
  <div class="rating-container">
    <AppInput :value="this.maxAttemptsData"
              @input-changed="maxAttemptsChanged" type="number" :label="$t('maxAttempts')"
              :placeholder="this.$t('maxAttempts')"></AppInput>
  </div>
</template>

<script>
import ToggleBtn from "@/components/toggle/ToggleBtn";
import AppInput from "@/components/form/inputs/AppInput";
import VueMultiselect from "vue-multiselect";

export default {
name: "CourseRating.vue",
  components: {AppInput, ToggleBtn, VueMultiselect},

  props:{
     hasRating: Boolean,
     hasCertificate: Boolean,
     ratingPercentage:Number,
     maxAttempts:Number
  },

  data(){
    return{
      ratingPercentageData: this.ratingPercentage? this.ratingPercentage: 0,
      percentageOptions: [10,20,30,40,50,60,70,80,90,100],
      maxAttemptsData: this.maxAttempts ? this.maxAttempts : "",
    }
  },

  methods:{
    hasRatingToggleChanged(state){
      this.$emit("rating-toggle-changed", state);
    },
    hasCertificateToggleChanged(state){
      this.$emit("certificate-toggle-changed",state);
    },

    ratingPercentageChanged(percentage){
      this.$emit("rating-percentage-changed", percentage);
    },
    maxAttemptsChanged(event){
      this.maxAttemptsData = event.target.value;
      this.$emit("max-attempts-changed",this.maxAttemptsData);
    }

  }
}

</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style scoped>

.rating-container{
  margin-bottom: 24px;
  margin-top: 24px;
}


</style>