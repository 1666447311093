import {createApp} from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from './i18n'
import vClickOutside from 'click-outside-vue3'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import Amplify from 'aws-amplify';
import awsGleasonCH from "./aws-exports-gleason-ch";
import awsmobile from "@/aws-exports";

const app = createApp(App);

if(localStorage.getItem("company") === "Gleason GSAG"){
    Amplify.configure(awsGleasonCH);
}else{
    Amplify.configure(awsmobile);
}

app.use(VueSweetalert2).use(i18n).use(store).use(router).use(vClickOutside).mount('#app');