<template>
  <div class="form-container">
    <div>
      <div>
        <div style="position: relative; width: 100%; margin-right: 20px;">
          <AppInput :hasError="v$.groupName.$error" :value="this.groupName"
                    @input-changed="groupChanged" type="text" :label="$t('form.group')"
                    :placeholder="this.$t('placeholderGroupName')"/>
        </div>
        <div style="position: relative; width: 100%;">
          <AppInput :hasError="v$.groupDescription.$error" :value="this.groupDescription" @input-changed="descriptionChanged" type="text" :label="$t('form.description' )" :placeholder="this.$t('placeholderDescription')"/>
        </div>
      </div>
      <div class="select-wrapper form-wrapper">
        <div style="position: relative; width: 100%;">
          <label>{{ $t('responsible') }}</label>
           <VueMultiselect
              mode="single"
              :placeholder="this.$t('placeholderResponsible')"
              :options="personList"
              v-model="this.responsible"
              @select="personChanged"
          />
        </div>
      </div>
      <div style="position: relative; width: 100%;">
        <AppInput :hasError="v$.groupCompany.$error" :value="this.groupCompany" @input-changed="companyChanged"
                  type="text" :label="$t('form.company') + ' (optional)'" :placeholder="this.$t('placeholderCompany')"/>
      </div>
      <div style="position: relative; width: 100%;">
        <AppInput :hasError="v$.companyAddress.$error" :value="this.companyAddress" @input-changed="addressChanged"
                  type="text" :label="$t('address') + ' (optional)'" :placeholder="this.$t('placeholderCompany')"/>
      </div>
      <div style="margin-top: 24px; display: flex; flex-direction: row;">
        <label style="margin-right: 24px;">{{ $t('groupExtern') }}</label>
        <toggle-btn :default-state="group.external" @toggle-changed="externalStatusChanged" :label-disable-text="$t('no')" :label-enable-text="$t('yes')"></toggle-btn>
      </div>
      <div class="form-btm-wrapper primary-btn-wrapper">
        <primary-btn @click="updateGroup" type="submit" :val="$t('confirm')"></primary-btn>
      </div>
    </div>
  </div>
</template>
<script>

import AppInput from "../../form/inputs/AppInput";
import PrimaryBtn from "../../buttons/PrimaryBtn";
import VueMultiselect from 'vue-multiselect'
import ToggleBtn from "../../toggle/ToggleBtn";
import Uuidv4Generator from "../../../mixins/uuid/Uuidv4Generator";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";

export default {
  name: 'AddGroupForm',
  mixins: [Uuidv4Generator],
  emits:['group-updated'],
  components: {
    AppInput,
    PrimaryBtn,
    VueMultiselect,
    ToggleBtn
  },

  props:{
    isNewGroup: Boolean,
    group: {
      type: Object,
    },
  },

  setup(){
    return{
      v$: useVuelidate()
    }
  },

  validations(){
    return{
      groupName: {required},
      groupDescription:{required},
      groupCompany:{},
      responsible:{required},
      companyAddress:{},
    }
  },

  data(){
    return {
      groupName: this.group.name ? this.group.name : "",
      groupDescription: this.group.description ? this.group.description: "",
      groupCompany: this.group.company ? this.group.company: "",
      responsible: this.group.responsible ? this.group.responsible: "",
      isGroupExternal: this.group.external? this.group.external: false,
      companyAddress: this.group.companyAddress? this.group.companyAddress : "",
      personList: [],
    }
  },

  methods:{
    cancelForm(){
      this.$emit("close-form");
    },

    emitGroupUpdatedEvent(groupData){
      this.$emit("group-updated",groupData);
    },

    groupChanged(event){
      this.groupName = event.target.value;
    },

    descriptionChanged(event){
      this.groupDescription = event.target.value;
    },

    companyChanged(event){
      this.groupCompany = event.target.value;
    },

    addressChanged(event){
      this.companyAddress = event.target.value;
    },

    personChanged(responsible){
      this.responsible = responsible;
    },

    externalStatusChanged(trueOrFalse){
        this.isGroupExternal = trueOrFalse;
    },

    updateGroup(){
      this.v$.$validate();
      if (!this.v$.$error){
        const groupData={
          "name": this.groupName,
          "description":this.groupDescription,
          "company":this.groupCompany,
          "companyAddress": this.companyAddress,
          "responsible": this.responsible,
          "id":this.isNewGroup ? this.createUuidv4() : this.group.id,
          "external":this.isGroupExternal,
        }
      this.$store.dispatch("postRequest",{
        "param":"updateGroup",
        "payload":groupData
      }).then(()=>{
        // now we can inform our parent and then close the form.
        this.emitGroupUpdatedEvent(groupData);
        this.cancelForm();
      });
      }
    },
  },
  mounted() {
    this.$store.dispatch("getRequest","getUsers").then(res => {
      const personObjs = JSON.parse(res.body);

      // Mapping personList, so we only see the name or the user instead of all information.
      for (let person of personObjs) {
        if (person.role === "admin_light" || person.role === "admin"){
          this.personList.push(person.friendlyName)
        }
      }
    })
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style scoped>

.select-wrapper{
  display: flex;
}

.form-container{
  background-color: var(--brightgrey);
  border-right: 4px;
  padding: 40px;
  border-radius: 4px;
  margin-bottom: 24px;
}

.primary-btn-wrapper{
  position: fixed;
  right: 64px;
  bottom: 64px;
}

.form-btm-wrapper{
  display: flex;
  justify-content: right;
}

label{
  font-weight: 300;
}

input:focus, select:focus, textarea:focus-visible, textarea:focus {
  outline: none;
  border: 1px solid var(--primary-color);
  border-radius: 4px;
}

input, select, textarea{
  height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
  border: 1px solid var(--brightgrey);
  background-color: var(--bright);
}

input:focus + label,
select:focus + label,
textarea:focus + label {
  color: var(--primary-color);
}

@media screen and (max-width: 600px) {

  .primary-btn-wrapper{
    right: 40px;
    bottom: 40px;
  }
  .select-wrapper{
    display: block;
  }

  .select-wrapper > div{
    margin-bottom: 24px;
  }
  .form-container{
    padding: 0;
    background-color: transparent;
  }
}
</style>
