<template>
  <div>
    <h3 style="margin-bottom: 10px;">PDF-Template</h3>
    <AppInput :has-error="titleIsEmpty" :value="stepObject.title" @input-changed="emitTitleChangedEvent" type="text" :label="this.$t('title')" :placeholder="this.$t('placeholderTitle')"></AppInput>
    <AppInput :value="stepObject.description" @input-changed="emitDescriptionChangedEvent" type="text" :label="this.$t('form.description')" :placeholder="this.$t('placeholderDescription')"></AppInput>

    <div class="form-wrapper-file">
      <label>{{ $t('uploadPDF') }}.</label>
      <input accept="application/pdf" type="file" @change="pdfFileUploaded">
    </div>

    <object
        :data='pdfSrc + "#toolbar=1&navpanes=1&scrollbar=0"'
        type="application/pdf"
        width="100%"
        height="678"
    >

      <iframe
          :src='pdfSrc + "#toolbar=1&navpanes=1&scrollbar=0"'
          width="500"
          height="678"
      >
        <p>This browser does not support PDF!</p>
      </iframe>

    </object>
    <Loader :show="this.showLoader"></Loader>
  </div>

</template>

<script>

import AppInput from "../form/inputs/AppInput";
import {S3FileManager} from "@/mixins/s3-file-manager/s3-file-manager";
import Loader from "../loader/Loader";

export default {
  name: "PDFEditor",
  components: {AppInput, Loader},

  props:{
    stepObject: Object,
    moduleId: String,
    courseId: String
  },

  data(){
    return{
      pdfSrc : '',
      s3Directory: '',
      showLoader: false,
      titleIsEmpty: false,
    }
  },

  methods:{
    emitTitleChangedEvent(title){
      if(title.target.value === undefined || title.target.value === '' || title.target.value === null){
        this.titleIsEmpty = true;
      }else{
        this.titleIsEmpty = false;
        this.$emit("step-title-changed", title);
      }
    },

    emitDescriptionChangedEvent(description){
      this.$emit("step-description-changed", description);
    },

    checkURLForValidImage(prefix) {
      return new Promise((resolve =>{
        const s3FileManager = new S3FileManager();
        s3FileManager.checkIfListExists(prefix).then(res => {
          resolve(res.length > 0);
        })
      }))
    },

    setDefaultImage(){
      this.pdfSrc = "";
    },

    setInstructionImage(){
      const s3FileManager = new S3FileManager();
      s3FileManager.getSignedURL("course/" + this.courseId + "/" + this.moduleId + "/" + this.stepObject.id + "/template.pdf").then(url=>{
        this.pdfSrc = url;
      })
    },

    pdfFileUploaded(event){
      const file = event.target.files[0];
      const path = "course/" + this.courseId + "/" + this.moduleId + "/" + this.stepObject.id + "/template.pdf";
      this.pdfSrc = URL.createObjectURL(file);
      const s3FileManager = new S3FileManager();
      s3FileManager.uploadFile(file, path);
    },
  },

  mounted() {
    // this is our s3 directory where all image templates - if any - are stored.
    this.s3Directory = "course/" + this.courseId + "/" + this.moduleId + "/" + this.stepObject.id;
    this.showLoader = true;
    this.checkURLForValidImage(this.s3Directory).then(fileExists => {
      this.showLoader = false;
      if (fileExists){
        this.setInstructionImage();
      } else {
        this.setDefaultImage();
      }
    })
  }
}
</script>

<style scoped>
</style>
