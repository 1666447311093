<template>
  <div class="main-content-container">
    <div class="path-container">
      <router-link to="/">News</router-link> >
      <router-link to="">{{ this.post.title }} ({{this.post.id}})</router-link>
    </div>
    <div class="blog-container">
      <div class="top-article-wrapper">
        <div class="blogHeader-info">
          <span>{{ creationDate }}</span>
          <span class="dot"></span>
          <span>{{this.post.creator}}</span>
        </div>

        <div v-if="this.hasPromotedCourse()" class="btn-wrapper-left coursesList">
          <a class="course-link" @click="this.goToCourse(this.post.promotedCourse)">{{ $t('goToCourse') }}</a>
        </div>
      </div>
      <div class="headline-container">
        <div class="left-wrapper">
          <h2 class="title">{{this.post.title}}</h2>
          <p class="description">{{this.post.description}}</p>
        </div>
        <div>
          <img class="title-img" alt="title image" :src="this.iconSrc">
        </div>
      </div>
      <div class="line"></div>
      <div class="content-container">
        {{this.post.content}}
      </div>
    </div>
    <router-link to="/">
      <svg class="arrow-icon" xmlns="http://www.w3.org/2000/svg" height="34px" viewBox="0 0 24 24" width="34px" fill="#007AC2"><path d="M0 0h24v24H0z" fill="none"/><path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"/></svg>
    </router-link>
  </div>
  <Loader :show="showLoader" ></Loader>
  </template>


<script>
import {S3FileManager} from "@/mixins/s3-file-manager/s3-file-manager";
import Loader from "@/components/loader/Loader";

export default {
  components: {Loader},

  name: "SingleviewArticle",

  data(){
    return {
      post: {},
      iconSrc: "",
      showLoader: false,
    }
  },

  computed:{
      creationDate(){
        return new Date(parseInt(this.post.creationDateUnix)).toLocaleString();
      }
  },

  methods:{

    /**
     * Assigning a promoted course to a post is optional. Therefore we have to check
     * if a promoted course exists, before we show the goToCourse link.
     * @return {boolean}
     */
    hasPromotedCourse(){
      if (this.post.promotedCourse){
        return this.post.promotedCourse !== "";
      }
      return false;
    },

    getTitleImgSrc(postId){
      return new Promise((resolve)=>{
        const s3FileManager = new S3FileManager();
        const prefix = "news/" + postId;
        s3FileManager.checkIfListExists(prefix).then(res => {
          if (res.length > 0){
            s3FileManager.getSignedURL(prefix + "/image.jpeg").then(url=>{
              resolve(url);
            })
          } else {
            resolve("https://kashmirobserver.net/wp-content/uploads/2020/11/Online-News.jpg");
          }
        })
      })
    },

    goToCourse(selectedCourse){
      this.$router.push({
        path:'/library/course/',
        name: 'Course Id',
        params: {"id":selectedCourse}
      });
    },
  },

  mounted() {
    const postId = this.$router.currentRoute.value.params.id;
    this.showLoader = true;
    this.$store.dispatch("getRequest","getPostById&query=" +  postId).then(resp => {
      this.showLoader = false;
      const data = JSON.parse(resp.body);
      this.post = data.post;
    });

    this.getTitleImgSrc(postId).then((src)=>{
      this.iconSrc = src;
    })
  },
}
</script>

<style scoped>

.course-link{
  color: var(--primary-color);
}

.course-link:hover{
  text-decoration: underline;
}

.arrow-icon{
  margin-top: 24px;
}

.arrow-icon:hover{
  cursor: pointer;
}

.title-img{
  width: 100%;
  max-width: 500px;
  border: 1px solid var(--brightgrey);
}

.left-wrapper{
  margin-right: 24px;
}

.btn-wrapper-left{
  max-width: 300px;
}

.path-container{
  margin-bottom: 24px;
  font-size: 12px;
}

.blog-container{
  padding: 40px;
  border: 1px solid var(--brightgrey);
  border-radius: 4px;
}

.dot {
  height: 5px;
  width: 5px;
  background-color: #999999;
  border-radius: 50%;
  display: inline-block;
}

.blogHeader-info{
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.blogHeader-info span{
  margin-right: 5px;
  color: #999999;
}

.title{
  margin-bottom: 20px;
}

.headline-container{
  padding-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.line {
  width: 200px;
  background-color: var(--primary-color);
  height: 2px;
  margin-bottom: 40px;
}

.content-container{
  line-height: 2;
}

.top-article-wrapper{
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 800px) {

  .title-img{
    max-width: 100%;
    height: 220px;
  }
  .blog-container{
    padding: 24px;
  }
  .headline-container{
    display: block;
  }
  .headline-container img{
    margin-top: 40px;
    width: 100%;
    object-fit: cover;
  }
  .top-article-wrapper{
    flex-direction: column-reverse;
  }

  .blogHeader-info{
    margin-bottom: 10px;
  }

  .btn-wrapper-left{
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 600px) {
  .blog-container{
    padding: 0;
    border: none;
  }
  .top-article-wrapper{
    display: block;
  }
  .blogHeader-info{
    margin-bottom: 24px;
  }

  .coursesList{
    margin-bottom: 24px;
  }
}

</style>
