import { ref, computed } from 'vue'

export const collapsed = ref(true)
export const toggleSidebar = () => (collapsed.value = !collapsed.value)

export const SIDEBAR_WIDTH_MOBILE = 50
export const SIDEBAR_WIDTH_DESKTOP = 180

export const sidebarWidth = computed(
    () => `${collapsed.value ? SIDEBAR_WIDTH_MOBILE : SIDEBAR_WIDTH_DESKTOP}px`
)