import {LitElement, html} from "lit";
import {UploadIndicatorStyles} from "./upload-indicator-styles";


export class UploadIndicator extends LitElement{
    constructor() {
        super();

        this.uploadItems = [];
    }

    static get styles(){
        return UploadIndicatorStyles;
    }

    static get properties(){
        return{
            uploadItems:{type:[]}
        }
    }

    hideDownload(key){
        for (let item of this.uploadItems) {
            if (item.key === key) {
                this.uploadItems.splice(this.uploadItems.indexOf(item), 1);
            }
        }
        let buffer = this.uploadItems;
        this.uploadItems = [];
        this.uploadItems = buffer;
    }

    render(){
        return html`
            <div class="rootDiv"> 
                ${this.uploadItems.map(item => html`
                    <div class="outerDiv"> 
                      <img @click="${()=>this.hideDownload(item.key)}" src="" alt="">
                        <div style="width: ${item.progress + "%"}" class="progressIndicator">
                             <span>${item.key + " " + item.progress + "%"}</span>
                        </div>
                    </div>
                `)}
            </div>
        `
    }
}

customElements.define("upload-indicator",UploadIndicator);
