<template>
  <img :src="this.imgBackground" style="z-index: -1;width:100%;height:75vh; object-fit: cover"  alt=""/>
  <div class="login-container">
    <div class="container-logo">
      <img alt="company logo" :src="this.imgLink">
    </div>
    <h1>{{ $t('login') }}</h1>
    <p>{{ $t('pleaseEnterYourAccessDataToContinue') }}</p>

    <AppInput @input-changed="usernameChanged" label="Code" :placeholder="this.$t('codePlaceholder')"></AppInput>
    <AppInput @keyup.enter.exact="login" @input-changed="passwordChanged" :label="$t('password')" type="password"
              :placeholder="this.$t('placeholderEnterPassword')"></AppInput>

    <div class="login-wrapper">
      <primary-btn @click="login" :val="$t('login')"></primary-btn>
    </div>
  </div>
</template>

<script>

import AppInput from "@/components/form/inputs/AppInput";
import PrimaryBtn from "@/components/buttons/PrimaryBtn";
import {Config} from "@/config";

export default {
  name: "LoginWithUserID",
  components:{AppInput,PrimaryBtn},
  data(){
    return{
      username:'',
      password:'',
      imgLink: Config.Logo.link,
      imgBackground: Config.BackgroundImage.link
    }
  },
  computed:{
    company(){
      return this.$store.getters.company;
    }
  },
  methods:{
    login(){
      this.$emit('emit-login',{"username":this.username,"password":this.password});
      this.$emit('close-form');
    },
    usernameChanged(event){
      this.username = event.target.value;
    },
    passwordChanged(event){
      this.password = event.target.value;
    }
  },

}
</script>

<style scoped>

h1{
  margin-top: 40px;
  margin-bottom: 24px;
}

a{
  text-decoration: none;
  color: var(--primary-color);
}

a:hover {
  text-decoration: underline;
}

.login-container {
  width: 400px;
  border: 1px solid var(--brightgrey);
  padding: 60px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color:white;
  border-radius:10px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  box-shadow: lightgrey 0 0 0 0, lightgrey 0 2px 4px;
}

.login-wrapper{
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
}

.login-container p {
  margin-bottom: 24px;
}

.container-logo{
  display: flex;
  justify-content: start;
}


@media screen and (max-width: 600px) {
  .login-container {
    width:auto!important;
  }
}


</style>