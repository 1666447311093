<template>
  <div>
    <button class="dropdown-btn" @click="open = !open">
      <svg :class="{'active-blue' : open }" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#555"><path d="M0 0h24v24H0z" fill="none"/><path d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/></svg>
    </button>
    <!-- v-click-outside is a in-build property of 'click-outside-vue3', which gets imported inside the main.js -->
    <div v-if="open" class="flex dropdown-list" v-click-outside="onClickOutside">
      <div class="dropdown-option" v-for="item in dropdownCardList" :key="item" >
        <DropDownOption :click-callback="item.clickCallback" :icon-src="item.iconSrc" :label="item.label"></DropDownOption>
      </div>
  </div>
  </div>
</template>

<script>

import DropDownOption from "./DropDownOption";
export default {
  name: 'AppDropdown',
  components: {DropDownOption},
  props: {
    dropdownCardList: Array,
  },
  data(){
    return{
      /**
       * comment
       * @type Boolean
       */
      open: false,
    }
  },
  methods: {
    // If a click-event is happening outside the div this method is called,
    // then we change the Boolean open to false;
    onClickOutside() {
     this.open = !this.open;
    }
  }
}
</script>

<style>
  .dropdown-btn{
    background-color: var(--bright);
    border: none;
    display: flex;
    float: right;
    box-shadow: 0 0 5px rgb(169 191 203);
    z-index: 1;
    border-radius: 4px;
  }

  .dropdown-btn:hover svg{
    fill: var(--primary-color);
  }

  .dropdown-list{
    background-color: var(--bright);
    box-shadow: 0 0 5px rgb(169 191 203);
    border-radius: 4px;
    z-index: 10000;
    position: relative;
    top: 28px;
  }

  .active-blue{
    fill: var(--primary-color);
  }

  .dropdown-option {
    border-bottom: 1px solid var(--brightgrey);
    padding: 8px 12px;
  }

  .dropdown-option:hover{
    box-shadow: 0 0 5px rgb(169 191 203);
  }

  .dropdown-option a{
    display: flex;
    align-items: center;
  }

  .dropdown-option img{
    margin-right: 12px;
  }

</style>
