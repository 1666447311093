<template>
  <Modal @close-form="this.closeForm" @confirm-form ="this.confirmForm"
         :modalIsOpen="this.showPopup" :showConfirmBtn = "true">
    <div>
      <h3>{{this.$t('courseGroupRecommendationHeader')}}</h3>
      <div class="span-container">
        <span>
          {{this.$t('courseGroupRecommendation')}}
        </span>
      </div>
    </div>
    <div>
      <VueMultiselect
          :multiple="true"
          v-model="this.selectedGroups"
          mode="tags"
          :placeholder="$t('pleaseSelect')"
          :options="this.groupOptions"
      />
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/modal/Modal";
import VueMultiselect from "vue-multiselect";
import SweetAlerts from "../../mixins/sweet-alerts/SweetAlerts";

export default {
  name: "CourseRecommendation",
  components: {Modal, VueMultiselect},
  mixins: [SweetAlerts],
  props:{
    showPopup: Boolean,
    courseTitle: String,
    courseId: String
  },
  methods:{
    closeForm(){
      this.$emit("close-popup");
    },
    confirmForm(){
      if (this.selectedGroups.length > 0){
        this.showSuccessMessage(this.$t("notificationSuccess"));
        this.$emit("close-popup");

        this.postGroupNotifications();

      } else {
        this.showErrorMessage(this.$t("selectAtLeastOneGroup"));
      }
    },

    postGroupNotifications(){
      this.$store.dispatch("postRequest",{
        "param":"createGroupNotifications",
        "payload":{
          groups:this.selectedGroups,
          courseTitle: this.courseTitle,
          courseId: this.courseId
        }
      }).then(()=>{
        this.selectedGroups = [];
      });
    }
  },
  data(){
    return {
      groupOptions:[],
      selectedGroups:[]
    }
  },

  mounted() {
    this.$store.dispatch("getRequest","getGroups").then(resp => {
      const data = JSON.parse(resp.body);

      // if we do not use a buffer, somehow the options in
      // multiselect are not loaded correctly.
      let buffer = [];
      for (let item of data) {
        buffer.push(item.group.name);
      }

      buffer.sort();

      this.groupOptions = buffer;
    })
  }
}

</script>

<style scoped>

.span-container{
  margin-bottom: 15px;
  margin-top: 25px;
}

</style>