<template>
  <div class="main-content-container">
    <div v-if="this.getDomainName.includes('gleason')">
      <h1 style="margin-bottom: 24px;">"Gleason E-Learning" Plattform</h1>
      <h2 style="margin-bottom: 24px;">Nutzungsbedingungen "Gleason E-Learning" Plattform</h2>
      <ol>
        <li>1. E-Learning Plattform 1.1 Die Gleason-Pfauter Maschinenfabrik GmbH (nachfolgend "Gleason") stellt Mitarbeitern, Vertriebspartnern, Kunden und anderen Mitgliedern innerhalb des Gleason Firmenverbunds (nachfolgend "Nutzer") eine digitale Lernplattform zur Verfügung. 1.2 Gleason ermöglicht dem Nutzer diese Plattform und die darin bereitgestellten Inhalte auf der Grundlage dieser Nutzungsbedingungen und der jeweils geltenden Gesetze für den Zeitraum des Betriebs zu nutzen. Bevor ein Nutzer Informationen über diese Plattform erhalten oder in sonstiger Weise nutzen kann, ist er verpflichtet, sein Einverständnis zu diesen Nutzungsbedingungen zu erklären. Der Zugang zu dieser Plattform ist kostenlos. Etwaige Zugangskosten (Internetverbindung etc.) und Kosten für kostenpflichtige E-Learnings trägt der Nutzer selbst. 1.3 Die E-Learning Plattform wird dem Nutzer unter dem Vorbehalt der Verfügbarkeit angeboten. Es besteht kein Anspruch auf die Nutzung der Plattform. Gleason ist bemüht, Störungen, insbesondere technischer Art, so gering wie möglich zu halten. Es wird jedoch keine Gewähr dafür übernommen, dass der Zugang oder die Nutzung dieser Plattform nicht durch Wartungsarbeiten, Weiterentwicklungen oder anderweitig durch Störungen unterbrochen oder beeinträchtigt wird, die ggf. auch zu Datenverlusten führen können. Gleason ist berechtigt den Betrieb die E-Learning Plattform ohne Angabe von Gründen vorübergehend oder dauerhaft einzustellen. Im Falle einer Dauerhaften Einstellung der Plattform, verpflichtet sich Gleason die Nutzer im Rahmen einer angemessenen Frist vorab über die Einstellung zu informieren.</li>
        <li>2. Teilnahme 2.1 Nutzer müssen sich für die Nutzung der E-Learning Plattform registrieren. Bei der Registrierung wird insbesondere die Teilnahmeberechtigung des Nutzers nach Ziffer 1.1 überprüft. Gleason behält sich vor, den Nutzerkreis für einzelne E-Learnings zu begrenzen. 2.2 Der Nutzer hat seine Zugangsdaten, bestehend aus Name und Passwort, vertraulich zu behandeln und sorgfältig aufzubewahren. Er hat dafür Sorge zu tragen, dass seine Zugangsdaten und das vom ihm angelegte Nutzerprofil nur von ihm verwendet und nicht durch Dritte missbräuchlich genutzt werden. Eine Weitergabe der Zugangsdaten an Dritte ist in jedem Fall untersagt. Im Falle eines Missbrauchs hat der Nutzer sein Passwort unverzüglich nach Kenntnis des Missbrauchs zu ändern und Gleason über den Missbrauch zu informieren. Gleason  übernimmt keine Haftung für Schäden, die sich aus der unberechtigten oder unsachgemäßen Verwendung der Zugangsdaten und der Plattform ergeben. 2.3 Gleason behält sich das Recht vor, den Zugang zur E-Learning Plattform auch ohne Angabe von Gründen zu verweigern oder den Nutzer von der weiteren Nutzung vorläufig oder dauerhaft auszuschließen, insbesondere wenn die Teilnahmeberechtigung endet oder erlischt oder ein Nutzer gegen diese Nutzungsbedingungen und/oder gesetzliche Vorschriften verstößt. Gleason behält sich das Recht vor, je nach Schwere des Verstoßes den Zugang zur E-Learning Plattform zu sperren und/oder zu löschen. 2.4 Der Nutzer ist berechtigt, das Nutzungsverhältnis zu kündigen. Die Kündigung ist per E-Mail an e-academy@gleason.com zu richten. Im Falle der Kündigung durch den Nutzer wird sein Zugang gesperrt. Die Sperrung des Zugangs erstreckt sich auch auf kostenpflichtige Inhalte.</li>
        <li>3. Inhalte 3.1 Auf der E-Learning Plattform kann der Nutzer sowohl kostenlose als auch kostenpflichtige E-Learnings abrufen. Der Nutzer ist nur berechtigt diese Inhalte im Zusammenhang mit seiner gewerblichen Tätigkeit für oder mit Gleason zu nutzen, soweit nicht für die einzelnen Inhalte etwas anderes auf der Plattform bestimmt ist. Eine Weitergabe an Dritte oder Veröffentlichung der Inhalte ist grundsätzlich nur zulässig, wenn dies ausdrücklich gestattet ist. 3.2 Die E-Learning Plattform ist in unterschiedliche Nutzergruppen und -bereiche aufgeteilt. Einzelne dieser Nutzergruppen und -bereiche richten sich an einen eingeschränkten Nutzerkreis. Der Zugriff des Nutzers auf einzelne der Nutzergruppen und -bereiche kann daher mangels Zugehörigkeit zum erforderlichen Nutzerkreis verwehrt sein. Nutzer erhalten nach erfolgreicher Registrierung Zugriff auf Inhalte, die Gleason in den jeweils zugänglichen Nutzergruppen und -bereichen der Plattform bereithalten. Es besteht kein Anspruch eines Nutzers auf einzelne Bereiche der Plattform zuzugreifen.</li>
        <li>4. Verhaltensregeln 4.1 Für einen erfolgreichen Austausch der Nutzer auf der E-Learning Plattform hat der Nutzer folgende Verhaltensregeln zu beachten: - Ziel der E-Learning Plattform ist es, sich auf dieser zu informieren und sich neues Wissen anzueignen. - Der Nutzer hat darauf zu achten, dass er andere Nutzer nicht belästigt, insbesondere nicht durch unerwünschte Kontaktaufnahme oder sonstige Störungen. - Der Nutzer verpflichtet sich, den ordnungsgemäßen Betrieb der Plattform nicht zu gefährden. Nutzer, die auf einen möglicherweise unzulässigen Inhalt stoßen, werden gebeten, diesen Gleason zu melden. Der Nutzer kann hierzu eine E-Mail an e-academy@gleason.com schicken. Gleason wird den beanstandeten Inhalt prüfen und ihn im Falle der Rechtswidrigkeit unverzüglich entfernen. 4.2 Bei Verstoß gegen diese Nutzungsbedingungen und die darin enthaltenen Verhaltensregeln ist Gleason unbeschadet seiner gesetzlichen Ansprüche insbesondere berechtigt: - den Nutzer auf seinen Verstoß hinzuweisen - den Zugang des Nutzers zu sperren - die Teilnahme des Nutzers zeitweise oder endgültig zu beenden. Gleason ist insbesondere berechtigt, Daten und sonstigen Inhalte ganz oder teilweise zu löschen, insbesondere wenn diese gegen diese Nutzungsbedingungen oder geltendes Recht verstoßen.</li>
        <li>5. Haftung 5.1 Die Haftung von Gleason für leicht fahrlässige Pflichtverletzungen ist ausgeschlossen, sofern nicht Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit oder Garantien betroffen oder Ansprüche nach dem Produkthaftungsgesetz berührt sind. Unberührt bleibt ferner die Haftung für die Verletzung von Pflichten, deren Erfüllung die ordnungsgemäße Durchführung des Nutzungsverhältnisses überhaupt erst ermöglicht und auf deren Einhaltung der Nutzer regelmäßig vertrauen darf. Gleiches gilt für Pflichtverletzungen der Erfüllungsgehilfen von Gleason. Gleason übernimmt keinerlei Gewähr oder Haftung für die Richtigkeit, Aktualität und Vollständigkeit auf der Plattform befindlichen Inhalte und Informationen. Hinweise, Empfehlungen und Auskünfte sind stets unverbindlich. 5.2 Der Nutzer stellt Gleason von sämtlichen Ansprüchen (inkl. Verlusten, Schäden und Aufwendungen) frei, die andere Nutzer oder sonstige Dritte gegenüber Gleason wegen dessen sonstiger Nutzung der Plattform geltend machen. Diese Freistellungsverpflichtung erstreckt sich auch auf die im Rahmen einer notwendigen Rechtsverteidigung entstehenden Kosten. Dies gilt nicht, wenn die Rechtsverletzung vom betroffenen Nutzer nicht zu vertreten ist.</li>
        <li>6. Datenschutz Personenbezogene Daten werden nach den gesetzlichen Bestimmungen, insbesondere des Telemediengesetzes und des Bundesdatenschutzgesetzes erhoben und verarbeitet. Ausführliche Informationen über Art, Umfang und Zweck der erhobenen personenbezogenen Daten finden sich in der Datenschutzerklärung. Datenschutzerklärung für die E-Learning Plattform.</li>
        <li>7. Schlussbestimmungen 7.1 Auf diese Nutzungsbedingungen sowie das Verhältnis zwischen dem Nutzer und dem Anbieter findet ausschließlich deutsches Recht Anwendung. 7.2 Sollten einzelne Bestimmungen dieser Nutzungsbedingungen unwirksam sein oder unwirksam werden, so wird dadurch die Gültigkeit des übrigen Inhalts dieser Nutzungsbedingungen nicht berührt. Die weggefallene Bestimmung ist durch eine Regelung zu ersetzen, die dem Zweck der weggefallenen Bestimmung am nächsten kommt. 7.3 Gleason behält sich vor, die vorliegenden Nutzungsbedingungen jederzeit mit Wirkung für die Zukunft zu ändern. Gleason wird die Nutzer über die neuen Bedingungen spätestens zwei Wochen vor ihrem Inkrafttreten per E-Mail und bei der nächsten Nutzung der Plattform informieren. Widerspricht ein Nutzer der Geltung der geänderten Nutzungsbedingungen nicht innerhalb von zwei Wochen nach Empfang der E-Mail oder nutzt er die Plattform widerspruchslos weiter, gelten die neuen Nutzungsbedingungen als akzeptiert. Gleason wird die Nutzer in der E-Mail, die die geänderten Nutzungsbedingungen enthält, auf die Bedeutung der Zweiwochenfrist gesondert hinweisen.</li>
      </ol>
    </div>
    <div v-if="!this.getDomainName.includes('gleason')">
      <a href="https://www.codevance.de/legal-notice">https://www.codevance.de/legal-notice</a>
    </div>
  </div>
</template>
<script>
export default {
  computed:{
    getDomainName(){
      return this.$store.getters.company.toLowerCase();
    },
  }
}
</script>
<style scoped>
  ol li{
    margin-bottom: 24px;
  }
  ol{
    padding-inline-start: 0;
  }
</style>