<template>
  <iframe v-if="this.template.editorData.videoType !== 'locale'" class="video"
          :src="this.template.editorData.videoSrc"
  ></iframe >
  <video controls v-if="this.template.editorData.videoType === 'locale'" class="video"
         :src="this.videoSrcProp">
  </video>
</template>

<script>
export default {
  name: "VideoTemplate",

  props:{
    template:{},
    videoSrcProp: String,
  },
}
</script>

<style scoped>

.video {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 4px;
}

</style>