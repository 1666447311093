<template>
  <div class="language-btn-container">
    <div>
      <a v-if="$i18n.locale !== 'de'" @click="setLocale('de')">DE</a>
      <strong v-if="$i18n.locale === 'de'">DE</strong>
      &nbsp;|&nbsp;
      <a v-if="$i18n.locale !== 'en'" @click="setLocale('en')">EN</a>
      <strong v-if="$i18n.locale === 'en'">EN</strong>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SelectLanguage',
  methods:{
    /*
    Sets the i18n locale
     */
    setLocale(locale){
      this.$i18n.locale = locale;
      /*
      Save to local storage and give it the name locale and in the second arg we pass in the locale that needs to be saved.
       */
      localStorage.setItem('locale', locale);
    }
  }
})
</script>
<style scoped>
  .language-btn-container button{
    color:lightgrey;
    background-color: transparent;
    border: none;
    padding: 0;
  }
</style>
