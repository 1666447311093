<template>
  <div>
    <form class="form-container">
      <div class="accountFormWrapper">
        <AppInput :isReadOnly="true" :value="friendlyName" label="Name" type="text" :placeholder="this.$t('placeholderFriendlyName')"></AppInput>
        <AppInput :isReadOnly="true" :value="username" label="Id" type="text" :placeholder="this.$t('placeholderPersonId')"></AppInput>
      </div>
      <AppInput :isReadOnly="true" :value="email" label="E-Mail" type="email" :placeholder="this.$t('placeholderEnterEmail')"></AppInput>
      <div class="accountFormWrapper">
        <AppInput :isReadOnly="true" :value="group" :label="$t('form.person.group')" type="text" :placeholder="this.$t('placeholderGroupName')"></AppInput>
        <AppInput :isReadOnly="true" :value="role" :label="$t('form.person.role')" type="text" :placeholder="this.$t('placeholderRole')"></AppInput>
      </div>
      <div style="margin-bottom: 20px">
        <label>E-Mail</label>
        <toggle-btn :default-state="true" label-disable-text="Notifications" label-enable-text="Notifications"></toggle-btn>
      </div>
    </form>
  </div>
</template>

<script>
import AppInput from "../../form/inputs/AppInput";
import ToggleBtn from "@/components/toggle/ToggleBtn";

export default {
  name: 'AccountSettings',
  components: {
    ToggleBtn,
    AppInput,
  },

  computed:{
    friendlyName(){
      return this.$store.getters.friendlyName;
    },
    username(){
      return this.$store.getters.username;
    },
    email(){
      return this.$store.getters.email;
    },
    group(){
      return this.$store.getters.group;
    },
    role(){
      return this.$store.getters.role;
    }
  }
}
</script>

<style scoped>

.accountFormWrapper{
  display: flex;
}

label{
  font-weight: 300;
}

input:focus, select:focus, textarea:focus-visible, textarea:focus {
  outline: none;
  border: 1px solid var(--primary-color);
  border-radius: 4px;
}

input, select, textarea{
  height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
  border: 1px solid var(--brightgrey);
  background-color: var(--bright);
}

input:focus + label,
select:focus + label,
textarea:focus + label {
  color: var(--primary-color);
}

@media screen and (max-width: 600px) {
  .accountFormWrapper{
    display: block;
  }
}

</style>
