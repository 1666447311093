
const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:737a3171-b957-40dd-928a-67ec7115f8ba",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_QqksxUly9",
    "aws_user_pools_web_client_id": "5e6um0890o72ql98v2fanfvfj3",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "Storage": {
        "AWSS3": {
            "bucket": 'gleason-elearning-storage', //REQUIRED -  Amazon S3 bucket name
            "region": 'eu-central-1', //OPTIONAL -  Amazon service region
        }
    },
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
