<template>
  <div class="form-wrapper pseudo-table">
    <input :readonly="this.isReadOnly" :checked="this.checked" :value="value" @change="this.inputChanged" :type="type" :disabled="disabled"
           :placeholder="this.placeholder" :name="label" :id="label" :class="this.hasError ? 'border-red' : 'border-grey'">
    <label :for="label">{{ label }}</label>
  </div>
</template>

<script>

export default {

  name: 'AppInput',
  data(){
    return{
      input: this.value,
    }
  },
  props: {
    label: String,
    type: String,
    value: String,
    disabled:Boolean,
    checked: Boolean,
    hasError: Boolean,
    isReadOnly: Boolean,
    placeholder: String,
  },
  methods:{
    inputChanged(event){
      this.$emit('inputChanged', event) // trigger event on the current instance
    },
  },
}
</script>

<style>

  .form-wrapper{
    width: 100%;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column-reverse;
  }

  .form-wrapper:nth-child(odd){
    margin-right: 40px;
  }

  label{
    font-weight: 300;
  }

  input:focus{
    outline: none;
    border: 1px solid var(--primary-color);
    border-radius: 4px;
  }

  input::placeholder{
    color: #b4b8be;
    font-size: 14px;
  }

  /*noinspection CssUnusedSymbol*/
  .border-red{
    border: 1px solid red;
  }

  /*noinspection CssUnusedSymbol*/
  .border-grey{
    border: 1px solid #e8e8e8;
  }

  input{
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 4px;
    background-color: var(--bright);
    font-size: 16px;
  }

  input:focus + label{
    color: var(--primary-color);
  }

  /**
  styling for custom checkboxes
   */
  input[type=checkbox] {
    position: relative;
    cursor: pointer;
  }

  input[type=checkbox]:before {
    content: "";
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 6px;
    left: -6px;
    border: 2px solid var(--primary-color);
    background-color: var(--brightgrey);
    border-radius: 50%;
  }
  input[type=checkbox]:checked:before {
    content: "";
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 6px;
    left: -6px;
    background-color:#1E80EF;
  }
  input[type=checkbox]:checked:after {
    content: "";
    display: block;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 10px;
    left: 2px;
  }

  @media screen and (max-width: 400px) {
    .form-wrapper:nth-child(odd){
      margin-right: 0;
    }
  }



</style>
