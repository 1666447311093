<template>
  <div class="news-form-container">
    <div class="form-container">
      <div class="flex">
        <div style="position: relative; width: 100%; margin-right: 20px">
          <AppInput :hasError='v$.title.$error' :value="title" @input-changed="titleChanged"
                    :label="$t('form.title')" type="text" :placeholder="this.$t('placeholderTitle')"></AppInput>
        </div>

        <div class="creator-container" style="position: relative; width: 100%;">
          <AppInput :hasError='v$.creator.$error' :value="creator" @input-changed="creatorChanged"
                    :label="$t('form.creator')" type="text" :placeholder="this.$t('placeholderCreator')"></AppInput>
        </div>
      </div>

      <div style="position: relative; width: 100%; margin-right: 20px;">
        <AppInput :hasError='v$.description.$error' :value="description" @input-changed="descriptionChanged"
                  :label="$t('form.description')" type="text" :placeholder="this.$t('placeholderDescription')"></AppInput>
      </div>

      <div style="position: relative; width: 100%; margin-right: 20px; margin-bottom: 24px;">
        <div>{{ $t("advertisedCourse") }}</div>
          <VueMultiselect
              mode="single"
              :placeholder="this.$t('advertisedCourse')"
              :options="this.promotionOptions"
              v-model="this.promotedCourseMap"
              @select="setPromotedCourseId"
              track-by="id"
              label="title"
          />
      </div>

      <div style="position: relative; width: 100%; margin-right: 20px;">
        <AppTextarea :hasError='v$.content.$error' :value="content" @text-area-changed="contentChanged"
                     :label="$t('form.content')" >
        </AppTextarea>
      </div>

      <div class="form-wrapper-file">
        <div>{{ $t("newTitleImg") }}</div>
        <div>
          <input @change="fileChanged" accept="image/jpeg" type="file"/>
        </div>
        <div>
          <img :src="this.imageSrc" alt="">
        </div>
      </div>

      <div class="form-btm-wrapper" style="margin-top: 24px">
        <primary-btn @click="submitForm" class="course-submit-btn" type="submit" :val="$t('confirm')"></primary-btn>
      </div>

    </div>

    <Loader :show="this.showLoader"></Loader>
  </div>
</template>

<script>
import PrimaryBtn from "../buttons/PrimaryBtn";
import AppInput from "../form/inputs/AppInput";
import AppTextarea from "../form/textarea/AppTextarea";
import Loader from "@/components/loader/Loader";
import {S3FileManager} from "@/mixins/s3-file-manager/s3-file-manager";
import Uuidv4Generator from "../../mixins/uuid/Uuidv4Generator";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import VueMultiselect from "vue-multiselect";

export default {
  name: 'NewsForm',
  mixins: [Uuidv4Generator],

  setup(){
    return{
      v$: useVuelidate()
    }
  },

  components: {
    AppTextarea,
    PrimaryBtn,
    AppInput,
    Loader,
    VueMultiselect
  },

  props:{
    post: Object
  },

  computed:{
    /**
     * The VueMultiselect takes an object to be able to select the id,
     * but to also display the title. However, we only submit the id to
     * the server, so we have to assemble the mpa using the course id
     * and pass that to the VueMultiselect component.
     * @returns {{id: string, title: string}}
     */
    promotedCourseMap(){
      let map = {"id":"", "title":""};
      for (let course of this.promotionOptions){
        if(course.id === this.promotedCourseId){
          map.id = course.id;
          map.title = course.title;
        }
      }
      return map;
    }
  },

  data(){
    return{
      title: "",
      description: "",
      creator: "",
      content: "",
      selectedCourse: [],
      showLoader: false,
      imageSrc: "",
      promotedCourseId: "",
      promotionOptions: [],
      id: this.createUuidv4()
    }
  },
  validations() {
    return {
      title: {required},
      description:{required},
      creator: {required},
      content: {required},
    }
  },

  methods:{
    postSubmitted(post){
      this.$emit("form-submitted", post);
    },

    titleChanged(event){
      this.title = event.target.value;
    },

    creatorChanged(event){
      this.creator = event.target.value;
    },

    descriptionChanged(event){
      this.description = event.target.value;
    },

    contentChanged(event){
      this.content = event.target.value;
    },

    fileChanged(event){
      this.uploadTitleImg(event.target.files[0]);
    },

    setPromotedCourseId(event){
      this.promotedCourseId = event.id;
    },

    setTitleImage(){
      const s3FileManager = new S3FileManager();
      const prefix = "news/" + this.id;
      s3FileManager.checkIfListExists(prefix).then(res => {
        if (res.length > 0){
            s3FileManager.getSignedURL(prefix + "/image.jpeg").then(url=>{
              this.imageSrc = url;
          })
        }
      })
    },

    createNewsPostObject(){
      return{
        "id": this.id,
        "title": this.title,
        "creator": this.creator,
        "description": this.description,
        "content":this.content,
        "promotedCourse": this.promotedCourseId,
        "creationDateUnix": new Date().getTime()
      }
    },

    submitForm() {
      this.v$.$validate();
      if(!this.v$.$error){
        this.showLoader = true;
        const postObject = this.createNewsPostObject();
        this.$store.dispatch("postRequest",{
          "param":"editPost",
          "payload":postObject
        }).then(() => {
          this.showLoader = false;
          this.postSubmitted(postObject);
        })
      }
    },

    uploadTitleImg(file){
      const path = "news/" +  this.id + "/image.jpeg";
      const s3FileManager = new S3FileManager();
      s3FileManager.uploadFile(file, path).then(()=>{
        this.setTitleImage();
      });
    },

    sortData(data, sortByItem){
      data.sort((a, b)=>{
        if (a[sortByItem].toLowerCase() < b[sortByItem].toLowerCase()) return -1;
        if (a[sortByItem].toLowerCase() > b[sortByItem].toLowerCase()) return 1;
      });
    },
  },

  mounted() {
    this.$store.dispatch("getRequest","getCoursesMetaInfo").then(data => {
      // there might be a possibility, that we do not have
      // courses yet.
      this.promotionOptions = JSON.parse(data.body);

      if (this.promotionOptions.length > 0){
        this.sortData(this.promotionOptions, "title");
        this.promotedCourseId = this.promotionOptions[0]["id"];
      }

      if (this.post){
        this.title = this.post.post.title;
        this.description = this.post.post.description;
        this.creator = this.post.post.creator;
        this.id = this.post.post.id;
        this.content = this.post.post.content;
        this.setTitleImage();
      } else {
        this.id = this.createUuidv4();
      }
    });
  },

}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style scoped>

img{
  max-height: 100px;
  display: block;
  margin-top: 10px;
}

.form-container{
  padding: 40px;
  background-color: var(--brightgrey);
  border-radius: 4px;
  margin-bottom: 24px;
}

.form-btm-wrapper{
  display: flex;
  justify-content: right;
}

.flex{
  display: flex;
}

label{
  font-weight: 300;
}

input:focus, select:focus, textarea:focus-visible, textarea:focus {
  outline: none;
  border: 1px solid var(--primary-color);
  border-radius: 4px;
}

input[type = "file"]{
  line-height:40px;
}

input, select, textarea{
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
  border: 1px solid #d1d5db;
  background-color: var(--bright);
}

input:focus + label,
select:focus + label,
textarea:focus + label {
  color: var(--primary-color);
}

.course-submit-btn{
  position: fixed;
  right: 64px;
  bottom: 64px;
}

.creator-container{
  margin-left: 20px;
}

@media screen and (max-width: 800px) {

  .flex{
    display: block;
  }

  .creator-container{
    margin-left: 0;
  }
}

@media screen and (max-width: 600px) {
  img{
    width: 100%;
    max-height: none;
  }

  .form-container{
    padding: 0;
    background-color: transparent;
    margin-bottom: 0;
  }

  .flex{
    display: block;
  }

  .course-submit-btn{
    right: 40px;
    bottom: 40px;
  }
}

</style>

