<template>
  <div class="main-content-container">
    <h1 style="margin-bottom: 24px;">{{this.$t('footer.help')}}</h1>
    <div style="margin-bottom: 24px; display: flex; justify-content: space-between; gap: 20px; align-items: center">
      <p>{{this.$t('elearningSupport')}} <a style="color: var(--primary-color)">{{mailTo}}</a>.</p>
      <img style="width: 50%" src="../../../public/img/help.jpg" alt="Help image">
    </div>
  </div>
</template>

<script>

import {Config} from "../../config";

export default {
  name: "Help",
  data(){
    return{
      mailTo: Config.AboutUs.email
    }
  }
}

</script>
