<template>
  <div>
    <h3 style="margin-bottom: 10px;">Video-Template</h3>
    <AppInput :has-error="titleIsEmpty" :value="stepObject.title" @input-changed="emitTitleChangedEvent" type="text" :label="this.$t('title')" :placeholder="this.$t('placeholderTitle')"></AppInput>
    <AppInput :value="stepObject.description" @input-changed="emitDescriptionChangedEvent" type="text" :label="this.$t('form.description')" :placeholder="this.$t('placeholderDescription')"></AppInput>
    <label>{{ $t('videoType') }}</label>
    <VueMultiselect @select="videoTypeChanged" mode="single"
                 :options= "videoTypeData"
                 v-model="selectedVideoType"
                 :placeholder="this.$t('selectVideoType')">
    </VueMultiselect>
    <div style="margin-top:30px">
      <div v-if="selectedVideoType === 'youtube'">
        <AppInput :value="videoSrc" @input-changed="videoSrcChanged" type="text" :label="this.$t('insertYTLink')" :placeholder="this.$t('placeholderYoutubeLink')"></AppInput>
        <iframe width="100%" style="min-height:400px;" :src="videoSrc">
        </iframe>
      </div>
      <div v-if="selectedVideoType === 'locale'">
        <div class="form-wrapper-file">
          <label>{{ $t('uploadVideo') }}</label>
          <input type="file" accept="video/mp4" @change="videoFileUploaded"/>
        </div>
        <video controls style="min-height:400px; margin-top: 24px; max-width:100%" :src="videoSrc">
        </video>
      </div>
      <div v-if="selectedVideoType === 'link'">
        <AppInput :value="videoSrc" @input-changed="videoSrcChanged" type="text" :label="this.$t('insertLinkToVideoSrc')" :placeholder="this.$t('placeholderVideoSrc')"></AppInput>
        <iframe width="100%" style="min-height:400px;" :src="videoSrc">
        </iframe>
      </div>
    </div>
  </div>
</template>

<script>

import AppInput from "../form/inputs/AppInput";
import VueMultiselect from "vue-multiselect";
import {S3FileManager} from "@/mixins/s3-file-manager/s3-file-manager";

export default {
  name: "VideoEditor.vue",
  components: {AppInput, VueMultiselect},

  props:{
    stepObject: Object,
    moduleId: String,
    courseId: String
  },

  data(){
    return{
      selectedVideoType: this.stepObject.editorData.videoType,
      videoTypeData: ["youtube","locale","link"],
      videoSrc : this.stepObject.editorData.videoSrc,
      titleIsEmpty: false,
    }
  },

  methods:{
    emitTitleChangedEvent(title){
      if(title.target.value === undefined || title.target.value === '' || title.target.value === null){
        this.titleIsEmpty = true;
      }else{
        this.titleIsEmpty = false;
        this.$emit("step-title-changed", title);
      }
    },
    emitDescriptionChangedEvent(description){
      this.$emit("step-description-changed", description);
    },

    // converts the Youtube link into the id of the video.
    getId(url){
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);

      return (match && match[2].length === 11)
          ? match[2]
          : null;
    },


    videoSrcChanged(event){
      this.videoSrc = event.target.value;

      if(this.selectedVideoType === 'youtube') {
        const videoId = this.getId(this.videoSrc);
        // Changing the youtube link into embed, otherwise the video can't be identified.
        this.videoSrc = 'https://www.youtube.com/embed/' + videoId;
        event.target.value = this.videoSrc
      }

      const videoObject = {
        "videoType": this.selectedVideoType,
        "videoSrc" : this.videoSrc
      }

      this.$emit("editor-changed", videoObject);
    },

    videoFileUploaded(event){
      const file = event.target.files[0];
      const path = "course/" + this.courseId + "/" + this.moduleId + "/" + this.stepObject.id + "/video.mp4";
      const s3FileManager = new S3FileManager();
      s3FileManager.uploadFile(file, path).then(()=>{
        this.setInstructionVideo(path)
      });
    },

    setInstructionVideo(prefix){
      const s3FileManager = new S3FileManager();
      s3FileManager.checkIfListExists(prefix).then(res => {
       if(res.length > 0){
         s3FileManager.getSignedURL("course/" + this.courseId + "/" + this.moduleId + "/" + this.stepObject.id + "/video.mp4").then(url=>{
           this.videoSrc = url;
         })
       }
      })
    },

    videoTypeChanged(videoType){
      // if it was previously locale, we need to reset the link as ohterwise it will search for link or youtube video right away.
      if (this.selectedVideoType === "locale") this.selectedVideoType = "";

     this.selectedVideoType = videoType;

     const videoObject = {
       "videoType": this.selectedVideoType,
       "videoSrc" : this.videoSrc
     }

      this.$emit("editor-changed", videoObject);

     },
  },


  mounted() {
    // this is our s3 directory where all image templates - if any - are stored.
    if (this.selectedVideoType === "locale"){
      this.s3Directory = "course/" + this.courseId + "/" + this.moduleId + "/" + this.stepObject.id;
      this.setInstructionVideo(this.s3Directory);
    }

  },

  watch:{
    stepObject(){
      this.selectedVideoType = this.stepObject.editorData.videoType;
      this.videoSrc = this.stepObject.editorData.videoSrc;
    }
  }

}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
