<template>
  <div class="home main-content-container">
    <h1>{{ $t("nav.home") }} ({{newsList.length}})</h1>
    <PrimaryBtn v-if="this.isAdmin" class="primaryBtnMarginBtm" @click="openModal" :val="$t('feed.add')"></PrimaryBtn>
    <div v-if="newsList.length > 0">
      <vueper-slides
          class="no-shadow"
          :visible-slides="3"
          slide-multiple
          :gap="3"
          :slide-ratio="1 / 4"
          :dragging-distance="200"
          :breakpoints="breakpoints"
          fixed-height="365px">
        <vueper-slide v-for="item in newsList" :key="item">
          <template #content>
            <NewsCard @edit-post="editPost" @delete-post="deletePost" @articleClicked= articleClicked(item) :post="item">
            </NewsCard>
          </template>
        </vueper-slide>
      </vueper-slides>
    </div>

  </div>
  <!-- The :heading of the Modal is set according to which Component is being used. In this case new / edit post -->
  <Modal :val="this.$t('back')" @close-form="this.modalClosed" :heading="this.$options.postModalHeader" :modal-is-open=modalIsOpen>
    <NewsForm :post="this.selectedPost" @form-submitted="newsFormSubmitted"></NewsForm>
  </Modal>

  <Loader :show="this.showLoader"></Loader>

</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

import NewsCard from "../components/news/NewsCard";
import Modal from "../components/modal/Modal";
import NewsForm from "../components/news/NewsForm";
import PrimaryBtn from "@/components/buttons/PrimaryBtn";
import {S3FileManager} from "@/mixins/s3-file-manager/s3-file-manager";
import Loader from "../components/loader/Loader";
import SweetAlerts from "../mixins/sweet-alerts/SweetAlerts";

export default {
  name: 'Home',
  // public var
  postModalHeader: "",
  mixins: [SweetAlerts],
  components:{
    Loader,
    NewsForm,
    NewsCard,
    PrimaryBtn,
    Modal,
    VueperSlides,
    VueperSlide,
  },

  data: () => ({
    modalIsOpen: false,
    showLoader: false,
    selectedPost: null,
    isAdmin: false,

    breakpoints: {
      900: {
        visibleSlides: 2,
        slideMultiple: 2,
      },
      600: {
        visibleSlides: 1,
        slideMultiple: 1,
        arrows: false,
        bulletsOutside: true
      },
    },
  }),

  methods:{
    openModal(){
      this.modalIsOpen = !this.modalIsOpen;
      this.$options.postModalHeader =  this.$t("feed.add");
    },

    articleClicked(params){
      this.$router.push({
        name: 'News Id',
        params: params}
      );
    },

    modalClosed(){
      this.modalIsOpen = false;
      this.selectedPost = null;
    },

    newsFormSubmitted(post){
      this.modalIsOpen = false;
      this.selectedPost = null;
      this.updateOrAddPost(post);
    },

    editPost(data){
      this.selectedPost = data;
      this.modalIsOpen = true;
      this.$options.postModalHeader = this.$t("editPost")
    },

    deletePost(data){
      // won't resolve if not confirmed.
      this.showConfirmDialogue(this.$t('deletePost'), this.$t('swal.confirmDelete')).then(()=>{
        const postId = data.id;
        const s3Dir = "news/" + postId + "/image.jpeg";
        const fileManager = new S3FileManager();
        // delete the files
        fileManager.deleteObjects(s3Dir).then(() => {})

        this.$store.dispatch("deletePost",data).then(()=>{
          this.showSuccessMessage('Post ' + ' ' + this.$t('deleteSuccess'));
        })
      })
    },

    // iterate through the existing posts
    // and add one if not found, otherwise
    // edit.
    updateOrAddPost(post){
      this.$store.commit("updatePost",post);

      if(this.$options.postModalHeader === this.$t('feed.add')){
        this.showSuccessMessage('Post ' + this.$t('addSuccess'));
      }else{
        this.showSuccessMessage('Post ' + this.$t('swal.updateSuccess'));
      }
    },
  },

  computed:{
    newsList(){
      return this.$store.getters.posts;
    }
  },

  mounted() {
    this.showLoader = true;

      this.$store.dispatch("getPosts").then(()=>{
        this.showLoader = false;
      });

      if (this.$store.getters.role) {
        if (this.$store.getters.role.toLowerCase() === "admin") this.isAdmin = true;
      }
    }
}

</script>

<style scoped>

.vueperslides__arrow svg{
  width: 2em;
}

h1{
    margin-bottom: 24px;
  }

  .primaryBtnMarginBtm{
    margin-bottom: 24px;
  }

  @media screen and (max-width: 600px) {

    .card-component-container div{
      margin-bottom: 15px;
    }

    .card-component-container div:last-child{
      margin-bottom: 0;
    }
  }


</style>
