<template>
  <div class="form-container" style="position: relative">
    <div class="flex-wrapper">
      <div class="form-elm-left">
        <AppInput @input-changed="addTitle" :label="$t('form.title')" type="text" :placeholder="this.$t('placeholderTitle')"></AppInput>
      </div>
      <div>
        <AppInput @input-changed="setCalendarStart" :label="$t('dateStart')" type="date" :placeholder="this.$t('placeholderDateStart')"></AppInput>
      </div>
      <div>
        <AppInput @input-changed="setCalendarTimeStart" :label="$t('timeStart')" type="time" :placeholder="this.$t('placeholderTimeStart')"></AppInput>
      </div>
      <div>
        <AppInput @input-changed="setCalendarEnd" :label="$t('dateEnd')" type="date" :placeholder="this.$t('placeholderDateEnd')"></AppInput>
      </div>
      <div>
        <AppInput @input-changed="setCalendarTimeEnd" :label="$t('timeEnd')" type="time" :placeholder="this.$t('placeholderTimeEnd')"></AppInput>
      </div>
      <div class="form-btm-wrapper">
        <PrimaryBtn class="course-submit-btn" @click="submitCalendar" type="submit" :val="$t('confirm')"></PrimaryBtn>
      </div>
    </div>
  </div>
</template>

<script>
import PrimaryBtn from "../../buttons/PrimaryBtn";
import AppInput from "@/components/form/inputs/AppInput";
import SweetAlerts from "@/mixins/sweet-alerts/SweetAlerts";
import Uuidv4Generator from "@/mixins/uuid/Uuidv4Generator";

export default {
  name: 'CalendarPopup',
  mixins: [SweetAlerts, Uuidv4Generator],

  components: {
    AppInput,
    PrimaryBtn
  },

  data() {
    return {
      calendarEntry: {},
    }
  },

  methods: {
    submitCalendar() {
      // Checking if the initial keys are empty and if the user deleted the input ,checking if the input is "".
      if (Object.keys(this.calendarEntry).length < 5 || this.calendarEntry.title === ""
          || this.calendarEntry.timeStart === "" || this.calendarEntry.timerEnd === ""
          || this.calendarEntry.dateStart === "" || this.calendarEntry.dateEnd === "") {
        this.showWarningMessage(this.$t('calendarSubmitEmpty'));
        //Checking if the start date/time was set after or equal to the end date/time.
      } else if(this.calendarEntry.dateStart > this.calendarEntry.dateEnd ||
      this.calendarEntry.dateStart >= this.calendarEntry.dateEnd && this.calendarEntry.timeStart >= this.calendarEntry.timerEnd) {
        this.showWarningMessage(this.$t('calendarDateSubmitWrong'));
      }else{
        //When submitting, we emit the changes and show a confirm dialog.
        this.emitEventsChanged();
      }
    },
    // Here we set the value we input in the AppInput into a new object.
    addTitle(event) {
      this.calendarEntry.title = event.target.value;
    },
    setCalendarStart(event) {
      this.calendarEntry.dateStart = event.target.value;
    },
    setCalendarEnd(event) {
      this.calendarEntry.dateEnd = event.target.value;
    },
    setCalendarTimeStart(event) {
      this.calendarEntry.timeStart = event.target.value;
    },
    setCalendarTimeEnd(event) {
      this.calendarEntry.timerEnd = event.target.value;
    },
    // Here we emit only the title/start/end of calendarEntry with the key-word "calendar-changed" and delete not needed attributes.
    emitEventsChanged() {
      this.calendarEntry.start = this.calendarEntry.dateStart + " " + this.calendarEntry.timeStart;
      this.calendarEntry.end = this.calendarEntry.dateEnd + " " + this.calendarEntry.timerEnd;
      this.calendarEntry["id"] = this.createUuidv4();
      delete this.calendarEntry.dateStart;
      delete this.calendarEntry.timeStart;
      delete this.calendarEntry.dateEnd;
      delete this.calendarEntry.timerEnd;
      this.$emit("calendar-changed", this.calendarEntry);
    }
  }

}
</script>

<style scoped>

.thumbnail-empty:hover svg {
  fill: var(--primary-color);
  cursor: pointer;
}

.course-submit-btn {
  position: fixed;
  right: 64px;
  bottom: 64px;
}

@media screen and (max-width: 600px) {
  .course-submit-btn {
    right: 40px;
    bottom: 40px;
  }
}


</style>
