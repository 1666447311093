<template>
  <div class="topnav">
    <div class="flex">
      <span>
        <CSS3DRoom></CSS3DRoom>
      </span>
      <span class="logoutBtn hide-mobile" @click="logout">
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#555"><path d="M0 0h24v24H0z" fill="none"/><path d="M17 7l-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5zM4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5z"/></svg>
      </span>
      <span>
        <DropDownNotification v-if="!isLoginPage" :dropdown-notify="dropdownNotify"></DropDownNotification>
      </span>
      <span>
        <AccountDropdown v-if="!isLoginPage" :dropdown-account="computedDropdownAccount"></AccountDropdown>
      </span>
    </div>
  </div>
</template>

<script>

import DropDownNotification from "../notification/dropdown/DropDownNotification";
import AccountDropdown from "../user/account/dropdown/AccountDropdown";
import {Auth} from "aws-amplify";
import CSS3DRoom from "../CSS3DRoom/CSS3DRoom";

export default {
  components: {CSS3DRoom, AccountDropdown, DropDownNotification},
  data(){
    return {
      dropdownNotify: [],
      dropdownAccount: [],
    }
  },
  computed:{
    computedDropdownAccount(){
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.dropdownAccount = [{
        label: this.$t('nav.settings'),
        iconSrc: require('/public/img/icons/dropdown-icons/topnav-dropdown/manage_accounts_black_24dp.svg'),
        clickCallback: () => this.$router.push({
          path: '/settings/',
          name: 'Settings',
        })
      },
        {
          label: this.$t('nav.courses'),
          iconSrc: require('/public/img/icons/dropdown-icons/topnav-dropdown/school_black_24dp.svg'),
          clickCallback: () => this.$router.push({
            path: '/my-courses/',
            name: 'My courses',
          })
        },
        {
          label: this.$t('footer.help'),
          iconSrc: require('/public/img/icons/dropdown-icons/topnav-dropdown/help_black_24dp.svg'),
          clickCallback: () =>  this.$router.push({
            name: 'Help',
          })
        },
        {
          label:this.$t('Logout'),
          iconSrc: require('/public/img/icons/dropdown-icons/topnav-dropdown/logout_black_24dp.svg'),
          clickCallback: () => this.logout(),
          name:'Logout'}]
    },

    isLoginPage(){
      return window.location.href.includes("login");
    }
  },
  methods:{
    logout() {
      this.$swal({
        icon: 'warning',
        type: "warning",
        title: this.$t('swal.logout'),
        showDenyButton: true,
        confirmButtonText: this.$t('confirm'),
        denyButtonText: this.$t("button.cancel"),
        confirmButtonColor: "#007AC2",
        denyButtonColor: "#6e7881",
      }).then((result) => {
        if(result.isConfirmed){
          try {
            Auth.signOut();
            this.$store.dispatch("signout");
          } catch (error) {
            alert(error.message);
          }
          this.$router.push({
            path:'/login/',
            name: 'Login',
          })
          setTimeout(()=>{
            location.reload();
          },2000);
        }
      })

    },
  }
}
</script>

<style scoped>

.logoutBtn:hover{
  cursor: pointer;
}

.logoutBtn:hover svg{
  fill: var(--primary-color);
}

button{
  border: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
}
  .topnav {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;
    padding: 24px;
    color: var(--darkgrey);
    background-color: var(--bright);
    -webkit-box-shadow: 0 0 5px rgb(169, 191, 203);
    -moz-box-shadow: 0 0 5px rgb(169, 191, 203);
    box-shadow: 0 0 5px rgb(169, 191, 203);
  }

  .topnav span{
    margin-right: 24px;
  }

  .topnav span:last-child{
    margin-right: 0;
  }

  .flex{
    display: flex;
  }


@media screen and (max-width: 600px) {
  .hide-mobile{
    display: none;
  }
}
</style>

