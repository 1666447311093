<template>
  <div class="form-wrapper">
    <textarea :class="this.hasError ? 'border-red' : 'border-grey'" @change="this.textAreaChanged"
              :placeholder="label" :name="label" :id="label" :value="value"></textarea>
    <label :for="label">{{ label }}</label>
  </div>
</template>

<script>
  export default {
    name: 'AppTextarea',

    props: {
      label: String,
      value:  String,
      hasError: Boolean
    },
    methods:{
      textAreaChanged(event){
        this.$emit('textAreaChanged', event) // trigger event on the current instance
      },
    }
  }
</script>

<style>

  textarea{
    height: 30vh;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    border-radius: 4px;
    border: 1px solid #b4b8be;
    background-color: var(--bright);
    font-family: Roboto, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--darkgrey);
    font-size: 16px;
  }

  textarea:focus{
    outline: none;
    border: 1px solid var(--primary-color);
    border-radius: 4px;
  }

  textarea::placeholder{
    color: #b4b8be;
    font-size: 14px;
  }

  textarea:focus + label{
    color: var(--primary-color);
  }

  /*noinspection CssUnusedSymbol*/
  .border-red{
    border: 1px solid red;
  }

  /*noinspection CssUnusedSymbol*/
  .border-grey{
    border: 1px solid #e8e8e8;
  }


</style>
