// noinspection ES6UnusedImports

import {
    Engine,
    Scene,
    Vector3,
    MeshBuilder,
    StandardMaterial,
    Color4,
    HemisphericLight,
    ArcRotateCamera, Mesh, Matrix, Camera, Tools,
} from "@babylonjs/core";

import * as BABYLON from 'babylonjs';
import 'babylonjs-loaders';
import * as GUI from "@babylonjs/gui";

let engine = null;

let currentCourse = localStorage.getItem('courseId') ? localStorage.getItem('courseId') : "https://academy.gleason.com/#/library";


let scene = null

/**
 * this is the listener for the pointer events, we need to toggle those because the natural pointer events from the babylon js
 * view will overwrite the pointer events of the iframes, so they become unusable.
 *
 * thats why on mouse move, we check what has been hovered, if we clicked one of the planes that the iframes are on we set the pointer events of the body to none, so that we
 * can use the iframes ans if we click out of the iframes we set them to auto so we can use the scene and spin the camera
 * @param evt
 */
var listener = function(evt) {
    let pick = scene.pick(Math.round(evt.offsetX), Math.round(evt.offsetY));
    if (pick.hit) {
        if(pick.pickedMesh.name === "contentPlane"){
            document.getElementsByTagName('body')[0].style.pointerEvents = 'none';
        } else {
            document.getElementsByTagName('body')[0].style.pointerEvents = 'auto';
        }
    }
}

let listenerOnMove = function(evt){
    let pick = scene.pick(Math.round(evt.offsetX), Math.round(evt.offsetY));
    if (pick.hit) {
        if(pick.pickedMesh.name !== "contentPlane"){
            document.getElementsByTagName('body')[0].style.pointerEvents = 'auto';
        }
    }
}

const createScene = (canvas) => {
    return new Promise((resolve)=>{
        engine = new Engine(canvas);
        scene = new Scene(engine);

        //imports the classroom model
        BABYLON.SceneLoader.Append("./", "room_rotated3_big.glb", scene, function () {});


        // Needs to be transparent for iframe to be seen
        scene.clearColor = new Color4(0,0,0,0);

        // we set the viewing angle of the camera
        var camera = new ArcRotateCamera("Camera", -1.55, 1.55, 0.3, new BABYLON.Vector3(-1.5, 3.5, -2), scene);

        //disables mouse wheel
        camera.wheelDeltaPercentage = 0

        //we disable zooming on touch devices
        camera.lowerRadiusLimit = camera.radius
        camera.upperRadiusLimit = camera.radius


        // This attaches the camera to the canvas
        camera.attachControl(canvas, true, false);

        //removes ability to move
        camera.inputs.remove(camera.inputs.attached.mousewheel);
        camera.inputs.attached.pointers.buttons = [0,1];

        //the light source for the whole scene
        const light = new HemisphericLight("light", new Vector3(0, 1, 0), scene);
        light.intensity = 1.5;

        /**
         * here we create to planes, the two iframes we have will be layed on top of those planes, so
         * for example we want to move the iframes, we will have to move the planes.
         * @type {Mesh}
         */
        var plane = MeshBuilder.CreatePlane("contentPlane", {width: 2.7, height: 2}, scene);
        plane.id="contentPlane1"
        plane.scaling.x = 6.5
        plane.scaling.y = 4
        plane.position = new BABYLON.Vector3(-1, 3.5, 14);

        var plane2 = MeshBuilder.CreatePlane("contentPlane", {width: 2.7, height: 2}, scene);
        plane2.id="contentPlane2"
        plane2.scaling.x = 6.5;
        plane2.scaling.y = 4;
        plane2.position = new Vector3(-15, 3.5, -2);
        plane2.rotation.y = Tools.ToRadians(-90);

        let renderer = setupRenderer();

        createCSSobject(plane, scene, renderer, "iframe1", currentCourse);
        createMaskingScreen(plane, scene, renderer)

        createCSSobject(plane2, scene, renderer, "iframe2", "https://academy.gleason.com/#/library");
        createMaskingScreen(plane2, scene, renderer)

        window.addEventListener('pointerdown', listener);
        window.addEventListener('pointermove', listenerOnMove);

        /**
         * we set the source of the iframe again to prevent caching issues
         * TODO: monitor this once they are not pointed at localhost anymore, maybe we dont even need this
         * @type {HTMLElement}
         */
        var iframe1 = document.getElementById("iframe1"); iframe1.contentWindow.location.href = iframe1.src;
        var iframe2 = document.getElementById("iframe2"); iframe2.contentWindow.location.href = iframe2.src;

        var advancedTexture = GUI.AdvancedDynamicTexture.CreateFullscreenUI("UI");

        /**
         * to have a dynamic button for differens screen sizes, we set the width and the position
         * of the button depending on the width and height of the canvas
         *
         * also, depending on if we are on a really small screen, we only show an image in the button and no text
         * @type {number}
         */
        let height = document.getElementById("canvasZone").offsetHeight
        let width = document.getElementById("canvasZone").offsetWidth


        if(!navigator.userAgent.includes("Mac") && "ontouchend") {
            var button1 = GUI.Button.CreateSimpleButton("but1", "Vollbild");
            button1.width = (width/10) + "px";
            button1.height = "40px";
            button1.color = "white";
            button1.top = (height/10) + "px"
            button1.left = "40px"
            button1.cornerRadius = 3;
            button1.background = "#007ac2";
            button1.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT;
            button1.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP;
            button1.onPointerUpObservable.add(function() {
                iframe1.requestFullscreen();
            });
            button1.onPointerMoveObservable.add(function() {
                document.getElementsByTagName('body')[0].style.pointerEvents = 'auto';
            });
            advancedTexture.addControl(button1);
        }



        engine.runRenderLoop(() => {
            scene.render();
        });

        /**
         * this is the part where we change the active course on the first iframe depending on what has been clicked
         * on the second iframe. when a course is clicked, we set the courseID in the local storage,
         * once that happened the eventListener triggers and the we check first if the clicked course is a
         * different one than the one already showing, if yes then we change the source of the iframe, and importantly we need to reload
         * the iframe and then we set the camera to the iframe, if its the same course we just set the camera
         */
        window.addEventListener('storage', (e) => {
            if(e.key === "courseId"){
                let newCourse = localStorage.getItem('courseId');
                if (newCourse !== currentCourse){
                    var iframe1 = document.getElementById("iframe1");
                    iframe1.contentWindow.location.href = newCourse;
                    iframe1.contentWindow.location.reload();
                    currentCourse = newCourse;
                    camera.alpha = -1.55;
                    camera.beta = 1.55;
                } else {
                    camera.alpha = -1.55;
                    camera.beta = 1.55;
                }
            }
        });

        /**
         * as soon as everything is running and ready, we resolve the promise
         * we do this to make sure we have a complete scene before the user gets a broken scene
         */
        scene.executeWhenReady(()=>{
            resolve(scene)
        })
    })

};

const removeScene = ()=>{
    scene = null;
    engine.dispose();
    window.removeEventListener("pointerdown", listener)
    window.removeEventListener("pointermove", listenerOnMove)
}

/**
 * this function is purely for safety reasons, only in the online version
 * somehow a second css-container is created, so before we create another one we remove all just in case
 */
const removeChildren = function(){
    let canvasZone = document.getElementById("canvasZone");
    for (let child of canvasZone.children){
        if(child.id !== "renderCanvas"){
            canvasZone.removeChild(child)
        }
    }
}

const setupRenderer = function() {
    removeChildren()
    let container = document.createElement('div')
    container.id = 'css-container'
    container.style.position = 'absolute'
    container.style.zIndex = '-1'

    let canvasZone = document.getElementById("canvasZone")
    canvasZone.insertBefore(container, canvasZone.firstChild)

    let renderer = new CSS3DRenderer()
    container.appendChild(renderer.domElement)
    renderer.setSize(canvasZone.offsetWidth, canvasZone.offsetHeight)

    window.addEventListener('resize', () => {
        renderer.setSize(canvasZone.offsetWidth, canvasZone.offsetHeight)
    })
    return renderer
}

/**
 *here we set the properties of the iframe and the div around it, things like
 * width or height can be changed here
 */
var createCSSobject = function(mesh, scene, renderer, id, url) {
    let width = 1280
    let height = 720
    scene.onBeforeRenderObservable.add(() => {
        renderer.render(scene, scene.activeCamera)
    })
    var div = document.createElement( 'div' )
    div.id="contentDiv"
    div.style.width = width + 'px'
    div.style.height = height + 'px'
    div.style.backgroundColor = '#fff'
    div.style.zIndex = '1'
    div.style.display = "flex"
    div.style.flexDirection = "column"
    div.style.justifyContent = "flex-start"

    var iframe = document.createElement( 'iframe' )
    iframe.id = id
    iframe.style.width = width + 'px'
    iframe.style.height = height + 'px'
    iframe.style.border = '0px'
    iframe.src = url;
    div.appendChild(iframe)


    var CSSobject = new CSS3DObject(div, scene)
    CSSobject.position.copyFrom(mesh.getAbsolutePosition())
    CSSobject.rotation.y = -mesh.rotation.y
    CSSobject.scaling.copyFrom(mesh.scaling)

    let canvasZone = document.getElementById('canvasZone')

    canvasZone.appendChild(div)

}


function createMaskingScreen(maskMesh, scene) {
    let depthMask = new StandardMaterial('matDepthMask', scene)
    depthMask.backFaceCulling = false

    maskMesh.material = depthMask
    maskMesh.onBeforeRenderObservable.add(() => engine.setColorWrite(false))
    maskMesh.onAfterRenderObservable.add(() => engine.setColorWrite(true))

    // swap meshes to put mask first
    var mask_index = scene.meshes.indexOf(maskMesh)
    scene.meshes[mask_index] = scene.meshes[0]
    scene.meshes[0] = maskMesh
}


class CSS3DObject extends Mesh {
    constructor(element) {
        super()
        this.element = element
        this.element.style.position = 'absolute'
        this.element.style.pointerEvents = 'auto'
    }
}

class CSS3DRenderer {
    constructor() {

        this.cache = {
            camera: { fov: 0, style: '' },
            objects: new WeakMap()
        }

        var domElement = document.createElement( 'div' )
        domElement.style.overflow = 'hidden'

        this.domElement = domElement
        this.cameraElement = document.createElement( 'div' )
        this.isIE = (!!document['documentMode'] || /Edge/.test(navigator.userAgent) || /Edg/.test(navigator.userAgent))

        this.cameraElement.style.webkitTransformStyle = 'preserve-3d'
        this.cameraElement.style.transformStyle = 'preserve-3d'

        this.cameraElement.style.pointerEvents = 'none'

        domElement.appendChild(this.cameraElement)
    }


    setSize(width, height) {
        this.width = width
        this.height = height
        this.widthHalf = this.width / 2
        this.heightHalf = this.height / 2

        this.domElement.style.width = width + 'px'
        this.domElement.style.height = height + 'px'

        this.cameraElement.style.width = width + 'px'
        this.cameraElement.style.height = height + 'px'
    }

    epsilon(value) {
        return Math.abs(value) < 1e-10 ? 0 : value
    }

    getCameraCSSMatrix(matrix) {
        var elements = matrix.m

        return 'matrix3d(' +
            this.epsilon( elements[ 0 ] ) + ',' +
            this.epsilon( - elements[ 1 ] ) + ',' +
            this.epsilon( elements[ 2 ] ) + ',' +
            this.epsilon( elements[ 3 ] ) + ',' +
            this.epsilon( elements[ 4 ] ) + ',' +
            this.epsilon( - elements[ 5 ] ) + ',' +
            this.epsilon( elements[ 6 ] ) + ',' +
            this.epsilon( elements[ 7 ] ) + ',' +
            this.epsilon( elements[ 8 ] ) + ',' +
            this.epsilon( - elements[ 9 ] ) + ',' +
            this.epsilon( elements[ 10 ] ) + ',' +
            this.epsilon( elements[ 11 ] ) + ',' +
            this.epsilon( elements[ 12 ] ) + ',' +
            this.epsilon( - elements[ 13 ] ) + ',' +
            this.epsilon( elements[ 14 ] ) + ',' +
            this.epsilon( elements[ 15 ] ) +
            ')'
    }

    getObjectCSSMatrix(matrix, cameraCSSMatrix) {
        var elements = matrix.m;
        var matrix3d = 'matrix3d(' +
            this.epsilon( elements[ 0 ] ) + ',' +
            this.epsilon( elements[ 1 ] ) + ',' +
            this.epsilon( elements[ 2 ] ) + ',' +
            this.epsilon( elements[ 3 ] ) + ',' +
            this.epsilon( - elements[ 4 ] ) + ',' +
            this.epsilon( - elements[ 5 ] ) + ',' +
            this.epsilon( - elements[ 6 ] ) + ',' +
            this.epsilon( - elements[ 7 ] ) + ',' +
            this.epsilon( elements[ 8 ] ) + ',' +
            this.epsilon( elements[ 9 ] ) + ',' +
            this.epsilon( elements[ 10 ] ) + ',' +
            this.epsilon( elements[ 11 ] ) + ',' +
            this.epsilon( elements[ 12 ] ) + ',' +
            this.epsilon( elements[ 13 ] ) + ',' +
            this.epsilon( elements[ 14 ] ) + ',' +
            this.epsilon( elements[ 15 ] ) +
            ')'

        if (this.isIE) {
            return 'translate(-50%,-50%)' +
                'translate(' + this.widthHalf + 'px,' + this.heightHalf + 'px)' +
                cameraCSSMatrix +
                matrix3d;
        }
        return 'translate(-50%,-50%)' + matrix3d
    }

    renderObject(object, scene, camera, cameraCSSMatrix ) {
        if (object instanceof CSS3DObject) {
            var style
            var objectMatrixWorld = object.getWorldMatrix().clone()
            var camMatrix = camera.getWorldMatrix()
            var innerMatrix = objectMatrixWorld.m

            // Set scaling
            const objWidth = 4.8
            const objHeight = 3.6

            innerMatrix[0] *= 0.01 / objWidth
            innerMatrix[2] *= 0.01 / objWidth
            innerMatrix[5] *= 0.01 / objHeight

            // Set position from camera
            innerMatrix[12] = -camMatrix.m[12] + object.position.x
            innerMatrix[13] = -camMatrix.m[13] + object.position.y
            innerMatrix[14] = camMatrix.m[14] - object.position.z
            innerMatrix[15] = camMatrix.m[15] * 0.00001

            objectMatrixWorld = Matrix.FromArray(innerMatrix)
            objectMatrixWorld = objectMatrixWorld.scale(100)
            style = this.getObjectCSSMatrix( objectMatrixWorld, cameraCSSMatrix)
            var element = object.element
            var cachedObject = this.cache.objects.get( object )

            if ( cachedObject === undefined || cachedObject.style !== style ) {

                element.style.webkitTransform = style
                element.style.transform = style

                var objectData = { style: style }

                this.cache.objects.set( object, objectData )
            }
            if ( element.parentNode !== this.cameraElement ) {
                this.cameraElement.appendChild( element )
            }

        } else if ( object instanceof Scene ) {
            for ( var i = 0, l = object.meshes.length; i < l; i ++ ) {
                this.renderObject( object.meshes[ i ], scene, camera, cameraCSSMatrix )
            }
        }
    }

    render(scene, camera) {
        var projectionMatrix = camera.getProjectionMatrix()
        var fov = projectionMatrix.m[5] * this.heightHalf

        if (this.cache.camera.fov !== fov) {

            if (camera.mode === Camera.PERSPECTIVE_CAMERA ) {
                this.domElement.style.webkitPerspective = fov + 'px'
                this.domElement.style.perspective = fov + 'px'
            } else {
                this.domElement.style.webkitPerspective = ''
                this.domElement.style.perspective = ''
            }
            this.cache.camera.fov = fov
        }

        if ( camera.parent === null ) camera.computeWorldMatrix()

        var matrixWorld = camera.getWorldMatrix().clone()
        var rotation = matrixWorld.clone().getRotationMatrix().transpose()
        var innerMatrix = matrixWorld.m

        innerMatrix[1] = rotation.m[1]
        innerMatrix[2] = -rotation.m[2]
        innerMatrix[4] = -rotation.m[4]
        innerMatrix[6] = -rotation.m[6]
        innerMatrix[8] = -rotation.m[8]
        innerMatrix[9] = -rotation.m[9]

        matrixWorld = Matrix.FromArray(innerMatrix)

        var cameraCSSMatrix = 'translateZ(' + fov + 'px)' + this.getCameraCSSMatrix( matrixWorld )

        var style = cameraCSSMatrix + 'translate(' + this.widthHalf + 'px,' + this.heightHalf + 'px)'

        if (this.cache.camera.style !== style && !this.isIE ) {
            this.cameraElement.style.webkitTransform = style
            this.cameraElement.style.transform = style
            this.cache.camera.style = style
        }

        this.renderObject(scene, scene, camera, cameraCSSMatrix )
    }
}
export { createScene, removeScene };