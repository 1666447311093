// util to upload a file to s3.

import { Storage } from 'aws-amplify';

export class S3FileManager{

    constructor() {
        this.uploadIndicator = document.body.querySelector("upload-indicator");

        this.uploadItems = [];
    }


    getFolderContents(dir){
        return new Promise(resolve => {
            Storage.list(dir).then((res)=>{
                resolve(res);
            })
        })
    }


    /**
     * Actually this method can only delete single objects by key.
     * So if we have a direct link to a key, this will work. However,
     * if we want to remove a directory and all its content, we need
     * to iterate through the keys and delete one by one.
     * @param dir
     * @returns {Promise<unknown>}
     */
    deleteObjects(dir){
        return new Promise((resolve, reject) => {
            Storage.remove(dir)
                .then(result => resolve(result))
                .catch(err => reject(err));
        })
    }

    updateIndicator(){
        this.uploadIndicator.uploadItems = JSON.parse(JSON.stringify(this.uploadItems))
    }

    updateUploadItem(key, progress){
        for(let item of this.uploadItems){
            if(item.key === key){
                item.progress = Math.round(progress * 100);
            }
        }
        this.updateIndicator();
    }

    uploadFile(file, key){
        return new Promise((resolve)=>{

            this.uploadItems.push({
                key: key,
                progress: 0,
            })

            this.updateUploadItem(key, 0);

            const reference = this;
            Storage.put(key, file, {
                progressCallback(progress) {
                    const uploadProgress = progress.loaded / progress.total;
                    reference.updateUploadItem(key,uploadProgress);
                },
                contentType: file.type,
            }).then(() => {
                this.removeUploadItem(key)
                resolve();

            })
        })
    }

    removeUploadItem(key){
        for(let item of this.uploadItems){
            if(item.key === key){
                this.uploadItems.splice(this.uploadItems.indexOf(item),1);
                this.updateIndicator();
            }
        }
    }

    checkIfListExists(prefix){
        // eslint-disable-next-line no-async-promise-executor
        return new Promise((resolve)=>{
            Storage.list(prefix).then((res)=>{
                resolve(res);
            }, (err => {
                console.log("error for prefix:");
                console.log(err);
            }))
        })
    }


    getSignedURL(objectKey){
        return new Promise((resolve)=>{
            Storage.get(objectKey).then(url =>{
                resolve(url);
            });
        })
    }
}
