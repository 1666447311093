<template>
  <div class="main-content-container">
    <div class="apart">
      <h1>Tags ({{tags.length}})</h1>
    </div>
    <PrimaryBtn @click="openModal(true)" :val="$t('tag.add')"></PrimaryBtn>
    <MaterialTable @item-edited="editTag" @item-deleted="deleteTag" :inside-group-table="false"
                   :item-delete="itemDelete" :item-edit="itemEdit" :table-headers="tableHeaders" :table-rows="tableRows"></MaterialTable>
  </div>
  <Modal :val="this.$t('back')" @close-form="closeModal" :heading="$t('tag.add')" :modal-is-open=modalIsOpen>
    <AddTagForm :tag="this.selectedTag" @tag-updated="this.tagUpdated" :is-new-tag="this.isNewTag" @close-form="closeModal"></AddTagForm>
  </Modal>

  <Loader :show="this.showLoader"></Loader>
</template>

<script>

import Modal from "../components/modal/Modal";
import PrimaryBtn from "../components/buttons/PrimaryBtn";
import AddTagForm from "../components/admin/tags/AddTagForm";
import SweetAlerts from "../mixins/sweet-alerts/SweetAlerts";
import MaterialTable from "@/components/material-table/MaterialTable";
import Loader from "@/components/loader/Loader";

export default {
  components: {Loader, MaterialTable, AddTagForm, Modal, PrimaryBtn},
  mixins: [SweetAlerts],
  data: () => ({
    modalIsOpen: false,
    sortBy: 'tag',
    sortDirection: 'asc',
    selectedTag: {},
    isNewTag: false,
    itemEdit: true,
    itemDelete: true,
    showLoader: false,
  }),

  methods: {
    openModal(isNewTag){
      this.isNewTag = isNewTag;
      this.modalIsOpen = true;
    },
    closeModal(){
      this.modalIsOpen = false;
      this.selectedTag = {}
    },
    editTag(tag){
      this.selectedTag = tag;
      this.openModal(false);
    },

    //Updating tag
    tagUpdated(event){
      const payload = {
        "tag":event,
        "isNewTag":this.isNewTag,
      }
      this.showLoader = true
      this.$store.commit("updateTag",payload)

      if(this.isNewTag){
        this.showSuccessMessage('Tag ' + this.$t('addSuccess'));
      }else{
        this.showSuccessMessage('Tag ' + this.$t('swal.updateSuccess'));
      }
      this.showLoader = false;
    },

    // Deleting Tag
    deleteTag(item){
      this.showConfirmDialogue(this.$t('tag.delete'),
          this.$t('swal.confirmDelete')).then(()=>{
            this.showLoader = true;
            this.$store.dispatch("deleteTag",item).then(()=>{
              this.showSuccessMessage("Tag " + this.$t('deleteSuccess'));
              this.showLoader = false;
            });
      })
    },
    sort(s){
      if(s === this.sortBy) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      }
      this.sortBy = s;
    }
  },
  computed: {
    tags(){
      return this.$store.getters.tags
    },
    // creating the header for the table
    tableHeaders() {
      return [{label: "Tag"}, {label: this.$t('form.description')}]
    },

    // creating the rows for the table
    tableRows() {
      let data = [];

      // Creating rows for every tag available in tags-array.
      for (let tag of this.tagData) {
        const tagName = tag.tag;
        const tagDescription = tag.description;
        const tagId = tag.id;

        let cells = [];
        // pushing data into the cells
        cells.push(tagName, tagDescription);

        data.push({"id": tagId, "tag": tagName, "description": tagDescription, cells: cells});
      }
      return data;
    },

    tagData() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.tags.sort((p1, p2) => {
        let modifier = 1;
        if (this.sortDirection === 'desc') modifier = -1;
        if (p1[this.sortBy] < p2[this.sortBy]) return -1 * modifier;
        if (p1[this.sortBy] > p2[this.sortBy]) return modifier;
        return 0;
      });
    },
  },
  mounted() {
    this.$store.dispatch("getTags");
  },
}
</script>



<style scoped>
h1{
  margin-bottom: 24px;
}

.apart{
  display: flex;
  justify-content: space-between;
}

/*noinspection CssUnusedSymbol*/
.asc:after{
  content: "\25B2";
  font-size: 10px;
}

/*noinspection CssUnusedSymbol*/
.desc:after{
  content: "\25BC";
  font-size: 10px;
}

input{
  line-height: 48px;
}

th, td {
  text-align: left;
  padding: 10px;
}

th {
  border-bottom: 2px solid var(--darkgrey);
}

tr {
  border-bottom: 1px solid var(--brightgrey);
}

/*search filter styling*/
input {
  outline: none;
  border: none;
}
input[type=search] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  font-family: inherit;
  font-size: 100%;
}
input::-webkit-search-decoration,
input::-webkit-search-cancel-button {
  display: none;
}

input[type=search] {
  background: url(https://static.tumblr.com/ftv85bp/MIXmud4tx/search-icon.png) no-repeat 9px center;
  border: solid 1px var(--brightgrey);
  padding: 5px 10px 5px 32px;


  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;

  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  transition: all .5s;
}
input[type=search]:focus {
  border-color: var(--primary-color);
}

input:-moz-placeholder {
  color: #999;
}
input::-webkit-input-placeholder {
  color: #999;
}

input[type=search] {
  padding-right: 10px;
  color: black;
  cursor: pointer;
}
input[type=search]:hover {
  background-color: #fff;
}
input[type=search]:focus {
  color: #000;
  background-color: #fff;
  cursor: auto;
}
input:-moz-placeholder {
  color: transparent;
}
input::-webkit-input-placeholder {
  color: transparent;
}

</style>
