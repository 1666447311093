<template>
  <div>
    <button class="account-btn" @click="open = !open">
      <svg :class="{'active-blue' : open }" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#555555"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"/></svg>
      <span :class="{  'arrow-down-account' : open }"></span>
    </button>
    <div v-if="open" class="dropdown-account" v-click-outside="onClickOutside">
      <div v-for="item in dropdownAccount" :key="item" >
        <AccountOption :click-callback="item.clickCallback" :icon-src="item.iconSrc" :label="item.label"></AccountOption>
      </div>
  </div>
  </div>
</template>

<script>
import AccountOption from "./AccountOption";
export default {
  name: 'AccountDropdown',
  components: {AccountOption},
  props: {
    dropdownAccount: Array,
  },
  data(){
    return{
      /**
       * comment
       * @type Boolean
       */
      open: false,
    }
  },
  methods: {
    onClickOutside() {
      this.open = !this.open;
    }
  }
}
</script>

<style scoped>

  button{
    padding: 0;
    border: none;
    background-color: transparent;
  }

  .active-blue{
    fill: var(--primary-color);
  }

  .dropdown-account{
    position: absolute;
    right: 24px;
    top: 52px;
    background-color: var(--bright);
    border-radius: 4px;
    border: 1px solid var(--brightgrey);
    box-shadow: 0 0 5px rgb(169 191 203);
  }

  .arrow-down-account {
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid var(--primary-color);
    position: absolute;
    right: 28px;
    top: 49px;
    z-index: 1;
  }

  .account-btn svg:hover{
    fill: var(--primary-color);
  }
</style>
