<template>
  <div>
    <h3 style="margin-bottom: 10px;">{{this.$t('fileUpload')}}-Template</h3>
    <AppInput :has-error="titleIsEmpty" :value="stepObject.title" @input-changed="emitTitleChangedEvent" type="text" :label="this.$t('title')" :placeholder="this.$t('placeholderTitle')"></AppInput>
    <AppInput :value="stepObject.description" @input-changed="emitDescriptionChangedEvent" type="text" :label="this.$t('form.description')" :placeholder="this.$t('placeholderDescription')"></AppInput>

    <div class="image-wrapper">
      <div v-for="file in this.s3KeyList" :key="file">
        <div class="layout-container" @click="this.downloadFile(file.key)">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 0 24 24" width="40px" fill="#007AC2"><path d="M0 0h24v24H0z" fill="none"/><path d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z"/></svg>
          </div>
          <div class="wrapper">
            <span>{{getReadableFileName(file.key)}}</span>
            <span class="file-size">{{getFileSizeEnding(file.size)}}</span>
          </div>
        </div>
      </div>
    </div>
    <Loader :show="this.showLoader"></Loader>
  </div>
</template>

<script>

import AppInput from "../form/inputs/AppInput";
import {S3FileManager} from "@/mixins/s3-file-manager/s3-file-manager";
import Loader from "../loader/Loader";

export default {
  name: "FileEditor",
  components: {AppInput, Loader},

  props:{
    stepObject: Object,
    moduleId: String,
    courseId: String,
    label: String,
  },

  data(){
    return{
      s3Directory: '',
      s3KeyList: [],
      showLoader: false,
      titleIsEmpty: false,
    }
  },

  methods:{
    emitTitleChangedEvent(title){
      if(title.target.value === undefined || title.target.value === '' || title.target.value === null){
        this.titleIsEmpty = true;
      }else{
        this.titleIsEmpty = false;
        this.$emit("step-title-changed", title);
      }
    },

    emitDescriptionChangedEvent(description){
      this.$emit("step-description-changed", description);
    },

    listFileObjects(prefix) {
      return new Promise((resolve =>{
        const s3FileManager = new S3FileManager();
        s3FileManager.checkIfListExists(prefix).then(res => {
          resolve(res);
        })
      }))
    },

    downloadFile(key){
      const s3FileManager = new S3FileManager();
      s3FileManager.getSignedURL(key).then(url=>{
        //TODO: download
        window.open(url, "_blank", "");
      })
    },

    // Converts bytes into the corresponding filesize up to TB.
    getFileSizeEnding(bytes) {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
      if (bytes === 0) return 'n/a'
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
      if (i === 0) return `${bytes} ${sizes[i]})`
      return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
    },

    getReadableFileName(fileKey) {
      //then we split at "/" to get the last item of the string to make it more readable.
      const pieces = fileKey.split("/");
      return pieces[pieces.length - 1];

    }
  },

  mounted() {
    // this is our s3 directory where all image templates - if any - are stored.
    this.s3Directory = "course/" + this.courseId + "/" + this.moduleId + "/" + this.stepObject.id + "/";
    this.showLoader = true;
    this.listFileObjects(this.s3Directory).then(fileList => {
      this.showLoader = false;
      this.s3KeyList = fileList;
    })
  }
}
</script>

<style scoped>
.image-wrapper{
  max-width:100%;
  margin-top:24px;
  border:1px solid lightgrey;
  border-radius:4px;
  min-height:100px;
  padding: 24px;
}

.layout-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 0.8;
  padding: 10px 0;
}

.layout-container:hover{
  cursor: pointer;
  opacity: 1;
}

.wrapper{
  display: flex;
  flex-direction: column;
}


.file-size{
  color: var(--darkgrey);
  font-size: 12px;
}

input[type="file"] {
  display: none;
}


label{
  font-weight: 300;
}
</style>
