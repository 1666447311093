<template>
  <div class="question-card">
    <div v-if="!isLearningInfo">
      <input accept="application/pdf" type="file" @change="fileSelected" >
    </div>
    <div v-for="file in this.s3KeyList" :key="file">
      <div class="layout-container" @click="this.downloadFile(file.key)">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 0 24 24" width="40px" fill="#007AC2">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path
                d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z"/>
          </svg>
        </div>
        <div class="wrapper">
          <span>{{ getReadableFileName(file.key) }}</span>
          <span class="file-size">{{ bytesToSize(file.size) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {S3FileManager} from "@/mixins/s3-file-manager/s3-file-manager";

export default {
  name: "UserCertificateTemplate",
  props:{
    s3KeyListProp:Array,
    isLearningInfoProp:Boolean,
    course:Object,
    activeModule:Object,
    template:Object,
  },

  data(){
    return{
      isLearningInfo:this.isLearningInfoProp,
      s3KeyList:this.s3KeyListProp,
    }
  },

  methods:{

    downloadFile(key) {
      const s3FileManager = new S3FileManager();
      s3FileManager.getSignedURL(key).then(url => {
        //TODO: download
        window.open(url, "_blank", "");
      })
    },

    fileSelected(event){
      const file = event.target.files[0];
      const path = "course/" + this.course.id + "/" + this.activeModule.id + "/" + this.template.id + "/userInput/"
          + this.$store.getters.username + "/" + file.name;
      const s3FileManager = new S3FileManager();
      s3FileManager.uploadFile(file, path);

      setTimeout(()=>{
        this.listFileObjects(path).then((list)=>{
          this.s3KeyList = list;
        })
      },4000);
    },

    listFileObjects(prefix) {
      return new Promise((resolve => {
        const s3FileManager = new S3FileManager();
        s3FileManager.checkIfListExists(prefix).then(res => {
          resolve(res);
        })
      }))
    },

    getReadableFileName(fileKey) {
      //then we split at "/" to get the last item of the string to make it more readable.
      const pieces = fileKey.split("/");
      return pieces[pieces.length - 1];
    },

    bytesToSize(bytes) {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
      if (bytes === 0) return 'n/a'
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
      if (i === 0) return `${bytes} ${sizes[i]})`
      return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
    },
  },
  watch:{
    isLearningInfoProp(){
      this.isLearningInfo = this.isLearningInfoProp;
    },
    s3KeyListProp(){
      this.s3KeyList = this.s3KeyListProp;
    }
  }
}
</script>

<style scoped>
.question-card {
  padding: 40px;
  background-color: var(--brightgrey);
  border-radius: 4px;
  width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 65vh;
  overflow: auto;
}


.layout-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 0.8;
  padding: 10px 0;
}

.layout-container:hover {
  cursor: pointer;
  opacity: 1;
}

.wrapper {
  display: flex;
  flex-direction: column;
}


.file-size {
  color: var(--darkgrey);
  font-size: 12px;
}


@media screen and (max-width: 600px) {

  .question-card {
    width: 100%;
    height: 100%;
    position: unset;
    transform: none;
    padding: 20px;
  }

}
</style>