<template>
  <div v-if="notificationVisible" class="dropdown-element">
    <div class="initials-container">
      <InitialsAvatar color="#007AC2" :initials="initials"></InitialsAvatar>
    </div>
    <div @click="goToCourse(courseId)" class="notification-container">
    <button @click="notificationClose()" type="button" class="close-btn">
      <svg @click="deleteClickedNotification()" xmlns="http://www.w3.org/2000/svg" height="10px" viewBox="0 0 24 24" width="10px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
    </button>
      <div style="display: flex; align-items: center;">
        <h4>{{header}}</h4>
      </div>
      <div class="left-wrapper">
        <div>
          <span style="font-size: 14px">{{ notification }}</span>
        </div>
        <div style="display: flex; align-items: center;">
          <span class="date">{{ date }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InitialsAvatar from "@/components/avatar/InitialsAvatar";
import SweetAlerts from "@/mixins/sweet-alerts/SweetAlerts";


export default {
  name: "OptionNotification",
  components: {InitialsAvatar},
  mixins:[SweetAlerts],
  data() {
    return {
      notificationVisible: true
    }
  },
  methods: {
    notificationClose() {
      this.notificationVisible = false;
    },
    deleteClickedNotification() {
      const deleteNotification = {
        "id": this.id,
      }
      this.$store.dispatch("postRequest", {
        "param": "deleteNotification",
        "payload": deleteNotification
      }).then(() => {
        this.$emit("notification-deleted", this.id);
      });
    },

    goToCourse(courseId) {
      this.$store.dispatch("getRequest", "getCourseById&query=" + courseId).then((resp) => {
        const data = JSON.parse(resp.body);
        if(data) {
          if (data.course.publicationStatus === "10") {
            this.showErrorMessage(this.$t('courseInactive'))
          } else {
            this.$router.push({
              path: '/library/course/:id',
              name: 'Course Id',
              params: {id: courseId}
            });
          }
          this.deleteClickedNotification();
        }
      })
    },
  },

  props: {
    header: String,
    notification: String,
    date: Date,
    id: Number,
    initials:String,
    courseId: String,
  }
}
</script>

<style scoped>
.left-wrapper {
  display: flex;
  justify-content: space-between;
}

.date {
  font-size: 12px;
}

.close-btn{
  float: right;
  background-color: transparent;
  padding: 0;
  border: none;
  font-size: 10px;
}

.close-btn svg:hover{
  cursor: pointer;
  fill: var(--primary-color);
}

.dropdown-element:nth-child(2){
  border-top: 1px solid var(--brightgrey);
}

.dropdown-element{
  padding: 10px;
  border-bottom: 1px solid var(--brightgrey);
  border-left: 3px solid transparent;

}

.dropdown-element:hover{
  box-shadow: 0 0 5px rgb(169 191 203);
  cursor: pointer;
}

.initials-container {
  margin-top: 12px;
  position: absolute;
  width: 40px;
  height: 40px;
}

.notification-container{
  position: relative;
  margin-left: 56px;
}

</style>
