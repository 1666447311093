<template>
  <div>
    <h3 style="margin-bottom: 10px;">Text-Template</h3>
    <AppInput :value="template.title" @input-changed="emitTitleChangedEvent" type="text" :label="this.$t('title')" :placeholder="this.$t('placeholderTitle')"></AppInput>
    <AppInput :value="template.description" @input-changed="emitDescriptionChangedEvent" :label="this.$t('form.description')" :placeholder="this.$t('placeholderDescription')"></AppInput>
    <quill-editor
        @text-change = "textChangedCallback"
        ref="myQuillEditor"
        toolbar="full">
    </quill-editor>
  </div>
</template>

<script>
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import '@vueup/vue-quill/dist/vue-quill.bubble.css';
import AppInput from "../form/inputs/AppInput";

// noinspection JSUnusedGlobalSymbols
export default {
  components: {
    AppInput,
    QuillEditor
  },
  props:{
    template: Object,
  },
  data(){
    return{
      updatedTemplate: this.template,
    }
  },
  methods:{
    textChangedCallback(){
      const editorContent = this.$refs.myQuillEditor.getContents();
      this.updatedTemplate.editorData = editorContent;
      this.$emit('editor-changed', editorContent) // trigger event on the current instance
    },

    emitTitleChangedEvent(event){
      this.updatedTemplate.title = event.target.value;
    },

    emitDescriptionChangedEvent(event){
      this.updatedTemplate.description = event.target.value;
    }
  },

  mounted(){
    if(this.template.editorData) this.$refs.myQuillEditor.setContents(this.template.editorData.ops);
  },

  watch:{
    template(){
      this.updatedTemplate = this.template;
      if(this.template.editorData) this.$refs.myQuillEditor.setContents(this.template.editorData.ops);
    }
  }
}

</script>

<style>
  /*noinspection CssUnusedSymbol*/
  .ql-editor{
    height: 100%;
    min-height: 30vh;
    width: 100%;
    background-color: white;
  }
</style>
