<template>
  <Modal :val="this.$t('back')" @close-form="this.emitModalClosed" :heading="$t('learningProgress')" :modal-is-open=this.modalIsOpen>
    <div style="display: flex; align-items: center; justify-content: start">
      <initials-avatar color=#5c6bc0 :initials="this.initials"></initials-avatar>
      <div style="margin-left:10px">
        <span>{{this.friendlyName}}</span>
        <div class="summary-container">
          <div>
            <span style="margin-right:5px"> {{$t('courseCount')}} </span>
            <span> {{this.totalCourseCount}}</span>
          </div>
        </div>
        <div>
          <span :class="getStatusColor">{{this.userStatus}}</span>
          <a style="margin-left: 5px" v-if="this.userStatus.toLowerCase() === 'unconfirmed'" @click="this.resendInvitation">{{$t('resendInvitationLink')}}</a>
        </div>
      </div>
    </div>
    <div style="margin-top: 20px; display: flex; gap:10px">
      <div style="display: flex; gap:20px">
        <PrimaryBtn @click="this.assignCoursesCb" :val="$t('assignCourse')"></PrimaryBtn>
      </div>
      <div style="display: flex; gap:20px">
        <SecondaryBtn @click="this.showNotifications()" :val="$t('showNotifications')"></SecondaryBtn>
      </div>
    </div>

    <material-table @item-viewed="this.openCourseProgressInfo" :item-view="itemView"
                    :tableHeaders="tableHeaders" :tableRows="tableRows"></material-table>

  </Modal>

  <CourseProgressInfo @modal-closed="closeCourseProgressInfo" :user-courses="userCourses" :courseProgress="this.selectedCourseProgress"  :friendly-name="this.friendlyName"
                      :course="this.selectedCourse" :modal-is-open="progressInfoIsOpen" :selected-course-data="selectedCourseData"
                      :selected-person-id="selectedPersonId"></CourseProgressInfo>

  <Modal @close-form="this.showNotificationInfo = false" :heading="$t('Notifications') + ' ' + this.friendlyName"
         :modal-is-open=this.showNotificationInfo>
    <div>
      <material-table :tableHeaders="notificationTableHeaders" :tableRows="notificationTableRows">
      </material-table>
    </div>
  </Modal>

</template>

<script>

import Modal from "@/components/modal/Modal";
import InitialsAvatar from "@/components/avatar/InitialsAvatar";
import MaterialTable from "@/components/material-table/MaterialTable";
import CourseProgressInfo from "@/components/user/learning-info/CourseProgressInfo";
import PrimaryBtn from "@/components/buttons/PrimaryBtn";
import SweetAlerts from "../../../mixins/sweet-alerts/SweetAlerts";
import SecondaryBtn from "../../buttons/SecondaryBtn";

// noinspection JSUnusedGlobalSymbols
export default {
name: "LearningInfo",
  components: {
    SecondaryBtn,
    PrimaryBtn,
    CourseProgressInfo,
    MaterialTable,
    InitialsAvatar,
    Modal,
  },

  mixins:[SweetAlerts],

  props:{
    modalIsOpen: Boolean,
    selectedUserCourses: Array,
    courseOptions: Array,
    selectedPersonId: Array
  },

  data(){
    return {
      friendlyName: "",
      userCourses:[],
      progressInfoIsOpen: false,
      selectedCourse: null,
      selectedCourseProgress: 0,
      selectedCourseData:null,
      assignedCourse: null,
      assignedCoursesOptions: Array,
      itemView: true,
      userStatus: "...",
      showNotificationInfo: false,
      userNotificationData: []
    }
  },

  computed:{

    getStatusColor(){
      switch (this.userStatus.toLowerCase()){
        case "unconfirmed":
          return "orange";
          case "confirmed":
            return "green";
        default:
          return "black";
      }
    },

    notificationTableRows(){
      let tableRows = [];

      for (let entry of this.userNotificationData){
        let tableRow = [];
        tableRow.push(new Date(entry.creationTimestamp * 1).toLocaleDateString());
        tableRow.push(entry.notification.message_de);

        tableRows.push({cells:tableRow});
      }

      return tableRows;
    },

    tableRows(){
      let data = [];

      for (let object of this.userCourses){
        const courseObject = object["courses"];
        const learningReport = object["learningStatus"];
        //const totalCourseSteps
        let totalSteps = 0;

        for (let module of courseObject.courseModules) {
          totalSteps = totalSteps + module.courseTemplates.length;
        }

        let progressInPercent = Math.round(learningReport.learningProgress / totalSteps * 100).toFixed(0);
        if(progressInPercent > 100) progressInPercent = 100;

        let cells = [];

        // each user course object contains information when the course was
        // subscribed to and when it was finished (in case it was)
        let subscriptionTimestamp = "";
        if (learningReport["subscriptionTimestamp"]){
          subscriptionTimestamp = new Date(learningReport["subscriptionTimestamp"] * 1).toLocaleDateString();
        }

        let completionTimestamp = "";
        if (learningReport["completionTimestamp"]){
          completionTimestamp = new Date(learningReport["completionTimestamp"]* 1).toLocaleDateString();
        }

        let attemptsLeft = learningReport["remainingRestarts"] ? learningReport["remainingRestarts"] : "-"
        cells.push(courseObject.title, learningReport.learningProgress, progressInPercent + "%",  subscriptionTimestamp,
            completionTimestamp,attemptsLeft ,courseObject["isCompulsory"]? this.$t('yes'): this.$t('no'));
        data.push({"id": courseObject.id,"title":courseObject.title, cells:cells});
      }

      return data;
    },

    initials(){
      let inits = "";
      if (this.friendlyName){
        inits = this.friendlyName.substring(0,2).toUpperCase();
      }
      return inits;
    },

    tableHeaders(){
      return [
          {label:this.$t('title')}, {label: this.$t('currentLearnStep')}, {label: this.$t('learningProgress')},
          {label:this.$t('subscriptionDate')}, {label:this.$t('completionDate')},{label:this.$t('attempts')}, {label: this.$t('mandatoryCourse')}
      ];
    },

    notificationTableHeaders(){
      return [
        {label:this.$t('creationDate')}, {label: this.$t('notification')},
      ];
    },

    totalCourseCount(){
      return this.userCourses ? this.userCourses.length : 0;
    }
  },

  methods:{

    emitModalClosed(){
      this.$emit("modal-closed");
    },

    /**
     * If a user has not confirmed the signup email after a certain amount of time,
     * the link becomes invalid. If this is the case, we can resend the confirmation
     * code.
     */
    resendInvitation(){
      this.showLoader = true;
      this.$store.dispatch("postRequest",{
        "param":"resendConfirmationCode",
        "payload":{
          "userId": this.selectedPersonId,
        }
      }).then(() => {
        this.showLoader = false;
        this.showSuccessMessage(this.$t('resentInvitationLinkSuccess'))
      })
    },

    showNotifications(){
      this.getUserNotifications().then(notifications => {
          this.userNotificationData = notifications;
          this.showNotificationInfo = true;
      });

    },

    assignCoursesCb(){
      this.selectAssignedCoursePrompt().then(courseId => {
        this.$emit("course-assigned", {courseId: courseId, id: this.selectedPersonId});
      });
    },

    selectAssignedCoursePrompt(){
      return new Promise((resolve)=>{
        this.$swal({
          title: this.$t('selectCourse'),
          input: 'select',
          inputOptions: this.assignedCoursesOptions,
          inputPlaceholder: this.$t('placeholderSelectCourse'),
          confirmButtonColor: "#007AC2",
          confirmButtonText: this.$t('confirm'),
          showCancelButton: true,
          cancelButtonText: this.$t('button.cancel'),
        }).then((res) => {
          if (res.isConfirmed && res.value){
            resolve(res.value);
          }
        });
      })
    },

    closeCourseProgressInfo(){
      this.progressInfoIsOpen = false;

      // if the course progress info gets closed, we close the learning info modal, too.
      // the reason behind this is, that when we e.g. restart a course, we need to connect
      // the data to the store first, so all updates populate.
      //TODO: connect the data to the store so that updates popuplate and
      // TODO: we do not need to close it for reloading purposes.
      this.emitModalClosed();
    },

    getUserNotifications(){
      return new Promise((resolve)=>{
        this.$store.dispatch("getRequest","getUserNotifications&query=" +  this.selectedPersonId).then(resp => {
          const data = JSON.parse(resp.body);
          resolve(data);
        });
      })
    },

    getUserStatus(){
      return new Promise((resolve)=>{
        this.$store.dispatch("getRequest","getUserStatus&query=" +  this.selectedPersonId).then(resp => {
          const data = JSON.parse(resp.body);
          resolve(data);
        });
      })
    },

    openCourseProgressInfo(item){
      const id = item.id;
      const progress = item.cells[1];

      this.$store.dispatch("getRequest","getCourseById&query=" +  id).then(resp => {
        const data = JSON.parse(resp.body);
        this.selectedCourseProgress = progress;
        this.selectedCourse = data.course;
        this.selectedCourseData = data
        this.progressInfoIsOpen = true;
      });
    }
  },

  watch:{
    async selectedUserCourses(){
      if (this.selectedUserCourses["userFriendlyName"]){
        this.friendlyName = this.selectedUserCourses["userFriendlyName"];
        this.userCourses = this.selectedUserCourses["courseDetails"];

        this.userStatus = await this.getUserStatus();
      }
    },

    courseOptions(){
      if (this.courseOptions){
        const options = [];
        for (let option of this.courseOptions){
          options.push({
            "title":option.courseTitle,
            "id":option.courseId,
          })
        }

        const optionsMap = {};

        options.map((o)=>{
          optionsMap[o.id] = o.title;
        })

        this.assignedCoursesOptions = optionsMap;
      }
    }
  },

}

</script>

<style scoped>
/*noinspection CssUnusedSymbol*/
.green{
  color:green;
}

/*noinspection CssUnusedSymbol*/
.orange{
  color:orange;
}
a{
  text-decoration: none;
  color: var(--primary-color);
}

</style>
