<template>
  <button class="notify-btn" @click="showNotifications">
    <svg :class="{  'color-clicked' : open }" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24"
         width="24px" fill="#555555">
      <path d="M0 0h24v24H0z" fill="none"/>
      <path
          d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 16.5c-.83 0-1.5-.67-1.5-1.5h3c0 .83-.67 1.5-1.5 1.5zm5-2.5H7v-1l1-1v-2.61C8 9.27 9.03 7.47 11 7v-.5c0-.57.43-1 1-1s1 .43 1 1V7c1.97.47 3 2.28 3 4.39V14l1 1v1z"/>
    </svg>
    <span v-if="this.hasNotifications" class="notification-counter">{{this.notificationsCounter}}</span>
    <span :class="{  'arrow-down' : open }"></span>
  </button>
  <div class="dropdown-content-container" v-if="open" v-click-outside="onClickOutside">
    <div class="notification-header-wrapper">
      <span class="notify-text" @click="clearNotifications">{{ $t('notifications.markAsRead') }}</span>
      <h4 class="notification-header">{{ $t('notifications') }}</h4>
    </div>
    <div v-for="item in notifications" :key="item">
      <div>
        <OptionNotification v-if="$i18n.locale === 'de'" :header="item.domain"
                            :notification="item.notification.message_de" :initials="item.notification.initials"
                            :date="item.creationTimestamp" :id="item.id" :courseId="item.notification.courseId" @notification-deleted="getNotifications"></OptionNotification>
        <OptionNotification v-else :header="item.domain" :notification="item.notification.message_en" :initials="item.notification.initials"
                            :date="item.creationTimestamp" :id="item.id" :courseId="item.notification.courseId" @notification-deleted="getNotifications"></OptionNotification>
      </div>
    </div>
  </div>
</template>

<script>

import OptionNotification from "./OptionNotification";
import SweetAlerts from "@/mixins/sweet-alerts/SweetAlerts";

export default {
  name: 'DropDownNotification',
  components: {OptionNotification},
  mixins: [SweetAlerts],
  props: {
    dropdownNotify: Array,
  },
  data() {
    return {
      /**
       * comment
       * @type Boolean
       */
      open: false,
      notifications: [],
      hasNotifications: false,
      notificationsCounter: 0
    }
  },

  methods: {
    // Shows a Sweet-Alert if notification-array is empty
    showNotifications() {
      if (this.notifications.length === 0) {
        this.showSuccessMessage(this.$t('noNotifications'));
      } else {
        this.open = !this.open;
      }
    },
    // Clears all current notifications and closes the notifications dropdown-menu.
    clearNotifications() {
          const deleteAllNotifications= {
            "userId": this.$store.getters.username
          }
          this.$store.dispatch("postRequest",{
            "param":"deleteAllNotifications",
            "payload":deleteAllNotifications
          }).then(() => {
             this.open = !this.open;
             this.notifications = [];
             this.hasNotifications = false;
           });
    },

    // Shows current notification number
    showNotificationCount(){
      // Do we have notifications ?
      if(this.notifications.length > 0){
        // Yes, now we set the current counter of the notifications and format the timestamp
        this.hasNotifications = true;
        this.notificationsCounter = this.notifications.length
        for (let item of this.notifications) {
          // Checking if creationTimestamp is a long number (over 100000), than we format, otherwise it would format everytime we open DropDownNotifications.
          if (item.creationTimestamp > 100000) {
            const milliseconds = parseInt(item.creationTimestamp) ;
            item.creationTimestamp = new Date(milliseconds).format('DD/MM/YYYY');
          }
        }
      }else{
        // No we don't have notifications, so we don't show the counter and close the notifications-dropdown.
        this.hasNotifications = false
        this.open = false;
      }
    },

    getNotifications(){
      if (this.$store.getters.username) {
        this.$store.dispatch("getRequest", "getMyNotifications").then((resp) => {
          this.notifications = JSON.parse(resp.body);
          this.showNotificationCount();
        })
      }
    },

    onClickOutside() {
      this.open = !this.open;
    }
  },

  mounted() {
    // Gets current notifications and updates our notifications every minute.

      this.getNotifications();
      setInterval(() => {
        this.getNotifications();
      }, 60000);
    }
}

</script>

<style scoped>

.notification-header-wrapper{
  display: flex;
  flex-direction: row-reverse;
  justify-content:space-between;
  padding: 10px;
  border-bottom: 1px solid var(--brightgrey);
}

.notification-counter{
  color:red;
  font-size: 12px;
  /*font-weight: bold;*/
}

.show-notifications-link a {
  color: var(--primary-color);
  text-decoration: none;
  font-size: 14px;
}

a:hover {
  text-decoration: underline;
}

.dropdown-content-container {
  position: absolute;
  right: 24px;
  background-color: var(--bright);
  border-radius: 4px;
  border: 1px solid var(--brightgrey);
  width: 500px;
  -webkit-box-shadow: 0 0 5px rgb(169, 191, 203);
  -moz-box-shadow: 0 0 5px rgb(169, 191, 203);
  box-shadow: 0 0 5px rgb(169, 191, 203);
}

.notify-text:hover {
  color: var(--primary-color);
  cursor: pointer;
}

.notify-btn {
  background-color: transparent;
  padding: 0;
  border: none;
}

.notify-btn svg:hover {
  fill: var(--primary-color);
  cursor: pointer;
}

.color-clicked {
  fill: var(--primary-color)
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid var(--primary-color);
  position: absolute;
  right: 76px;
  top: 49px;
  z-index: 1;
}


@media screen and (max-width: 600px) {
  .dropdown-content-container {
    z-index: 12;
    right: 0;
    top: 62px;
    width: 100%;
  }

  .notification-header-wrapper{
    flex-direction: column-reverse;
  }
}


</style>
