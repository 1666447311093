<template>
  <div>
    <h3 style="margin-bottom: 10px;">{{this.$t('imageMarker')}}-Template</h3>
    <AppInput :has-error="titleIsEmpty" :value="stepObject.title" @input-changed="emitTitleChangedEvent" type="text"
              :label="this.$t('title')" :placeholder="this.$t('placeholderTitle')"></AppInput>
    <AppInput :value="stepObject.description" @input-changed="emitDescriptionChangedEvent" type="text"
              :label="this.$t('form.description')" :placeholder="this.$t('placeholderDescription')"></AppInput>
    <AppInput :value="this.maxAttempts" @input-changed="emitMaxAttemptsChangedEvent" type="number"
              :label="this.$t('maxAttempts')" :placeholder="this.$t('placeholderMaxAttempts')"></AppInput>

    <div class="form-wrapper-file">
      <label>{{ $t('uploadFile') }}</label>
      <input accept="image/jpeg" type="file" @change="imageFileUploaded">
    </div>
    <div>
      <button v-if="this.imageSrc !== ''" class="reset-button" @click="resetSelectField">{{ $t('resetMarker') }}</button>
      <button v-if="this.imageSrc !== ''" class="drag-button" :style="computedDragButtonColor" @click="enableDrag">{{$t('enableDrag')}}</button>
    </div>

    <div class="image-wrapper">
      <div style="position: relative">
        <img v-if="this.imageSrc !== ''" ref="image" :style="computedImgSize" :src="imageSrc" :alt="imageSrc">
        <div v-if="this.imageSrc !== ''" ref="selectDiv" class="selectBorder" :style="computedLeft" @mousedown="mousedown"
             @mouseup="mouseup" @mousemove="mousemove">
        </div>
      </div>
    </div>
    <Loader :show="this.showLoader"></Loader>
  </div>

</template>

<script>

import AppInput from "../form/inputs/AppInput";
import {S3FileManager} from "@/mixins/s3-file-manager/s3-file-manager";
import Loader from "../loader/Loader";

export default {
  name: "ImageMarker",
  components: {AppInput, Loader},

  props: {
    stepObject: Object,
    moduleId: String,
    courseId: String
  },

  data() {
    return {
      imageSrc: '',
      s3Directory: '',
      showLoader: false,
      selectField: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        active: false
      },
      offsetX: undefined,
      offsetY: undefined,
      cursor: 'default',
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
      imgHeight: 100,
      imgWidth: 100,
      enableDragging:false,
      color: '',
      textColor: '',
      maxAttempts: this.stepObject.editorData.maxAttempts ? this.stepObject.editorData.maxAttempts : 1,
      titleIsEmpty: false,
    }
  },

  computed: {
    computedLeft() {
      return {
        left: this.selectField.left + 'px',
        top: this.selectField.top + 'px',
        right: this.selectField.right + 'px',
        bottom: this.selectField.bottom + 'px',
      }
    },
    computedImgSize(){
      return{
        height: this.imgHeight + '%',
        width: this.imgWidth + '%',
      }
    },
    computedDragButtonColor(){
      if(this.enableDragging){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.color = '#007AC2';
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.textColor = 'white';

      }else{
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.color = 'white';
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.textColor = '#007AC2';
      }
      return{
      }
    }
  },

  methods: {

    emitTitleChangedEvent(title) {
      if (title.target.value === undefined || title.target.value === '' || title.target.value === null) {
        this.titleIsEmpty = true;
      } else {
        this.titleIsEmpty = false;
        this.$emit("step-title-changed", title);
      }
    },

    enableDrag(){
      this.enableDragging =! this.enableDragging;
    },

    mousedown(e) {
      // We check if dragging is enabled
      if(this.enableDragging){
        this.selectField.active = true;
        this.offsetX = e.offsetX;
        this.offsetY = e.offsetY;
        this.cursor = 'pointer';
      }
    },

    mouseup() {
      // We check if dragging is enabled
      if(this.enableDragging) {
        this.selectField.active = false;
        this.cursor = 'default';

        // We get the current image height and width
        let x = this.$refs.image.clientWidth;
        let y = this.$refs.image.clientHeight;

        // We get the current height and width of the div
        let selectedWidth = this.$refs.selectDiv.clientWidth;
        let selectedHeight = this.$refs.selectDiv.clientHeight;

        // We get the position of the div in percentage (currently bottom right is 0/0)
        let percentageStart = (x - this.selectField.left) / x * 100;
        let percentage2Start = (y - this.selectField.top) / y * 100;
        let percentageEnd = (x - this.selectField.left - selectedWidth) / x * 100;
        let percentage2End = (y - this.selectField.top - selectedHeight) / y * 100;

        // Now the percentage start at the top left with 0/0 and only has 2 decimals.
        let currentSelectedWidth = (100 - percentageStart).toFixed(2);
        let currentSelectedHeight = (100 - percentage2Start).toFixed(2);
        let currentSelectedWidthEnd = (100 - percentageEnd).toFixed(2);
        let currentSelectedHeightEnd = (100 - percentage2End).toFixed(2);

        // Creating dimensions-object of the selected div
        const dimensions = {
          "widthStartPercentage": currentSelectedWidth,
          "widthEndPercentage": currentSelectedWidthEnd,
          "heightStartPercentage": currentSelectedHeight,
          "heightEndPercentage": currentSelectedHeightEnd,
        }

        this.emitMarkerListChanged(dimensions);
      }
    },

    mousemove(e) {
      // We check if the selected div is on active and if that's correct,
      // we update the position of the div with the current position of the mouse
      if (this.selectField.active) {
          let x = e.offsetX;
          let y = e.offsetY;
          let dx = x - this.offsetX;
          let dy = y - this.offsetY;
          this.selectField.left += dx;
          this.selectField.top += dy;
      }
    },

    // Resetting the div
    resetSelectField(){
      this.selectField.top = 0;
      this.selectField.left = 0;
      this.selectField.active = false;
    },

    emitDescriptionChangedEvent(description) {
      this.$emit("step-description-changed", description);
    },

    emitMaxAttemptsChangedEvent(maxAttempts){
      this.maxAttempts = maxAttempts.target.value;
      this.$emit("max-attempts-changed",this.maxAttempts);
    },

    emitMarkerListChanged(dimensions){

      const markerObject = {
        "dimensions": dimensions,
        "maxAttempts": this.maxAttempts,
      }
      this.$emit("editor-changed", markerObject);
    },

    checkURLForValidImage(prefix) {
      return new Promise((resolve => {
        const s3FileManager = new S3FileManager();
        s3FileManager.checkIfListExists(prefix).then(res => {
          resolve(res.length > 0);
        })
      }))
    },

    setDefaultImage() {
      this.imageSrc = "";
    },

    setInstructionImage() {
      const s3FileManager = new S3FileManager();
      s3FileManager.getSignedURL("course/" + this.courseId + "/" + this.moduleId + "/" + this.stepObject.id + "/image.jpeg").then(url => {
        this.imageSrc = url;
      })
    },

    async imageFileUploaded(event) {
      const file = event.target.files[0];
      const path = "course/" + this.courseId + "/" + this.moduleId + "/" + this.stepObject.id + "/image.jpeg";
      this.imageSrc = URL.createObjectURL(file);
      const s3FileManager = new S3FileManager();
      await s3FileManager.uploadFile(file, path).then(()=>{
        this.resetSelectField();
        this.setInitialCoordinates()
      });

    },

    // after uploading an image the initial coordinates are saved from 0/0.
    setInitialCoordinates(){
      this.enableDragging = true;
      this.mouseup();
      this.enableDragging = false;
    },
  },


  mounted() {
    // this is our s3 directory where all image templates - if any - are stored.
    this.s3Directory = "course/" + this.courseId + "/" + this.moduleId + "/" + this.stepObject.id;
    this.showLoader = true;
    this.checkURLForValidImage(this.s3Directory).then(fileExists => {
      this.showLoader = false;
      if (fileExists) {
        this.setInstructionImage();
      } else {
        this.setDefaultImage();
      }
    })
  },

}
</script>

<style>
.image-wrapper {
  max-width: 100%;
  margin-top: 24px;
  border: 1px dashed lightgrey;
  border-radius: 5px;
  min-height: 100px;
}

.selectBorder {
  position: absolute;
  background-color: transparent;
  border: 2px solid blue;
  width: 100px;
  height: 100px;
  top: v-bind('top');
  left: v-bind('left');
  right: v-bind('right');
  bottom: v-bind('bottom');
  cursor: v-bind('cursor');
  resize: both;
  display:block;
  overflow: hidden;
}
.drag-button{
  background-color:v-bind(color);
  border-radius: 4px;
  border: 1px solid var(--primary-color);
  color: v-bind(textColor);
  margin-right: 10px;
  margin-left: 10px;
}

.reset-button{
  border-radius: 4px;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  background-color: var(--bright);
  margin-right: 10px;
  margin-left: 10px;
}
</style>
