<template>
  <div class="news-card-container">
    <div>
      <img style="width: 100%;
                  height: 150px;
                  border-top-right-radius: 5px;
                  border-top-left-radius: 5px;
                  filter: blur(2px);
                  opacity: 0.9;
                  object-fit: cover;
                  -webkit-filter: blur(2px);" :src=this.post.iconSrc alt="">
      <img style="left: 50%;
                  width: 100%;
                  transform: translate(-50%);
                  position: absolute;
                  top: 0;
                  height: 150px;
                  object-fit: contain;" :src=this.post.iconSrc alt="">
      <div class="dropdown-container">
        <AppDropdown v-if="isAdmin" :dropdown-card-list="computedDropDownList"/>
      </div>
    </div>
    <div class="padding">
      <div  class="feed-header">
        <h3>{{ post.post.title }}</h3>
      </div>
      <div class="description-container">
        <p>{{ post.post.description }}</p>
      </div>
      <div class="btn-container">
        <div class="btn-wrapper-left">
          <h6>{{ post.post.creator  }}</h6>
        </div>
        <div @click=articleClicked() class="btn-wrapper-right">
          <button type="button">{{ $t("card.readmore") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import AppDropdown from "@/components/dropdown/AppDropdown";

export default {
  name:'NewsCard',
  components: {AppDropdown},
  props: {
    post: {}
  },
  data(){
    return{
      dropdownCardList:[],
      isAdmin: false,
    }
  },
  computed:{
    computedDropDownList(){
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.dropdownCardList = [{
        label: this.$t("editPost"),
        iconSrc:  require('/public/img/icons/dropdown-icons/edit_black_24dp.svg'),
        clickCallback: () => this.requestEditPost()
      },
        {
          label: this.$t("deletePost"),
          iconSrc:  require('/public/img/icons/dropdown-icons/delete_black_24dp.svg'),
          clickCallback: () => this.requestDeletePost()
        },]
    }
  },
  methods:{
    articleClicked(){
      this.$emit("articleClicked", this.post);
    },

    requestEditPost(){
      this.$emit("edit-post", this.post);
    },

    requestDeletePost(){
      this.$emit("delete-post", this.post);
    }
  },

  mounted(){
      if (this.$store.getters.role.toLowerCase() === "admin") this.isAdmin = true;
  }
}
</script>

<style scoped>
  .news-card-container{
    height: 360px;
    margin: 0;
    border: 1px solid var(--brightgrey);
    -webkit-box-shadow: 0 0 5px rgb(169, 191, 203);
    -moz-box-shadow: 0 0 5px rgb(169, 191, 203);
    box-shadow: 0 0 5px rgb(169, 191, 203);
    border-radius:8px;
    position: relative;
    z-index: 1;
    background-color: var(--bright);
  }

  .description-container{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .padding{
    padding: 20px;
  }

  .feed-header h6{
    margin-top: 3px;
  }

  .feed-header{
    margin-bottom: 20px;
  }

  .feed-header h3{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: auto;
    text-overflow: ellipsis;
  }

  .btn-wrapper-left{
    position: absolute;
    left: 20px;
    bottom: 20px;
  }

  .btn-wrapper-right{
    position: absolute;
    right: 20px;
    bottom: 20px;
  }

  .dropdown-container{
    position: absolute;
    top:5px;
    right:5px;
  }


  button{
    background-color: transparent;
    color: var(--primary-color);
    border: none;
    padding: 0;
  }

  button:hover{
    cursor: pointer;
    text-decoration: underline;
  }

</style>
