<template>
  <div class="main-content-container">
    <h1 style="margin-bottom: 24px;">{{this.$t('footer.about')}}</h1>
    <div class="wrapper">
      <span>{{this.$t('visitWebsite')}}</span>
      <a target="_blank" :href="this.aboutLink">
        <img :src="this.imgLink" alt="Logo">
      </a>
    </div>
  </div>
</template>

<script>

import {Config} from "../config";

export default{
  name:"About",
  data(){
    return{
      aboutLink: Config.AboutUs.link,
      imgLink: Config.Logo.link
    }
  }
}

</script>

<style scoped>

img:hover{
  cursor: pointer;
  opacity: 0.8;
}

.wrapper{
  display: flex;
  gap:20px;
  align-items: center;
}

img{
  width: 100px;
  height: auto;
}

</style>
