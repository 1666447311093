<template>
  <h3 style="margin-bottom: 10px;">Audio-Template</h3>
  <AppInput :has-error="titleIsEmpty" :value="stepObject.title" @input-changed="emitTitleChangedEvent" type="text" :label="this.$t('title')" :placeholder="this.$t('placeholderTitle')"></AppInput>
  <AppInput :value="stepObject.description" @input-changed="emitDescriptionChangedEvent" type="text" :label="this.$t('form.description')" :placeholder="this.$t('placeholderDescription')"></AppInput>
  <div class="form-wrapper-file">
    <label>{{ $t('uploadFile') }}</label>
    <input accept="audio/mpeg" type="file" @change="audioFileUploaded">
  </div>

  <Loader :show="this.showLoader"></Loader>
  <div class="audio-container">
  <audio tabindex="0" id="audio" controls preload="auto" :src="this.audioSrc">
  </audio>
  </div>

</template>

<script>
import AppInput from "@/components/form/inputs/AppInput";
import {S3FileManager} from "@/mixins/s3-file-manager/s3-file-manager";
import Loader from "@/components/loader/Loader";
export default {
  name:"AudioEditor",
  components: {Loader, AppInput},

  props:{
    stepObject: Object,
    moduleId: String,
    courseId: String,
  },

  data:() => ({
    audioSrc: '',
    showLoader: false,
    s3Directory: '',
    titleIsEmpty: false,
  }),


  methods:{
    emitTitleChangedEvent(title){
      if(title.target.value === undefined || title.target.value === '' || title.target.value === null){
        this.titleIsEmpty = true;
      }else{
        this.titleIsEmpty = false;
        this.$emit("step-title-changed", title);
      }
    },

    emitDescriptionChangedEvent(description){
      this.$emit("step-description-changed",description);
    },

    setDefaultAudio(){
      this.audioSrc = "";
    },

    setInstructionAudio(){
      const s3FileManager = new S3FileManager();
      s3FileManager.getSignedURL("course/" + this.courseId + "/" + this.moduleId + "/" + this.stepObject.id + "/audio.mp4").then(url=>{
        this.audioSrc = url;
      })
    },

    checkURLForValidAudio(prefix) {
      return new Promise((resolve =>{
        const s3FileManager = new S3FileManager();
        s3FileManager.checkIfListExists(prefix).then(res => {
          resolve(res.length > 0);
        })
      }))
    },

    audioFileUploaded(event){
      const path = "course/" + this.courseId + "/" + this.moduleId + "/" + this.stepObject.id + "/audio.mp4";
      const file = event.target.files[0];
      this.audioSrc = URL.createObjectURL(file);
      const s3FileManager = new S3FileManager();
      s3FileManager.uploadFile(file, path);
    }
  },
  mounted() {
    // this is our s3 directory where all image templates - if any - are stored.
    this.s3Directory = "course/" + this.courseId + "/" + this.moduleId + "/" + this.stepObject.id;
    this.showLoader = true;
    this.checkURLForValidAudio(this.s3Directory).then(fileExists => {
      this.showLoader = false;
      if (fileExists){
        this.setInstructionAudio();
      } else {
        this.setDefaultAudio();
      }
    })

  }
}


</script>

<style scoped>

.audio-container{
  margin-top: 10px;

}

</style>