import router from "@/router";
import {Auth} from "aws-amplify";
import {Config} from "../../config";

let fetchLink = '';

const actions = {
    postRequest(context, payload){
        if (Config.Names.domain.toLowerCase() !== "gleason"){
            fetchLink = "https://1efp9svq09.execute-api.eu-central-1.amazonaws.com/dev"
        } else if(context.rootGetters.company === "Gleason GSAG"){
            fetchLink = "https://5urbhvsgwg.execute-api.eu-central-1.amazonaws.com/prod"
        }else{
            fetchLink = "https://1efp9svq09.execute-api.eu-central-1.amazonaws.com/dev"
        }
        return new Promise((resolve)=>{
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json","Authorization": context.rootGetters.jwtToken,
                    "username": context.rootGetters.username},
                body: JSON.stringify({ identifier: payload.param, payload:payload.payload})
            };
            fetch(fetchLink, requestOptions)
                .then(resp => {
                    if(resp.status!==200) {
                        // if we receive a 401 response, it is likely that something with
                        // the token went wrong. So we require a re-login.
                        if (!window.location.href.includes("login") && resp.status.toString() === "401"){
                            alert("Error retrieving security credentials in post request. Please login again.");
                            Auth.signOut();
                            router.push({
                                name: 'Login',
                            }).then(()=>{
                                // TODO: maybe logout in addition?
                            })
                        }
                    } else {
                        resolve(resp.json());
                    }
                })
        });
    },
    getRequest(context,param){
        if (Config.Names.domain.toLowerCase() !== "gleason"){
            fetchLink = "https://1efp9svq09.execute-api.eu-central-1.amazonaws.com/dev"
        } else if(context.rootGetters.company === "Gleason GSAG"){
            fetchLink = "https://5urbhvsgwg.execute-api.eu-central-1.amazonaws.com/prod"
        }else{
            fetchLink = "https://1efp9svq09.execute-api.eu-central-1.amazonaws.com/dev"
        }
        return new Promise((resolve)=>{
            const requestOptions = {
                method: "GET",
                headers: {
                    "Authorization": context.rootGetters.jwtToken,
                    "username": context.rootGetters.username
                },
            };

            fetch(fetchLink + "?param=" + param, requestOptions)
                .then(resp => {
                    if(resp.status!==200) {
                        // if we receive a 401 response, it is likely that something with
                        // the token went wrong. So we require a re-login.
                        if (!window.location.href.includes("login") && resp.status.toString() === "401"){
                            alert("Error retrieving security credentials in get request. Please login again.");
                            Auth.signOut();
                            router.push({
                                name: 'Login',
                            }).then(()=>{
                                // TODO: maybe logout in addition?
                            })
                        }
                    } else {
                        resolve(resp.json());
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        });
    }

}

const requestModule = {
    actions,
}

export default requestModule;