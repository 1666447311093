<template>
  <div class="main-content-container">
    <h1>{{ $t('nav.calendar') }}</h1>
    <div>
      <vue-cal v-if="$i18n.locale === 'de'" :editable-events="{delete:true}" @event-delete="deleteEvent($event)" locale="de" class="vuecal--blue-theme" :events="computedEvents" style="height: 70vh"></vue-cal>
      <vue-cal v-else class="vuecal--blue-theme" :editable-events="{delete:true}" @event-delete="deleteEvent($event)" :events="computedEvents" style="height: 70vh" />
    </div>
  </div>
  <Loader :show="this.showLoader"></Loader>
</template>

<script>

import VueCal from 'vue-cal'
import '/src/assets/css/calendar.css'
import Loader from "@/components/loader/Loader";
import SweetAlerts from "@/mixins/sweet-alerts/SweetAlerts";

export default {
  name: "Calendar",
  mixins:[SweetAlerts],
  components: {
    VueCal,
    Loader
  },

  data(){
    return{
      showLoader: false,
    }
  },

  computed:{
    computedEvents(){
      return this.$store.getters.events
    }
  },
  methods:{
    deleteEvent(item){
      const payload = {"eventId":item.id}
      this.$store.dispatch("postRequest",{
        "param":"deleteCalendarEvent",
        "payload":payload
      }).then(()=>{
        this.$store.commit("deleteEvent",payload);
        this.showSuccessMessage("Event " + this.$t('deleteSuccess'))
      })
    }
  },

  mounted() {
    this.showLoader = true;
    this.$store.dispatch("getEvents").then(()=> {
      this.showLoader = false;
    });
  }
}

</script>
