<template>
  <div class="form-container" style="position: relative">
    <div class="flex-wrapper">
      <div class="form-elm-left">
        <AppInput :has-error="this.titleIsEmpty" :value="this.courseTitle" @input-changed="titleChanged"
                  :label="$t('form.title')" type="text" :placeholder="this.$t('placeholderTitle')"></AppInput>
      </div>
    </div>
    <div style="margin-bottom: 20px;" class="editor-container">
      <span>{{ $t('form.description') }}</span>
      <quill-editor
          @text-change="descriptionChanged"
          toolbar="full"
          theme="snow"
          ref="quillEditorCourseDescription">
      </quill-editor>
    </div>
    <div class="flex-wrapper" style="margin-right: 20px;">
      <div style="position: relative; width: 100%;" class="form-elm-left select-publication-wrapper">
        <label>{{ $t("selectPublicationStatus") }}</label>
        <VueMultiselect
            :class="statusIsEmpty ? 'errorBorder':''"
            v-model="this.coursePublicationStatus"
            :placeholder="$t('placeholderPublicationStatus')"
            :options="publicationStatusList"
            @select="publicationStatusChanged"
        />
      </div>
      <div class="form-elm-right">
        <AppInput :has-error="this.durationIsNegative" :value="this.courseDuration" @input-changed="durationChanged"
                  :label="$t('form.duration')" type="number" :placeholder="this.$t('placeholderDuration')"></AppInput>
      </div>
    </div>
    <div class="flex-wrapper flex-wrapper-select" style="margin-right: 20px;">
      <div class="form-elm-left select-wrapper">
        <label>{{ $t('form.title.tag') }}s</label>
        <VueMultiselect
            :multiple="true"
            v-model="courseTags"
            mode="tags"
            :close-on-select="false"
            :placeholder="$t('pleaseSelect')"
            @select="tagsChanged"
            :options="tagOptions"/>
      </div>
      <div class="form-elm-right select-wrapper">
        <label>{{ $t('nav.groups') }}</label>
        <VueMultiselect
            v-model="this.courseGroups"
            mode="tags"
            :multiple="true"
            @select="groupsChanged"
            :placeholder="$t('pleaseSelect')"
            :options="groupOptions"/>
      </div>
    </div>
    <div class="toggle-container">
      <div class="form-elm-left toggle-wrapper">
        <label id="1" style="margin-right: 24px;">{{ $t('external.access') }}</label>
        <toggle-btn :default-state="courseExternHasAccess" @toggle-changed="externalAccessChanged" id="1"
                    :label-disable-text="$t('no')" :label-enable-text="$t('yes')"></toggle-btn>
      </div>
      <div class="toggle-wrapper">
        <label id="2" style="margin-right: 24px;">{{ $t('compulsory.course') }}</label>
        <toggle-btn :default-state="courseIsCompulsory" @toggle-changed="courseCompulsoryChanged" id="2"
                    :label-disable-text="$t('no')" :label-enable-text="$t('yes')"></toggle-btn>
      </div>
    </div>
    <div class="toggle-container">
      <div class="form-elm-left toggle-wrapper">
        <label id="3" style="margin-right: 24px;">{{ $t('organisational.access') }}</label>
        <toggle-btn :default-state="isOrganizationWide" @toggle-changed="courseOrgaAccessChanged" id="3"
                    :label-disable-text="$t('no')" :label-enable-text="$t('yes')"></toggle-btn>
      </div>
    </div>

    <div v-if="courseIsCompulsory" style="margin-top: 20px; margin-right: 20px">
      <AppInput :has-error="courseHasEndDate" :value="this.courseCompulsoryEndDate" @input-changed="courseCompulsoryEndDateChanged"
                :label="this.$t('endDate')" type="date"></AppInput>
    </div>
    <div class="form-wrapper-file">
      <label>{{ $t('titleImage.upload') }}</label>
      <input accept="image/jpeg" type="file" @change="fileChanged"/>
      <img :src="this.imageSrc" alt="">
    </div>
  </div>
</template>
<script>
import AppInput from "../../../form/inputs/AppInput";
import VueMultiselect from "vue-multiselect";
import {S3FileManager} from "@/mixins/s3-file-manager/s3-file-manager";
import ToggleBtn from "../../../toggle/ToggleBtn";
import {QuillEditor} from '@vueup/vue-quill'
import Uuidv4Generator from "@/mixins/uuid/Uuidv4Generator";

export default {
  components: {ToggleBtn, AppInput, VueMultiselect, QuillEditor},
  props: {
    isNewCourse: Boolean,
    course: Object,
    titleIsEmpty: Boolean,
    durationIsNegative: Boolean,
    statusIsEmpty: Boolean,
    courseHasEndDate: Boolean,
  },
  mixins: [Uuidv4Generator],

  data() {
    return {
      tagOptions: [],
      groupOptions: [],
      confirmBtnLabel: "",
      imageSrc: "",
      publicationStatusList: ["10", "20"],
      courseId: this.course.id,
      courseTitle: this.course.title,
      courseDescription: this.course.description,
      courseDuration: this.course.duration,
      coursePublicationStatus: this.course.publicationStatus,
      courseGroups: this.course.groups ? this.course.groups : [],
      courseTags: this.course.tags ? this.course.tags : [],
      courseIsCompulsory: this.course.isCompulsory ? this.course.isCompulsory : false,
      courseExternHasAccess: this.course.externHasAccess ? this.course.courseExternHasAccess : false,
      isOrganizationWide: this.course.isOrganizationWide ? this.course.isOrganizationWide: false,
      rating: this.course.rating,
      courseCompulsoryEndDate: this.course.courseCompulsoryEndDate ? this.course.courseCompulsoryEndDate.date : "",
    }
  },

  computed :{
    updatedCourse() {
      return {
        id: this.courseId,
        title: this.courseTitle,
        description: this.courseDescription,
        duration: this.courseDuration,
        publicationStatus: this.coursePublicationStatus,
        groups: this.courseGroups,
        tags: this.courseTags,
        isCompulsory: this.courseIsCompulsory,
        externHasAccess: this.courseExternHasAccess,
        isOrganizationWide: this.isOrganizationWide,
        rating: this.rating,
        courseCompulsoryEndDate: {
          "title": this.courseTitle,
          "date": this.courseCompulsoryEndDate,
          "startDate": this.courseCompulsoryEndDate + " 00:00",
          "endDate": this.courseCompulsoryEndDate + " 24:00"
        }
      }
    }
  },

  methods: {
    groupsChanged() {
      this.emitCourseChangedEvent();
    },

    tagsChanged() {
      this.emitCourseChangedEvent();
    },

    titleChanged(event) {
      this.courseTitle = event.target.value;
      this.emitCourseChangedEvent();
    },

    descriptionChanged() {
      this.courseDescription = this.$refs.quillEditorCourseDescription.getContents();
      this.emitCourseChangedEvent();
    },

    publicationStatusChanged() {
      this.emitCourseChangedEvent();
    },

    durationChanged(event) {
      this.courseDuration = event.target.value;
        this.emitCourseChangedEvent();
    },

    courseCompulsoryChanged(trueOrFalse) {
      this.courseIsCompulsory = trueOrFalse;
      this.emitCourseChangedEvent();
    },

    courseOrgaAccessChanged(trueOrFalse){
      this.isOrganizationWide = trueOrFalse;
      this.emitCourseChangedEvent();
    },

    externalAccessChanged(trueOrFalse) {
      this.courseExternHasAccess = trueOrFalse;
      this.emitCourseChangedEvent();
    },

    courseCompulsoryEndDateChanged(event){
      this.courseCompulsoryEndDate = event.target.value;
      this.emitCourseChangedEvent();
    },

    fileChanged(event) {
      const imageBody = event.target.files[0];
      this.uploadTitleImg(imageBody);
    },

    uploadTitleImg(file) {
      const path = "course/" + this.course.id + "/image.jpeg";
      const s3FileManager = new S3FileManager();
      s3FileManager.uploadFile(file, path).then(() => {
        s3FileManager.getSignedURL("course/" + this.course.id + "/image.jpeg").then(url => {
          this.imageSrc = url;
        })
      });
    },

    /**
     * Checks if the course object is empty.
     * @return {boolean}
     */
    isObjectEmpty() {
      return this.course && Object.keys(this.course).length === 0
          && Object.getPrototypeOf(this.course) === Object.prototype;
    },

    emitCourseChangedEvent() {
        this.$emit("course-changed", this.updatedCourse);
    },

    getTitleImgSrc(courseId) {
      return new Promise((resolve) => {
        const prefix = "course/" + courseId + "/";
        const s3FileManager = new S3FileManager();
        s3FileManager.checkIfListExists(prefix).then(res => {
          if (res.length > 0) {
            s3FileManager.getSignedURL(prefix + "image.jpeg").then(url => {
              resolve(url);
            })
          } else {
            resolve("https://annsvg.com/the-content/uploads/2021/03/E-Learning_gr-1.jpg");
          }
        })
      })
    },
  },

  /**
   * After we mounted the component, we need to retrieve select options like
   * tags and groups. Also we will set the quil editor content, if a course
   * object exists.
   */
  mounted() {
    this.$store.dispatch("getRequest","getTags").then(resp => {
      const data = JSON.parse(resp.body);

      // if we do not use a buffer, somehow the options in
      // multiselect are not loaded correctly.
      let buffer = [];
      for (let item of data) {
        buffer.push(item.tag.tag);
      }

      this.tagOptions = buffer;

    })

    this.$store.dispatch("getRequest","getGroups").then(resp => {
      const data = JSON.parse(resp.body);

      // if we do not use a buffer, somehow the options in
      // multiselect are not loaded correctly.
      let buffer = [];
      for (let item of data) {
        buffer.push(item.group.name);
      }

      this.groupOptions = buffer;
    })

    this.getTitleImgSrc(this.course.id).then(src => {
      this.imageSrc = src;
    });

    if (!this.isObjectEmpty()) {
      if(this.courseDescription)this.$refs.quillEditorCourseDescription.setContents(this.courseDescription.ops);
      // if we have a course object it means we are editing.
      this.confirmBtnLabel = this.$t('course.edit');
    } else {
      // otherwise, we are creating. Set label accordingly.
      this.confirmBtnLabel = this.$t('course.add');
    }
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>

img {
  max-height: 100px;
  display: block;
  margin-top: 10px;
}

.toggle-container {
  display: flex;
  justify-content: space-between;
}

.toggle-wrapper {
  margin-top: 24px;
  display: flex;
  width: 100%;
}

.form-container {
  padding: 40px;
  background-color: var(--brightgrey);
  border-radius: 4px;
  margin-bottom: 24px;
}

.flex-wrapper {
  display: flex;
}

.editor-container{
  margin-right: 20px;
}


input[type = "file"] {
  line-height: 40px;
}

.form-elm-left {
  position: relative;
  width: 100%;
  margin-right: 20px;
}

.form-elm-right {
  position: relative;
  width: 100%;
  margin-left: 20px;
}


.form-wrapper-file {
  margin-top: 24px;
}

@media screen and (max-width: 1000px) {

  .flex-wrapper-select {
    display: block;
  }

  .editor-container{
    margin-right: 0;
  }

  .form-elm-right {
    margin-left: 0;
  }

  .select-wrapper {
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 680px) {

  img {
    width: 100%;
    max-height: none;
  }


  .flex-wrapper {
    display: block;
  }

  .select-publication-wrapper {
    margin-bottom: 24px;
  }

  .toggle-container {
    display: block;
  }

  .toggle-wrapper {
    display: block;
  }
}

@media screen and (max-width: 600px) {

  .form-container {
    margin-bottom: 0;
    padding: 0;
    background-color: transparent;
  }

  .form-wrapper-file {
    margin-bottom: 80px;
  }

  .select-publication-wrapper {
    margin-bottom: 24px;
  }

  .form-elm-left {
    margin-right: 0;
  }

  .form-elm-right {
    margin-left: 0;
  }

  .flex-wrapper {
    display: block;
  }
}

</style>
