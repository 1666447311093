<template>
  <div>
    <div class="editCourseBtnWrapper">
      <primary-btn @click="addModule" :val="$t('module.add')"></primary-btn>
      <primary-btn class="convertPdfButton" @click="convertPdfToTemplates" :val="this.$t('convertPdf')"></primary-btn>
    </div>
      <div class="thumbnail-container">
          <ContentPreviewContainer @switch-modules="(switchEvent)=> this.switchModules(switchEvent)" @add-module="(module)=>this.selectStepType(module)"
                                   @delete-module="(module)=>this.deleteModule(module)" :isEditorView="true" @module-updated="moduleUpdated" :course="this.updatedCourse"
                                   @template-clicked="(module, template)=>this.openTemplate(template, module)" @module-name-changed="(module)=>this.editModuleName(module)">
          </ContentPreviewContainer>

    </div>

    <Modal :val="this.$t('saveExit')" @close-form="this.isTemplateOpen = false" :heading="this.$t('editTemplate')" :modal-is-open=this.isTemplateOpen>
      <EditTemplate :module-id="selectedModuleId" :course-id="course.id" :template="selectedTemplate"></EditTemplate>
    </Modal>

    <Modal :val="this.$t('saveExit')" @close-form="closePdfConverterModal" :modal-is-open="isPdfConverterOpen">
      <PdfImageConverter :module="pdfConvertModule" :course="course"></PdfImageConverter>
    </Modal>

  </div>
</template>
<script>

import PrimaryBtn from "@/components/buttons/PrimaryBtn";
import ContentPreviewContainer from "../../../content-preview-container/content-preview-container";
import Modal from "../../../modal/Modal";
import EditTemplate from "../../template-manager/EditTemplate";
import SweetAlerts from "@/mixins/sweet-alerts/SweetAlerts";
import PdfImageConverter from "@/components/pdf-image-converter/PdfImageConverter";

export default {
  name: 'CourseModules',
  mixins: [SweetAlerts],
  components: {
    PdfImageConverter,
    EditTemplate,
    Modal,
    ContentPreviewContainer,
    PrimaryBtn,
  },

  props:{
    course:{}
  },

  data(){
    return{
      courseModuleData: this.course.courseModules ? this.course.courseModules : [],
      selectedTemplate: null,
      selectedModuleId: "",
      isTemplateOpen: false,
      isPdfConverterOpen: false,
      pdfConvertModule: {},
      static: {image: this.$t('form.image'),
        file: this.$t('file'),
        text: "Text",
        video: "Video",
        audio:"Audio",
        pdf: "PDF", },
      interactive:{question: this.$t('question'),
        imageMarker: this.$t('imageMarker'),
        userCertificate: this.$t('fileUpload')
      }
    }
  },

  computed:{
    updatedCourse(){
      let course = {};
      course = this.course;
      course["courseModules"] = this.courseModuleData;
      return course;
    }
  },

  methods:{
    openModal(){
      this.modalIsOpen = !this.modalIsOpen;
    },

    // opens a new template inside its given editor. Images e.g. will be
    // opened inside the image-editor component etc.
    openTemplate(template, module){
      // set the selected template for the modal property.
      this.selectedTemplate = template;

      this.selectedModuleId = module.id;
      //.. and open the modal.
      this.isTemplateOpen = true;
    },

    convertPdfToTemplates(){
      this.$swal({
        title: this.$t('enterTitle'),
        input: "text",
        inputPlaceholder: this.$t('form.title'),
        confirmButtonColor: "#007AC2",
        confirmButtonText: this.$t('confirm'),
        showCancelButton: true,
        cancelButtonText: this.$t('button.cancel'),
      }).then((res) => {
        if (res.value && res.isConfirmed){
          this.moduleObject = {
            "title": res.value,
            "id": new Date().getTime().toString(),
            "courseTemplates": []
          }
          this.pdfConvertModule = this.moduleObject;
          this.courseModuleData.push(this.moduleObject);
          this.emitModuleUpdatedEvent();
          this.isPdfConverterOpen = true;
        }
      });
    },

    closePdfConverterModal(){
      this.isPdfConverterOpen = false;
    },

    /**
     * Update the module and make sure that the template popup, in which
     * the module was updated is closed.
     * @param module
     */
    moduleUpdated(module){
      const moduleIndex = this.courseModuleData.indexOf(module);
      this.courseModuleData[moduleIndex] = module;

      // and make sure the template is closed.
      this.isTemplateOpen = false;
      this.isPdfConverterOpen = false;
    },

    switchModules(event){
      const moveModuleIndex = event.moveModuleIndex;
      const toModuleIndex = event.toModuleIndex;
      const movedModule = event.movedModule;

      this.courseModuleData.splice(moveModuleIndex, 1);
      this.courseModuleData.splice(toModuleIndex, 0, movedModule);
    },

    deleteModule(module){
      this.showConfirmDialogue(this.$t('deleteModule') + '?',this.$t('swal.confirmDelete')).then((result) => { // <--
        if (result === true) { // <-- if confirmed
          this.courseModuleData.splice(this.courseModuleData.indexOf(module),1);
          this.emitModuleUpdatedEvent();
        }
      });
    },

    addModule(){
      this.$swal({
        title: this.$t('enterTitle'),
        input: "text",
        inputPlaceholder: this.$t('form.title'),
        confirmButtonColor: "#007AC2",
        confirmButtonText: this.$t('confirm'),
        showCancelButton: true,
        cancelButtonText: this.$t('button.cancel'),
      }).then((res) => {
        if (res.value && res.isConfirmed){
          this.moduleObject = {
            "title": res.value,
            "id": new Date().getTime().toString(),
            "courseTemplates": []
          }
          this.courseModuleData.push(this.moduleObject);
          this.emitModuleUpdatedEvent();
        }
      });
    },

    emitModuleUpdatedEvent(){
      this.$emit("modules-updated", this.courseModuleData);
    },

    getEditorObject(type){
      switch (type){
        case "video":
          return {
            videoType : "",
            videoSrc: ""
          }
        case "image":
          return {
            imgType : "",
            imgSrc: ""
          }
        case "file":
          return {
            fileType : "",
            fileSrc: ""
          }
        case "userCertificate":
          return{
            fileType: "",
            fileSrc: ""
          }
        case "text":
          return [];
        case "question":
          return {
            question: "",
            answerList: []
          }
        case "imageMarker":
          return{
            imgSrc: "",
            imgType: ""
          }
        case "pdf":
          return{
          }
        case "audio":
          return{
            audioSrc:"",
          }
      }
    },

    // Edits the module name.
    editModuleName(module){
      this.$swal({
        title: this.$t('module.editName'),
        html: '<input type="text" class="swal2-input"  id="moduleName">',
        confirmButtonText:this.$t('button.change'),
        confirmButtonColor: "#007AC2",
        showCancelButton:true,
        cancelButtonText:this.$t('button.cancel'),
        // resolves before the confirmation. We check if the value of the input is empty and if so we warn the user.
        preConfirm:() =>{
          const moduleName = this.$swal.getPopup().querySelector('#moduleName').value
          if(!moduleName) this.$swal.showValidationMessage(this.$t('emptyInput'))
          return {moduleName: moduleName}
        }
      }).then((result) => {
        // If confirmed, we change the title from the module.
        if(result.isConfirmed){
          module.title = result.value.moduleName
        }
      });
    },

    selectStepType(module){
      this.$swal({
        title: this.$t('selectStepType'),
        input: 'select',
        inputOptions: {
          statisch:this.static,
          interaktiv:this.interactive
        },
        inputPlaceholder: this.$t('placeholderSelectStepType'),
        confirmButtonColor: "#007AC2",
        confirmButtonText: this.$t('confirm'),
        showCancelButton: true,
        cancelButtonText: this.$t('button.cancel'),
      }).then((res) => {
        if (res.isConfirmed && res.value){
          const newTemplateObject = {
            "title": "neu",
            "type": res.value,
            "editorData": this.getEditorObject(res.value),
            "id": new Date().getTime().toString()
          }

          const moduleIndex = this.courseModuleData.indexOf(module);
          this.courseModuleData[moduleIndex].courseTemplates.push(newTemplateObject);
        }
      });
    },
  },
}
</script>
<style scoped>

.editCourseBtnWrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.add-template-btn:hover svg{
  fill: var(--darkgrey);
}

.add-template-btn svg{
  fill: var(--primary-color);
}

.bottom-container button span{
display: flex;
}

.close-icon svg{
  fill: var(--delete);
}

.close-icon:hover svg{
  fill: var(--darkgrey);
}

  .thumbnail-container{
    display: flex;
    flex-direction: column;
    margin-top: 24px;
  }

  @media screen and (max-width: 500px){
    .editCourseBtnWrapper{
      flex-direction: column;
    }
    .editCourseBtnWrapper button:first-child{
      margin-bottom: 10px;
    }
  }

</style>
