<template>
<div>
  <div class="headline">
    <PrimaryBtn @click="this.addComment" :val="$t('addComment')"></PrimaryBtn>
  </div>
  <div class="input-container">
    <label>
      <input @keyup.enter.exact="this.addComment" v-model="commentText" :placeholder="$t('addComment') + '...'" type="text"/>
    </label>

  </div>
  <div v-for="(comment) in commentsData" :key=comment class="single-comment-container">
    <div v-if="this.isAdmin" class="delete-button-container">
      <button class="delete-button" @click="deleteComment(comment)">
        <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#555"><path d="M0 0h24v24H0z" fill="none"/><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/></svg>
      </button>
    </div>
    <single-comment class="comment-container" :course-id="courseId" :course="this.course" :comment-prop="comment">
    </single-comment>
  </div>
</div>
</template>

<script>
import PrimaryBtn from "../buttons/PrimaryBtn";
import SingleComment from "./SingleComment";
import Uuidv4Generator from "@/mixins/uuid/Uuidv4Generator";
import SweetAlerts from "@/mixins/sweet-alerts/SweetAlerts";

export default {
  name: "CommentSection",
  components: {SingleComment, PrimaryBtn},
  mixins: [Uuidv4Generator,SweetAlerts],
  props:{
    comments: [],
    courseId: String,
    course: {}
  },
  data(){
    return{
      commentsData: this.comments ? this.comments: [],
      commentText: "",
      user: "",
      isAdmin: false,
    }
  },
  computed:{
    comment(){
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour:"2-digit", minute:"2-digit"};
      return {
        "author": this.$store.getters.friendlyName,
        "creationDate": new Date().toLocaleDateString("de-DE", options),
        "text": this.commentText,
        "id": this.createUuidv4(),
        "likedBy":[]
      }
    },
  },
  methods:{
    //Comments can't be posted if they are empty.
      addComment(){
      if(this.comment.text.length > 0){

        const postObject = {
            "id": this.courseId,
            "comment": this.comment
        }

        this.$store.dispatch("postRequest",{
          "param":"addCourseComment",
          "payload":postObject
        }).then(()=>{

          const postObject = {
            "courseId":this.courseId,
            "comment":this.comment,
          }

          this.$store.commit("addComment",postObject);
          this.commentText = "";
        })
      }else{
        this.showWarningMessage(this.$t('emptyComments'))
      }
    },

    //Showing a sweet-alert, if confirmed the comment gets deleted.
    deleteComment(comment){
      this.$swal({
        title: this.$t('deleteComment'),
        text: this.$t('swal.confirmDelete'),
        confirmButtonColor: "#007AC2",
        confirmButtonText: this.$t('confirm'),
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: this.$t('button.cancel'),
      }).then((res) => {
        if (res.isConfirmed) {
          const commentData = {
            "comment": comment,
            "id": this.courseId,
          }
          this.$store.commit("deleteComment",commentData);

          this.$store.dispatch("postRequest",{
            "param":"deleteComment",
            "payload":commentData
          })
        }
      });

    }
  },

  mounted(){
    // Checking if user is admin
      if (this.$store.getters.role.toLowerCase() === "admin") this.isAdmin = true;
  },
}
</script>

<style scoped>
.input-container{
  margin-top: 20px;
  margin-bottom: 20px;
}

.input-container button{
  margin-bottom: 24px;
}

input{
  width:100%;
  border: 1px solid #d1d5db;
}

input[type=search]:focus {
  border-color: var(--primary-color);
}

.single-comment-container{
  margin-bottom: 5px;
  position: relative;
}

.delete-button-container{
  right: 20px;
  top: 20px;
  position: absolute;
}

.delete-button{
  border: none;
  border-radius: 4px;
  background-color: transparent;
  padding: 0;
  display: flex;
}
</style>
