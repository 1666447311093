<template>
  <div>
    <h3 style="margin-bottom: 10px;">{{this.$t('question')}}-Template</h3>
    <AppInput :has-error="titleIsEmpty" :value="stepObject.title" @input-changed="emitTitleChangedEvent" type="text" :label="this.$t('title')" :placeholder="this.$t('placeholderTitle')"></AppInput>
    <AppInput :value="stepObject.description" @input-changed="emitDescriptionChangedEvent" type="text" :label="this.$t('form.description')" :placeholder="this.$t('placeholderDescription')"></AppInput>
    <div style="margin-top:30px">
      <div>
        <AppInput :value="stepObject.editorData.question" @input-changed="emitQuestionChangedEvent" type="text" :label="this.$t('question')" :placeholder="this.$t('placeholderQuestion')"></AppInput>
        <div class="padding-attempts" style="display:flex; justify-content: space-between">
          <div>
            <AppInput :value="this.maxAttempts" @input-changed="maxAttemptsValueChanged" type="number" :label="this.$t('attempts')" :placeholder="this.$t('placeholderMaxAttempts')"></AppInput>
          </div>
        </div>
        <div v-for="(item, i) in answerList" :key="item.answer">
              <div class="question-wrapper">

              <span class="counter-questions">{{ i+1 }}</span>
                <div>
                <AppInput :value="item.answer" @input-changed="(event)=>emitAnswerListChanged(event, item, 'answer')" type="text" :label="this.$t('answerOptions')" :placeholder="this.$t('placeholderAnswer')"></AppInput>
              </div>
              <div>
                <AppInput :value="item.feedback" @input-changed="(event)=>emitAnswerListChanged(event,item, 'feedback')"  type="text" :label="this.$t('feedback')" :placeholder="this.$t('placeholderFeedback')"></AppInput>
              </div>
              <div style="margin-bottom: 0; flex: 0.1; display: flex; flex-direction: revert; align-items: center;">
                <AppInput :checked="item.isCorrectAnswer" style="margin-bottom: 0; display: flex; flex-direction: row; align-items: center;" :value="item.isCorrectAnswer"  @input-changed="(event)=>emitAnswerListChanged(event,item, 'isCorrectAnswer')"  type="checkbox" :label="$t('question.correct')"></AppInput>
              </div>
              <div class="add-question-btn-container">
                <button class="deleteBtn" @click="this.deleteAnswer(item)">
                  <span>{{$t('deleteEvent')}}</span>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="red" data-v-75ad1774=""><path d="M0 0h24v24H0V0z" fill="none" data-v-75ad1774=""></path><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9zm7.5-5l-1-1h-5l-1 1H5v2h14V4h-3.5z" data-v-75ad1774=""></path></svg>
                  </span>
                </button>
              </div>
            </div>
        </div>
      </div>
      <div>
        <button class="add-question-btn" @click="this.addAnswer">
          <span>{{ this.$t('addAnswer') }}</span>
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#007AC2"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></svg>
          </span>
        </button>
      </div>
    </div>
  </div>

</template>

<script>

import AppInput from "../form/inputs/AppInput";


export default {
  name: "QuestionEditor.vue",
  components: {AppInput},

  props:{
    stepObject: Object,
  },

  data(){
    return{
      answerList: this.stepObject.editorData.answerList,
      question: this.stepObject.editorData.question,
      maxAttempts: this.stepObject.editorData.maxAttempts ? this.stepObject.editorData.maxAttempts : 1,
      titleIsEmpty: false,
    }
  },

  methods:{
    addAnswer(){
      this.answerList.push(this.createAnswerObject())
    },

    createAnswerObject(){
      return {
        id: new Date().getTime().toString(),
        answer: "",
        isCorrectAnswer: false,
        feedback: ""
      }
    },

    deleteAnswer(item){
      this.answerList.splice(this.answerList.indexOf(item),1);
    },

    maxAttemptsValueChanged(event){
      this.maxAttempts = event.target.value;
      this.$emit("max-attempts-changed", this.maxAttempts);
    },

    emitTitleChangedEvent(title){
      if(title.target.value === undefined || title.target.value === '' || title.target.value === null){
        this.titleIsEmpty = true;
      }else{
        this.titleIsEmpty = false;
        this.$emit("step-title-changed", title);
      }
    },

    emitDescriptionChangedEvent(description){
      this.$emit("step-description-changed", description);
    },

    emitAnswerListChanged(event, answerObject, key){

      // first we need to find the index of this answer object.
      const index = this.answerList.indexOf(answerObject);

      // now we can update the answer of this object.
      this.answerList[index][key] = key === "isCorrectAnswer" ? event.target.checked : event.target.value;

      const questionObject = {
        "question": this.question,
        "answerList": this.answerList,
        "maxAttempts": this.maxAttempts.toString()
      }

      this.$emit("editor-changed", questionObject);
    },

    emitQuestionChangedEvent(event){
      this.question = event.target.value;

      const questionObject = {
        "question": this.question,
        "answerList": this.answerList
      }

      this.$emit("editor-changed", questionObject);
    },
  },

  watch:{
    stepObject(){
      this.answerList = this.stepObject.editorData.answerList;
    }
  }
}

</script>

<style scoped>

.question-wrapper{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 24px;
}

.counter-questions{
  position: absolute;
  left: 5px;
  top: -5px;
  border: 1px solid var(--darkgrey);
  border-radius: 4px;
  font-size: 10px;
  height: 15px;
  width: 15px;
  align-items: center;
  display: flex;
  justify-content: center;
  color: var(--darkgrey);
  /*font-weight: bold;*/
}

.deleteBtn svg{
  fill: var(--delete);
}

.deleteBtn:hover svg{
  fill: var(--darkgrey);
}

.deleteBtn:hover{
  border: 2px solid var(--darkgrey);
  color: var(--darkgrey);
}

.deleteBtn{
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--delete);
  border-radius: 4px;
  color: var(--delete);
  background-color: transparent;
  padding: 2px 10px 0 10px;
}

.add-question-btn{
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--primary-color);
  border-radius: 4px;
  color: var(--primary-color);
  background-color: transparent;
  padding: 2px 10px 0 10px;
  margin-bottom: 10px;
}

.add-question-btn svg{
  fill: var(--primary-color);
}

.add-question-btn:hover svg{
  fill: var(--darkgrey);
}

.add-question-btn:hover{
  border: 2px solid var(--darkgrey);
  color: var(--darkgrey);
}

.padding-attempts{
 padding-bottom: 15px;
}

@media screen and (max-width: 1000px) {
  .question-wrapper{
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    background-color: var(--bright);
    border-radius: 4px;
    margin-bottom: 24px;
    border: 1px solid #d1d5db;
  }

  .add-question-btn-container{
    margin-left: auto;
  }
}
</style>
