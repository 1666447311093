export default {
  "nav.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News"])},
  "nav.library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Library"])},
  "nav.courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My courses"])},
  "nav.calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar"])},
  "nav.persons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persons"])},
  "nav.groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups"])},
  "nav.settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
  "course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course"])},
  "selectCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose course"])},
  "deleteUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delete user"])},
  "objectCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object count"])},
  "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
  "notifications.markAsRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark all as read"])},
  "card.readmore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read more"])},
  "advertisedCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advertised Course"])},
  "newTitleImg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New title image"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "saveExit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save & Back"])},
  "createdCourses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created courses"])},
  "usedMemory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Used memory"])},
  "createdUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created users"])},
  "unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unlimited"])},
  "groupExtern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extern group"])},
  "responsible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsible"])},
  "pleaseSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "pleaseEnterYourAccessDataToContinue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your access data to continue."])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "elearningSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have questions about the content of individual courses, please contact the respective author of the course. Do you need support in using the e-learning learning platform? Please first check the documentation provided on the intranet. For further questions, please send an e-mail to "])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
  "modules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modules"])},
  "courseDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course details"])},
  "deleteModule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete module"])},
  "addTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add template"])},
  "editTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit template"])},
  "deleteTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete this template ?"])},
  "goToCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to course"])},
  "editPost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit post"])},
  "addComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add comment"])},
  "deleteComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete comment"])},
  "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
  "generateLearningInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["generate learning info"])},
  "deleteCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete course"])},
  "deletePost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete post"])},
  "selectStepType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a step type."])},
  "placeholderSelectStepType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["select a step type"])},
  "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])},
  "courseHasRating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course has rating"])},
  "courseHasCertificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course grants certificate"])},
  "successfulAttendanceQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What percentage of the questions must participants answer correctly?"])},
  "swal.confirmDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure? You won't be able to revert this!"])},
  "swal.logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to logout?"])},
  "addSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["successfully added"])},
  "deleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["successfully deleted"])},
  "swal.updateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["successfully updated"])},
  "resetSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully restarted"])},
  "enterTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a title."])},
  "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File"])},
  "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question"])},
  "imageMarker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image marker"])},
  "fileUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File upload"])},
  "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
  "enrol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enrol"])},
  "progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progress"])},
  "button.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "button.back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "button.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "button.delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "button.change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
  "showNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show notifications"])},
  "courseGroupRecommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose groups that you would like to inform about this course. The persons inside this group, will be informed by a system notification. You can choose multiple groups at once."])},
  "courseGroupRecommendationHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inform group(s) about course"])},
  "rightsError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have the permission to perform this action."])},
  "course.add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add course"])},
  "course.edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit course"])},
  "unsaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsaved"])},
  "unsaved.content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There might be unsaved edits. By pressing confirm these edits won't be saved. Are you sure you want to go back ?"])},
  "compulsory.course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compulsory course"])},
  "external.access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External access"])},
  "module.add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add module"])},
  "module.editName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit module name"])},
  "tab.course.content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course content"])},
  "tab.course.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course description"])},
  "tab.course.discussion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discussion"])},
  "feed.add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New post"])},
  "person.add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add person"])},
  "person.edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit person"])},
  "group.add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add group"])},
  "group.edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit group"])},
  "group.delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Group"])},
  "tag.add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add tag"])},
  "tag.delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete tag"])},
  "select.grid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grid view"])},
  "select.list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List view"])},
  "resendInvitationLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["resend invitation link"])},
  "resentInvitationLinkSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["resent invitation link"])},
  "form.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
  "form.title.tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])},
  "form.creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creator"])},
  "form.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "form.duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration (in min)"])},
  "form.image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
  "form.publicationStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "form.content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content"])},
  "form.upload.file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload file"])},
  "form.person.friendlyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friendly name"])},
  "form.person.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "form.person.role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
  "form.person.group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
  "form.person.persNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal number"])},
  "form.company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
  "form.group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
  "form.person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person"])},
  "mandatoryCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandatory course"])},
  "titleImage.upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload title image"])},
  "question.correct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correct"])},
  "question.wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong"])},
  "footer.about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])},
  "footer.help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])},
  "footer.terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of use"])},
  "deleteEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "learningProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learning Progress"])},
  "currentLearnStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current step"])},
  "courseCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course count"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
  "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
  "selectPublicationStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publication status (10 = inactive, 20 = live)"])},
  "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
  "attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attempts"])},
  "remainingAttempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["remaining attempts"])},
  "noRemainingAttempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No remaining attempts left. PLease go to the next template."])},
  "assignCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign course"])},
  "emailConfirmationHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user did not confirm its email yet"])},
  "convertToCodeUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["convert to code user"])},
  "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retry"])},
  "submitAnswer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit answer"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "calendar.add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to Calendar"])},
  "dateStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begin (date)"])},
  "timeStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begin (time)"])},
  "dateEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End (date)"])},
  "timeEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End (time)"])},
  "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End course till"])},
  "calendarSubmitEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inputs can't be empty"])},
  "calendarDateSubmitWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date or time is after the end date"])},
  "courseSuccessfullyAddedIntoCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course successfully added into calendar."])},
  "selectVideoType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select video type"])},
  "insertYTLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["insert youtube link"])},
  "insertLinkToVideoSrc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["insert a link pointing to a video src"])},
  "answerOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer options"])},
  "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
  "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
  "uploadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload a file from your computer."])},
  "uploadVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["upload a video from your computer"])},
  "uploadPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload a PDF file from your computer"])},
  "videoType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videotype"])},
  "maxAttempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum Attempts"])},
  "placeholderResponsible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select responsible"])},
  "placeholderSelectCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a course"])},
  "placeholderTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter title"])},
  "placeholderDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter description"])},
  "placeholderMaxAttempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter max attempts"])},
  "placeholderEnterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter e-mail"])},
  "placeholderEnterPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter password"])},
  "placeholderCreator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter creator"])},
  "placeholderGroupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter group name"])},
  "placeholderTagName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter tag"])},
  "placeholderFriendlyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter name"])},
  "placeholderPersNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter personal number"])},
  "placeholderCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter company"])},
  "placeholderRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter role"])},
  "placeholderDateStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter start date"])},
  "placeholderDateEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter end date"])},
  "placeholderTimeStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter start time"])},
  "placeholderTimeEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter end time"])},
  "placeholderDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter duration"])},
  "placeholderQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Question"])},
  "placeholderAnswer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter answer"])},
  "placeholderFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter feedback"])},
  "placeholderYoutubeLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter youtube link"])},
  "placeholderVideoSrc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter video source"])},
  "placeholderPublicationStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select publication status"])},
  "placeholderRecommendCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select person"])},
  "placeholderSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "placeholderPersonId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
  "resetMarker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset marker"])},
  "enableDrag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable drag"])},
  "recommendCourseToPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommend course to a person: "])},
  "recommendCourseToGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommend course to a group: "])},
  "recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" got recommended"])},
  "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password"])},
  "enterVerifiedEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your verified E-mail address."])},
  "verificationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification code"])},
  "enterVerificationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your verification code that was sent to your E-mail."])},
  "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
  "enterNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a new, strong password. (upper and lower case & 1 special character & min 8 characters)"])},
  "notSubscribed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not subscribed"])},
  "subscribeFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please subscribe to the course first, using the top left button."])},
  "courseCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course completed"])},
  "courseFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course failed"])},
  "completedCourseInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You already completed this course."])},
  "finishPrevStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please finish the previous step(s) first, before you access: "])},
  "lookForLearningIcon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Look out for the yellow learning icon, or select continue in the top right corner, to go to your current learning template."])},
  "visitWebsite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visit our new website"])},
  "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recently visited"])},
  "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["open"])},
  "noNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Notifications"])},
  "Notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
  "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification"])},
  "finishedModuleMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have finished this module."])},
  "startNextModuleMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you wish to start the next module ?"])},
  "finishedLastModuleMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have finished this course"])},
  "passwordResetSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully set new password"])},
  "fileExceedSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File size exceeds 5MB."])},
  "noFileFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No file found."])},
  "convertPdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convert PDF into a Module"])},
  "selectPdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a PDF file"])},
  "showCertificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to open the certificate ?"])},
  "certificateNotUploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The certificate hasn't been uploaded yet."])},
  "downloadCertificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["download certificate"])},
  "like": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Like"])},
  "subscriptionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subscribed on"])},
  "completionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["completed on"])},
  "restartCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restart course"])},
  "restartCourseText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to restart the course ? You have "])},
  "restartCourseCompletely": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to restart the entire course for this user ?"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["address"])},
  "emptyInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The input isn't allowed to be empty."])},
  "recommendedForMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended for me"])},
  "commentAlreadyLiked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have already liked this comment."])},
  "emptyComments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments aren't allowed to be empty"])},
  "exportToExcel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export to Excel"])},
  "exportToExcelText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you wish to export this table into Excel ? This might take a few minutes."])},
  "noSearchResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry! We couldn't find any results"])},
  "cantDeleteYourself": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can't delete yourself."])},
  "cantDeleteGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You aren't allowed to delete a group, that still has users assigned."])},
  "addAnswer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add answer option"])},
  "courseDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This course doesn't exist anymore."])},
  "courseInactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This course is currently inactive."])},
  "passwordDoesntFitCriteria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This password doesn't fit our criteria.(Upper - lowercase, 1 special character & min. 8 characters)"])},
  "setNewPasswordAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to enter a new password again ?"])},
  "invalidVerificationCodeError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid verification code provided, please try again."])},
  "emailEmptyError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username cannot be empty."])},
  "passwordEmptyError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password cannot be empty."])},
  "usernameOrPasswordWrongError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username or password are wrong."])},
  "verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verify User"])},
  "reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reload"])},
  "reloadPageText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This page hasn't been reloaded in a while and could have new content available. Please reload the page."])},
  "securityError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot log in due to security reasons. Please reset your password."])},
  "selectCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select your company headquarters."])},
  "placeholderSelectCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation"])},
  "loginWithCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login with code"])},
  "codePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter code"])},
  "registerWithCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register with code"])},
  "pleaseCopyCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These credentials are shown only once! Please press 'Copy credentials'."])},
  "createdId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created Id: "])},
  "createdPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created password: "])},
  "cantEditCreatedUsersWithCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users that were created with a code, cannot be edited."])},
  "copyCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy credentials"])},
  "oneTimeUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One time user"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The 3D-room is being currently created"])},
  "open3DRoom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open 3D-room"])},
  "creationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation date"])},
  "liveCourseModulesIsEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Live courses must have a minimum of one Module and one Template."])}
}