export default {
    methods:{
        /**
         * sets of charachters
         */

        /**
         * generate random integer not greater than `max`
         */

        rand(max) {
            return Math.floor(Math.random() * max)
        },

        /**
         * generate random character of the given `set`
         */

        random(set) {
            return set[this.rand(set.length - 1)]
        },

        /**
         * generate an array with the given `length`
         * of characters of the given `set`
         */

        generate(length, set) {
            let result = []
            while (length--) result.push(this.random(set))
            return result
        },

        /**
         * shuffle an array randomly
         */
        shuffle(arr) {
            let result = []

            while (arr.length) {
                result = result.concat(arr.splice(this.rand[arr.length - 1]))
            }

            return result
        },
        /**
         * generate the password
         */
        password(length) {
            const upper = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            const lower = 'abcdefghijklmnopqrstuvwxyz';
            const digit = '0123456789';
            const special = '\'!@#$%&()_+}{[]?/=\'';
            const all = upper + lower + digit + special;
            let result = [] // we need to ensure we have some characters

            result = result.concat(this.generate(1, upper)); // 1 upper case
            result = result.concat(this.generate(1, lower)); // 1 lower case
            result = result.concat(this.generate(1, digit)); // 1 digit
            result = result.concat(this.generate(1, special)); // 1 digit
            result = result.concat(this.generate(length - 4, all)); // remaining - whatever


            return this.shuffle(result).join(''); // shuffle and make a string
        }
    }
}