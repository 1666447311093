
export default {

    methods:{
        showConfirmDialogue(title, text){
            return new Promise((resolve) => {
                this.$swal({
                    // enter template title in title.
                    title: title,
                    text: text,
                    icon: 'warning',
                    type: "warning",
                    confirmButtonColor: "#007AC2",
                    confirmButtonText: this.$t('button.confirm'),
                    showCancelButton: true,
                    cancelButtonText: this.$t('button.cancel'),
                }).then((result) => { // <--
                    if (result.value && result.isConfirmed) {
                        resolve(true);
                    }
                    // else do nothing...
                });
            })
        },

        showConfirmationDialogue(title,text) {
            return new Promise((resolve)=>{
                this.$swal({
                    title: title,
                    text: text,
                    icon: 'warning',
                    confirmButtonColor: "#007AC2",
                    confirmButtonText: this.$t('confirm'),
                    showCancelButton: true,
                    cancelButtonText: this.$t('button.cancel'),
                }).then((result)=>{
                    if(result.value && result.isConfirmed){
                        resolve(true);
                    }
                })
            })
        },
        showSuccessMessage(message){
            this.$swal({
                icon: 'success',
                title: message,
                confirmButtonColor: "#007AC2",
            })
        },
        showErrorMessage(message){
            this.$swal({
                icon: 'error',
                title: message,
                confirmButtonColor: "#007AC2",
            })
        },
        showWarningMessage(message){
            this.$swal({
                icon: 'warning',
                title: message,
                confirmButtonColor: "#007AC2",
            })
        },
        showInfoMessage(title, message){
            this.$swal({
                icon: 'info',
                title: title,
                html:message,
                confirmButtonColor: "#007AC2",
            })
        },
        showPromptMessage(title, message){
            return new Promise((resolve)=>{
                this.$swal({
                    input:"text",
                    title: title,
                    text: message,
                    content: 'input',
                    showCancelButton: true,
                    cancelButtonText: this.$t('button.cancel'),
                }).then(result =>{
                    resolve(result)
                });
            })
        },
        showPromptPassword(title, message){
            return new Promise((resolve)=>{
                this.$swal({
                    input:"password",
                    title: title,
                    text: message,
                    content: 'input',
                    showCancelButton: true,
                    cancelButtonText: this.$t('button.cancel'),
                }).then(result =>{
                    resolve(result)
                });
            })
        }
    }
}