<template>
  <Modal :val="this.$t('back')" id="elem" @close-form="galleryClosed" :heading="this.template.title"
         :modal-is-open=modalIsOpen>
    <div>
      <div v-if="this.template.description"
           :class="{'description-container': showDescription ,'hidden-description-container': !showDescription}">
        <div title="Beschreibung anzeigen" @click="showDescription = !showDescription" class="toggle-container"
             :class="{'btnToggled':showDescription}">
          <svg class="descriptionIcon" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"
               fill="var(--primaryColor)">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"/>
          </svg>
        </div>
        <div v-if="showDescription" class="contentDescription">
          <span>
          {{ template.description }}
        </span>
        </div>
      </div>
      <!-- Only shows when template is question or imageMarker and gives the buttons the corresponding function, otherwise its the normal back/next -->
      <div
          v-if="this.template.type  === 'question' || this.template.type === 'imageMarker'"
          class="prev-next-btn-container">
        <button style="padding-right: 20px" @click="this.goToPreviousTemplate">
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#007AC2FF">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"/>
          </svg>
          <span>{{ this.$t('back') }}</span>
        </button>
        <div class="textcenter">
          <h3>{{ showCurrentIndex() }} / {{ showMaximumIndex() }}</h3>
        </div>
        <button style="padding-left: 20px" :disabled="submitEventButtonStatus"
                @click="goToNextTemplateAndEnableChoices">
          <span>{{ this.$t('next') }}</span>
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#007AC2FF">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/>
          </svg>
        </button>
      </div>

      <div v-else class="prev-next-btn-container">
        <button style="padding-right: 20px" @click="this.goToPreviousTemplate">
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#007AC2FF">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"/>
          </svg>
          <span>{{ this.$t('back') }}</span>
        </button>
        <div class="textcenter">
          <h3>{{ showCurrentIndex() }} / {{ showMaximumIndex() }}</h3>
        </div>
        <button style="padding-left: 20px" @click="goToNextTemplate">
          <span>{{ this.$t('next') }}</span>
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#007AC2FF">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/>
          </svg>
        </button>
      </div>

      <!-- TEXT -->
      <div v-if="this.template.type !== null">
        <div v-if="this.template.type === 'text'">
          <div class="quill-editor-container" style="">
            <quill-editor
                ref="myQuillEditor"
                readOnly="true"
                theme="bubble"
                toolbar="false">
            </quill-editor>
          </div>
        </div>

        <div v-if="this.template.type  === 'video'" class="iframe-container">
          <VideoTemplate :template="this.template" :video-src-prop="this.videoSrc"></VideoTemplate>
        </div>

        <div class="image-container" v-if="this.template.type  === 'image'">
          <img class="image" :src="imageSrc" alt="">
        </div>

        <div class="image-container" v-if="this.template.type  === 'imageMarker'">
          <ImageMarkerTemplate :course-data-prop="courseData" :template-prop="template"
                               :clicked-prop="clicked" @clicked="clickedMethod"
                               :image-src-prop="imageSrc" :remaining-attempts-prop="remainingAttempts"
                               :is-answer-correct-prop="isAnswerCorrect" @answerIsCorrect="answerIsCorrectMethod"></ImageMarkerTemplate>
        </div>

        <div class="iframe-container" v-if="this.template.type  === 'pdf'">
          <iframe :src="this.pdfSrc">
          </iframe>
        </div>

        <div class="question-content-container" v-if="this.template.type === 'audio'">
          <div class="question-card">
            <audio tabindex="0" id="audio" controls preload="auto" :src="this.audioSrc">
            </audio>
          </div>
        </div>

        <div v-if="this.template.type  === 'question'" class="question-content-container">
          <QuestionTemplate :remaining-attempts-prop="remainingAttempts" :template="template"
                            :disable-choice-prop="disableChoice" @disableChoice="disableChoiceMethod"
                            :is-answer-correct-prop="isAnswerCorrect"  @answerIsCorrect="answerIsCorrectMethod"
                            :no-remaining-attempts-prop="noRemainingAttempts" :question-feedback-list-prop="questionFeedbackList"
                            :show-feedback-prop="showFeedback" @showFeedback="showFeedbackMethod" :course-data="courseData"></QuestionTemplate>
        </div>
        <div class="question-content-container" v-if="this.template.type  === 'file'">
          <FileTemplate :s3-key-list="s3KeyList"></FileTemplate>
        </div>
        <div class="question-content-container" v-if="this.template.type  === 'userCertificate'">
          <UserCertificateTemplate :s3-key-list-prop="s3KeyList" :is-learning-info-prop="isLearningInfo"
          :template="template" :course="course" :active-module="activeModule" ></UserCertificateTemplate>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from "../modal/Modal";
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import '@vueup/vue-quill/dist/vue-quill.bubble.css';
import {S3FileManager} from "@/mixins/s3-file-manager/s3-file-manager";
import SweetAlerts from "@/mixins/sweet-alerts/SweetAlerts";
import VideoTemplate from "@/components/course-gallery/templates/VideoTemplate";
import ImageMarkerTemplate from "@/components/course-gallery/templates/ImageMarkerTemplate";
import QuestionTemplate from "@/components/course-gallery/templates/QuestionTemplate";
import FileTemplate from "@/components/course-gallery/templates/FileTemplate";
import UserCertificateTemplate from "@/components/course-gallery/templates/UserCertificateTemplate";
import {QuillEditor} from "@vueup/vue-quill";

export default {
  name: "CourseGallery",
  components: {
    UserCertificateTemplate, FileTemplate, QuestionTemplate, ImageMarkerTemplate, VideoTemplate, QuillEditor, Modal
  },
  mixins: [SweetAlerts],
  props: {
    courseProp: Array,
    activeModule: Object,
    activeTemplate: Object,
    learningProgress: Number,
    courseDataProp: Array,
    selectedPersonId: String,
    isLearningInfo: Boolean,
  },

  data() {
    return {
      modalIsOpen: false,
      template: this.activeTemplate,
      questionFeedbackList: [],
      imageSrc: '',
      videoSrc: '',
      pdfSrc: '',
      audioSrc: '',
      s3KeyList: [],
      course: this.courseProp,
      courseData: this.courseDataProp,
      learningProgressData: this.learningProgress,
      isAnswerCorrect: false,
      showFeedback: false,
      disableChoice: false,
      noRemainingAttempts: false,
      remainingAttempts: 0,
      showDescription: true,
      clicked:false,
    }
  },

  computed: {
    submitEventButtonStatus() {
      return this.isAnswerCorrect === false;
    },
  },

  methods: {

    getRemainingAttempts() {

      // Do we have the questions array inside courseData.progress ?
      if (this.courseData.progress.questions) {
        let foundItem = false;

        // Iterating through all questions
        for (let question of this.courseData.progress.questions) {

          // Is the questionId equal templateId ?
          if (question.id === this.template.id) {

            // Returning the remaining attempts of the current question.
            foundItem = true;
            return question.remainingAttempts;
          }
        }
        // Return the maxAttempts of the question if we didn't find
        // the current questionId
        if (!foundItem) {
          return this.template.editorData.maxAttempts;
        }
      } else {
        return this.template.editorData.maxAttempts;
      }
    },

    answerIsCorrectMethod(answerIsCorrect){
      this.isAnswerCorrect = answerIsCorrect;
    },

    disableChoiceMethod(disableChoice){
      this.disableChoice = disableChoice;
    },

    showFeedbackMethod(showFeedback){
      this.showFeedback = showFeedback;
    },

    clickedMethod(clicked){
      this.clicked = clicked;
    },

    goToNextTemplateAndEnableChoices() {
      if (this.template.type === "question" || this.template.type === "imageMarker") {
        if (this.isAnswerCorrect) {
          this.goToNextTemplate();
        }
      }
    },

    galleryClosed() {
      this.modalIsOpen = false;
      this.$emit('gallery-closed');
    },

    showCurrentIndex() {
      let currentTemplateIndex = this.activeModule.courseTemplates.indexOf(this.template);
      return currentTemplateIndex + 1;
    },

    showMaximumIndex() {
      return this.activeModule.courseTemplates.length;
    },

    goToPreviousTemplate() {
      const currentTemplateIndex = this.activeModule.courseTemplates.indexOf(this.template);
      if (currentTemplateIndex > 0) {
        this.template = this.activeModule.courseTemplates[currentTemplateIndex - 1];
        if (this.template.type === "text") {
          this.loadQuillEditor();
        }
        if (this.template.type === "image") {
          this.setInstructionImageSrc();
        }
        if (this.template.type === "video") {
          if (this.template.editorData.videoType === "locale") {
            this.setInstructionVideoSrc();
          }
        }
        if (this.template.type === "pdf") {
          this.setInstructionPDFSrc();
        }
        if (this.template.type === "audio") {
          this.setInstructionAudioSrc();
        }
        if (this.template.type === "file") {
          const prefix = "course/" + this.course.id + "/" + this.activeModule.id + "/" + this.template.id;
          this.listFileObjects(prefix).then(objects => {
            this.s3KeyList = objects;
          })
        }
        if (this.template.type === "userCertificate") {
          this.isAnswerCorrect = true;
          let prefix = "course/" + this.course.id + "/" + this.activeModule.id + "/" + this.template.id + "/userInput/" + this.$store.getters.username;
          if (this.isLearningInfo) {
            prefix = "course/" + this.course.id + "/" + this.activeModule.id + "/" + this.template.id + "/userInput/" + this.selectedPersonId;
          }
          this.listFileObjects(prefix).then(objects => {
            this.s3KeyList = objects;
          })
        }
        if (this.template.type === "imageMarker") {
          this.isAnswerCorrect = false;
          this.clicked = false;
          this.remainingAttempts = this.getRemainingAttempts();
          if (this.remainingAttempts < 1) {
            this.isAnswerCorrect = true;
          }
          this.setInstructionImageSrc();
        }
        if (this.template.type === "question") {
          this.showFeedback = false;
          this.disableChoice = false;
          this.isAnswerCorrect = false;
          this.noRemainingAttempts = false;
          for (let items of this.template.editorData.answerList) {
            items.isChecked = false;
          }
          this.questionFeedbackList = [];
          this.remainingAttempts = this.getRemainingAttempts();
          if (this.remainingAttempts < 1) {
            this.noRemainingAttempts = true;
            this.isAnswerCorrect = true;
            this.disableChoice = true;
          }
        }
      } else {
        this.galleryClosed();
      }
    },

    getTotalTemplateCount(){
      let totalCount = 0;
      if (this.courseData.course.courseModules){
        for (let module of this.courseData.course.courseModules){
          totalCount = totalCount + module.courseTemplates.length;
        }
      }

      // as we are starting with an index of 0, unless the course module is completely empty,
      // we have to remove one step to reflect the total amount of templates as the progress starts
      // with the integer 1.
      if (totalCount !== 0){
        totalCount = totalCount - 1
      }

      return totalCount;
    },

    goToNextTemplate() {
      const currentTemplateIndex = this.activeModule.courseTemplates.indexOf(this.template);

      // check if we need to send an update information, if the current template index does exceed
      // or match our learning progress data.
      if (this.templateCounter(this.activeModule, currentTemplateIndex) >= this.learningProgressData) {
        // now check if we have reached the end of the course. If so, we send a
        // completion timestamp to the server.
        if (this.learningProgressData >= this.getTotalTemplateCount()) {
          this.updateLearningProgress(true);
        } else {
          this.updateLearningProgress(false);
        }
      }

      if (currentTemplateIndex < this.activeModule.courseTemplates.length - 1) {
        this.template = this.activeModule.courseTemplates[currentTemplateIndex + 1];
        if (this.template.type === "text") {
          this.loadQuillEditor();
        }
        if (this.template.type === "image") {
          this.setInstructionImageSrc();
        }
        if (this.template.type === "imageMarker") {
          this.isAnswerCorrect = false;
          this.clicked = false;
          this.remainingAttempts = this.getRemainingAttempts();
          if (this.remainingAttempts < 1) {
            this.isAnswerCorrect = true;
          }
          this.setInstructionImageSrc();
        }
        if (this.template.type === "video") {
          if (this.template.editorData.videoType === "locale") {
            this.setInstructionVideoSrc();
          }
        }
        if (this.template.type === "pdf") {
          this.setInstructionPDFSrc();
        }
        if (this.template.type === "audio") {
          this.setInstructionAudioSrc();
        }
        if (this.template.type === "file") {
          const prefix = "course/" + this.course.id + "/" + this.activeModule.id + "/" + this.template.id;
          this.listFileObjects(prefix).then(objects => {
            this.s3KeyList = objects;
          })
        }
        if (this.template.type === "userCertificate") {
          this.isAnswerCorrect = true;
          let prefix = "course/" + this.course.id + "/" + this.activeModule.id + "/" + this.template.id + "/userInput/" + this.$store.getters.username;
          if (this.isLearningInfo) {
            prefix = "course/" + this.course.id + "/" + this.activeModule.id + "/" + this.template.id + "/userInput/" + this.selectedPersonId;
          }
          this.listFileObjects(prefix).then(objects => {
            this.s3KeyList = objects;
          })
        }
        // If template = question, resetting booleans and unchecking clicked answers.
        if (this.template.type === "question") {
          this.showFeedback = false;
          this.disableChoice = false;
          this.isAnswerCorrect = false;
          this.noRemainingAttempts = false;
          for (let items of this.template.editorData.answerList) {
            items.isChecked = false;
          }
          this.questionFeedbackList = [];
          this.remainingAttempts = this.getRemainingAttempts();
          // If there are no attempts, isAnswerCorrect is set as true so that the user can go to the next template.
          if (this.remainingAttempts < 1) {
            this.noRemainingAttempts = true;
            this.disableChoice = true;
            this.isAnswerCorrect = true;
          }
        }
      } else {
        if (this.learningProgress === this.templateCounter(this.activeModule, currentTemplateIndex)) {

          for (let module of this.course.courseModules) {
            const moduleIndex = this.course.courseModules.indexOf(module);
            if (module.id === this.activeModule.id) {
              const nextModule = this.course.courseModules[moduleIndex + 1];
              // checking if there is another module afterwards.
              if (nextModule === undefined) {
                // Notifying the user that the course is finished.
                this.showSuccessMessage(this.$t('finishedLastModuleMessage'))
                this.galleryClosed();
                this.$store.commit("completionTimestamp", this.course.id);
              } else {
                // Another module exists, asking the user if they want to start with the next module.
                const nextTemplate = nextModule.courseTemplates[0];
                this.$swal({
                  title: this.$t('finishedModuleMessage'),
                  text: this.$t('startNextModuleMessage'),
                  confirmButtonColor: "#007AC2",
                  confirmButtonText: this.$t('confirm'),
                  showCancelButton: true,
                  cancelButtonText: this.$t('button.cancel'),
                }).then((res) => {
                  if (res.isConfirmed) {
                    this.$emit("open-next-module", nextModule, nextTemplate);
                  } else {
                    this.galleryClosed();
                  }
                });
              }
            }
          }
        } else {
          this.galleryClosed();
        }
      }
    },

    emitLearningProgressChanged() {
      this.$emit("learningProgressChanged", this.learningProgressData);
    },

    /**
     * Returns us the actual position of the template index, regardless
     * of the module it is in. Will be a continuous number.
     * @param module
     * @param templateIndex
     * @returns {*}
     */
    templateCounter(module, templateIndex) {
      // first we have to check if we have more than one module
      const moduleCount = this.course.courseModules.length;

      if (moduleCount > 0) {
        // yes we have more than one module. In which module are we?
        const index = this.course.courseModules.indexOf(module);
        // if we are at least in the 2nd module, we need to add all
        // templates of the 1st module and so on.
        if (index > 0) {
          // now iterate through all previous modules
          let prevTemplateCount = 0;
          for (let i = 1; i <= index; i++) {
            prevTemplateCount = prevTemplateCount + this.course.courseModules[index - i].courseTemplates.length;
          }
          return prevTemplateCount + templateIndex;
        } else {
          // we are still inside the first module, just return the template index
          return templateIndex;
        }
      } else {
        // we only have one module - we can just return the template index.
        return templateIndex;
      }
    },

    // when we go to the next template, also need to update
    // the learning progress
    updateLearningProgress(isCompleted) {
      this.learningProgressData++;

      this.emitLearningProgressChanged();

      let postObject = {};

      if (isCompleted) {
        postObject = {
          "courseId": this.course.id,
          "learningProgress": this.learningProgressData.toString(),
          "userId": this.$store.getters.username,
          "completionTimestamp": new Date().getTime().toString()
        }
      } else {
        postObject = {
          "courseId": this.course.id,
          "learningProgress": this.learningProgressData.toString(),
          "userId": this.$store.getters.username,
        }
      }

      this.$store.dispatch("postRequest", {
        "param": "updateLearningProgress",
        "payload": postObject
      })
    },

    loadQuillEditor() {
      setTimeout(() => {
        if(this.template["editorData"]) this.$refs.myQuillEditor.setContents(this.template["editorData"].ops);
      }, 1500);
    },

    // our instruction image will always be at the same place. And there is no
    // possibility that this is empty.
    setInstructionImageSrc() {
      const s3FileManager = new S3FileManager();
      const url = "course/" + this.course.id + "/" + this.activeModule.id + "/" + this.template.id + "/image.jpeg";
      s3FileManager.getSignedURL(url).then(signedUrl => {
        this.imageSrc = signedUrl;
      })
    },

    setInstructionPDFSrc() {
      const s3FileManager = new S3FileManager();
      const url = "course/" + this.course.id + "/" + this.activeModule.id + "/" + this.template.id + "/template.pdf";
      s3FileManager.getSignedURL(url).then(signedUrl => {
        this.pdfSrc = signedUrl;
      })
    },

    setInstructionAudioSrc() {
      const s3FileManager = new S3FileManager();
      const url = "course/" + this.course.id + "/" + this.activeModule.id + "/" + this.template.id + "/audio.mp4";
      s3FileManager.getSignedURL(url).then(signedUrl => {
        this.audioSrc = signedUrl;
      })
    },

    setInstructionVideoSrc() {
      const s3FileManager = new S3FileManager();
      const url = "course/" + this.course.id + "/" + this.activeModule.id + "/" + this.template.id + "/video.mp4";
      s3FileManager.getSignedURL(url).then(signedUrl => {
        this.videoSrc = signedUrl;
      })
    },

    listFileObjects(prefix) {
      return new Promise((resolve => {
        const s3FileManager = new S3FileManager();
        s3FileManager.checkIfListExists(prefix).then(res => {
          resolve(res);
        })
      }))
    },

    setUpTemplate() {
      this.learningProgressData = this.learningProgress;

      if (this.template) {
        if (this.template.type === "text") {
          this.loadQuillEditor();
        }

        if (this.template.type === "image") {
          this.setInstructionImageSrc();
        }

        if (this.template.type === "imageMarker") {
          this.setInstructionImageSrc();
          this.remainingAttempts = this.getRemainingAttempts();
          if (this.remainingAttempts < 1) {
            this.isAnswerCorrect = true;
          }
        }

        if (this.template.type.toLowerCase() === "pdf") {
          this.setInstructionPDFSrc();
        }

        if (this.template.type === "audio") {
          this.setInstructionAudioSrc();
        }

        if (this.template.type === "video") {
          if (this.template.editorData.videoType === "locale") {
            this.setInstructionVideoSrc();
          }
        }

        if (this.template.type === "file") {
          const prefix = "course/" + this.course.id + "/" + this.activeModule.id + "/" + this.template.id;
          this.listFileObjects(prefix).then(objects => {
            this.s3KeyList = objects;
          })
        }
        if (this.template.type === "userCertificate") {
          this.isAnswerCorrect = true;
          let prefix = "course/" + this.course.id + "/" + this.activeModule.id + "/" + this.template.id + "/userInput/" + this.$store.getters.username;
          if (this.isLearningInfo) {
            prefix = "course/" + this.course.id + "/" + this.activeModule.id + "/" + this.template.id + "/userInput/" + this.selectedPersonId;
          }
          if (prefix !== "") {
            this.listFileObjects(prefix).then(objects => {
              this.s3KeyList = objects;
            })
          }
        }
        if (this.template.type === "question") {
          this.showFeedback = false
          for (let items of this.template.editorData.answerList) {
            items.isChecked = false;
          }
          this.questionFeedbackList = [];
          this.remainingAttempts = this.getRemainingAttempts();
          if (this.remainingAttempts < 1) {
            this.noRemainingAttempts = true;
            this.isAnswerCorrect = true;
            this.disableChoice = true;
          }
        }
      }
    },
  },

  mounted() {
    this.setUpTemplate();
  },

  watch: {
    activeTemplate() {
      this.template = this.activeTemplate;

      this.setUpTemplate();
    },
    // Don't delete the watcher.
    courseDataProp() {
      this.courseData = this.courseDataProp;
    },
  }
}

</script>

<style scoped>

.iframe-container, .image-container {
  border: 1px solid lightgrey;
  border-radius: 4px;
  position: absolute;
  box-sizing: border-box;
  right: 40px;
  left: 40px;
  bottom: 100px;
  top: 120px;
  margin-top: 20px;
}

iframe {
  margin: 0;
  padding: 0;
  height: 100%;
  border: none;
  display: block;
  width: 100%;
}

.image-container button {
  position: absolute;
  right: -1px;
  top: -15px;
}

.image {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 4px;
}

.downloadBtn:hover svg {
  fill: var(--primary-color);
}

.question-card {
  padding: 40px;
  background-color: var(--brightgrey);
  border-radius: 4px;
  width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 65vh;
  overflow: auto;
}

.question-card h3 {
  margin-bottom: 24px;
}

.checkbox-wrapper span {
  margin-left: 10px;
}

.question-content-container {
  border: 1px solid lightgrey;
  border-radius: 4px;
  position: absolute;
  box-sizing: border-box;
  right: 40px;
  left: 40px;
  bottom: 100px;
  top: 120px;
  margin-top: 20px;
  background-image: url("../../../src/assets/background_main.jpg");
  background-size: cover;
  background-position: center;

}

.quill-editor-container {
  border: 1px solid lightgrey;
  border-radius: 4px;
  position: absolute;
  box-sizing: border-box;
  right: 40px;
  left: 40px;
  bottom: 100px;
  top: 120px;
  margin-top: 20px;
}

/*noinspection CssUnusedSymbol*/
.text-color-red {
  color: forestgreen;
}

/*noinspection CssUnusedSymbol*/
.text-color-green {
  color: forestgreen;
}

.toggle-container:hover {
  cursor: pointer;
}

.toggle-container svg {
  fill: var(--primary-color);
  position: absolute;
  left: -3px;
  bottom: -12px;
  z-index: 2;
  width: 32px;
  height: 32px;
}

/*noinspection CssUnusedSymbol*/
.hidden-description-container {
  position: absolute;
}

/*noinspection CssUnusedSymbol*/
.contentDescription {
  padding: 0 10px;
}

.description-container {
  position: absolute;
  width: 300px;
}

.description-container span {
  padding: 10px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid #007AC2;
  position: absolute;
  z-index: 1;
  left: 14px;
  min-width: 200px;
  min-height: auto;
  max-height: 50vh;
  overflow: auto;
}

.prev-next-btn-container {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  z-index: 1;
  align-items: center;
}

.prev-next-btn-container button {
  border-radius: 4px;
  border: none;
  color: var(--primary-color);
  background-color: var(--bright);
  margin-right: 10px;
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.prev-next-btn-container button:hover {
  text-decoration: underline;
}


@media screen and (max-width: 600px) {

  .question-content-container {
    background-color: var(--brightgrey);
    background-image: none;
  }

  .question-card {
    width: 100%;
    height: 100%;
    position: unset;
    transform: none;
    padding: 20px;
  }

  .prev-next-btn-container button {
    margin: 0;
  }

  .question-content-container, .image-container, .iframe-container {
    right: 20px;
    left: 20px;
    bottom: 120px;
    top: 100px;
  }

  .hidden-description-container {
    position: unset;
  }

  .prev-next-btn-container {
    position: fixed;
    bottom: 100px;
    transform: translate(-50%);
    left: 50%;
    width: 100%;
  }

  .quill-editor-container{
    right: 20px;
    left: 20px;
    bottom: 120px;
    top: 100px;
  }

}

@media screen and (max-width: 600px) {
  .retryBtn span {
    display: none;
  }
}

.textcenter {
  text-align: center;
}
</style>