<template>
  <div>
      <div @dragover.prevent @dragenter.prevent @drop="onModuleDrop($event, moduleIndex)" draggable="true"
            class="template-list" v-for="(module, moduleIndex) in this.course.courseModules" :key="module"
           @dragstart="startModuleDrag($event, module)">
        <div v-if="this.isEditorView" class="action-btn-container">
          <button @click="emitDeleteModuleEvent(module)" class="delete-module-btn">
            <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/></svg>
            <span>{{$t('deleteModule')}}</span>
          </button>
          <button class="add-template-btn" @click="this.emitAddModuleEvent(module)">
            <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#007AC2"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"/></svg>
            <span>{{$t('addTemplate')}}</span>
          </button>
        </div>
        <div>
          <span class="module-header-title">
            <span class="list-counter">0{{ moduleIndex+1 }}</span>
            <span class="list-title">{{module.title}}</span>
            <div v-if="this.isEditorView">
            <button class="add-template-btn" @click="this.emitModuleNameChange(module)">
            <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 48 48" width="20px" fill="#007AC2"><path d="M6 42V35.6L35.4 6.2Q36.25 5.35 37.525 5.375Q38.8 5.4 39.65 6.25L41.8 8.4Q42.65 9.25 42.65 10.5Q42.65 11.75 41.8 12.6L12.4 42ZM37.5 12.45 39.5 10.45 37.45 8.4 35.45 10.4Z"/></svg>  </button>
              </div>
          </span>
          <span class="list-description">{{module.description}}</span>
        </div>
        <div>
          <div class="template-container">
              <div @dragover.prevent @dragenter.prevent @drop="onTemplateDrop($event, templateIndex, moduleIndex)" draggable="true" :class="{'opacity': this.learningProgress < templateCounter(module, templateIndex)}" :title="item.title"
                   @click="()=>emitTemplateClickedEvent(module, item, templateIndex)" @dragstart="startTemplateDrag($event, item)"
                   style="position: relative;" class="template-wrapper" v-for="(item, templateIndex) in module.courseTemplates" :key="templateIndex">
                <div>
                  <span v-if="this.isEditorView" @click="deleteTemplate(module, item)" class="close-icon" >
                    <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
                  </span>
                  <span class="counter">{{ templateIndex+1 }}</span>
                  <p class="template-title">{{item.title}}</p>
                  <!--text-->
                  <span style="position:absolute; top: 0; left: 0; opacity: 0.2;" class="template-type-wrapper" v-if="item.type === 'text'">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#555"><path d="M0 0h24v24H0z" fill="none"/><path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 9H13z"/></svg>
                  </span>
                  <!--image-->
                  <span style="position:absolute; top: 0; left: 0; opacity: 0.2;" class="template-type-wrapper" v-if="item.type === 'image'">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#555"><path d="M0 0h24v24H0z" fill="none"/><path d="M21 19V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zM8.5 13.5l2.5 3.01L14.5 12l4.5 6H5l3.5-4.5z"/></svg>
                  </span>
                  <!--PDF-->
                  <span style="" class="template-type-wrapper" v-if="item.type === 'pdf'">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M20 2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8.5 7.5c0 .83-.67 1.5-1.5 1.5H9v2H7.5V7H10c.83 0 1.5.67 1.5 1.5v1zm5 2c0 .83-.67 1.5-1.5 1.5h-2.5V7H15c.83 0 1.5.67 1.5 1.5v3zm4-3H19v1h1.5V11H19v2h-1.5V7h3v1.5zM9 9.5h1v-1H9v1zM4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm10 5.5h1v-3h-1v3z"/></svg>
                  </span>
                  <!--File-->
                  <span style="" class="template-type-wrapper" v-if="item.type === 'file'">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#555"><path d="M0 0h24v24H0z" fill="none"/><path d="M16.5 6v11.5c0 2.21-1.79 4-4 4s-4-1.79-4-4V5c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5v10.5c0 .55-.45 1-1 1s-1-.45-1-1V6H10v9.5c0 1.38 1.12 2.5 2.5 2.5s2.5-1.12 2.5-2.5V5c0-2.21-1.79-4-4-4S7 2.79 7 5v12.5c0 3.04 2.46 5.5 5.5 5.5s5.5-2.46 5.5-5.5V6h-1.5z"/></svg>
                  </span>
                  <!--Audio-->
                  <span style="" class="template-type-wrapper" v-if="item.type === 'audio'">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#555"><path d="M0 0h24v24H0z" fill="none"/><path d="M10 21Q8.35 21 7.175 19.825Q6 18.65 6 17Q6 15.35 7.175 14.175Q8.35 13 10 13Q10.575 13 11.062 13.137Q11.55 13.275 12 13.55V3H18V7H14V17Q14 18.65 12.825 19.825Q11.65 21 10 21Z"/></svg>
                  </span>
                  <!--UserCertificate-->
                  <span style="" class="template-type-wrapper" v-if="item.type === 'userCertificate'">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#555"><path d="M0 0h24v24H0z" fill="none"/><path d="M16.5 6v11.5c0 2.21-1.79 4-4 4s-4-1.79-4-4V5c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5v10.5c0 .55-.45 1-1 1s-1-.45-1-1V6H10v9.5c0 1.38 1.12 2.5 2.5 2.5s2.5-1.12 2.5-2.5V5c0-2.21-1.79-4-4-4S7 2.79 7 5v12.5c0 3.04 2.46 5.5 5.5 5.5s5.5-2.46 5.5-5.5V6h-1.5z"/></svg>
                  </span>
                  <!--video-->
                  <span style="position:absolute; top: 0; left: 0; opacity: 0.2;" class="template-type-wrapper" v-if="item.type === 'video'">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#555"><g><rect fill="none" height="24" width="24" y="0"/></g><g><path d="M20,4H4C2.9,4,2,4.9,2,6v12c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V6C22,4.9,21.1,4,20,4z M9.5,16.5v-9l7,4.5L9.5,16.5z"/></g></svg>
                  </span>
                  <!--question-->
                  <span style="position:absolute; top: 0; left: 0; opacity: 0.2;" class="template-type-wrapper" v-if="item.type === 'question'">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#555"><g><path d="M0,0h24v24H0V0z" fill="none"/></g><g><g><path d="M4,6H2v14c0,1.1,0.9,2,2,2h14v-2H4V6z"/><path d="M20,2H8C6.9,2,6,2.9,6,4v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V4C22,2.9,21.1,2,20,2z M14.01,15 c-0.59,0-1.05-0.47-1.05-1.05c0-0.59,0.47-1.04,1.05-1.04c0.59,0,1.04,0.45,1.04,1.04C15.04,14.53,14.6,15,14.01,15z M16.51,8.83 c-0.63,0.93-1.23,1.21-1.56,1.81c-0.13,0.24-0.18,0.4-0.18,1.18h-1.52c0-0.41-0.06-1.08,0.26-1.65c0.41-0.73,1.18-1.16,1.63-1.8 c0.48-0.68,0.21-1.94-1.14-1.94c-0.88,0-1.32,0.67-1.5,1.23l-1.37-0.57C11.51,5.96,12.52,5,13.99,5c1.23,0,2.08,0.56,2.51,1.26 C16.87,6.87,17.08,7.99,16.51,8.83z"/></g></g></svg>
                  </span>
                  <!--image-marker-->
                  <span style="position:absolute; top: 0; left: 0; opacity: 0.2;" class="template-type-wrapper" v-if="item.type === 'imageMarker'">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#555"><path d="M0 0h24v24H0z" fill="none"/><path d="M21 19V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zM8.5 13.5l2.5 3.01L14.5 12l4.5 6H5l3.5-4.5z"/></svg>
                  </span>
                  <span class="classIcon" v-if= "this.learningProgress  > templateCounter(module, templateIndex) ">
                    <svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 0 24 24" width="14px" fill="green"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/></svg>
                  </span>
                  <span class="classIcon" v-if= "this.learningProgress === templateCounter(module, templateIndex)">
                    <svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 0 24 24" width="18px" fill="orange"><path d="M0 0h24v24H0z" fill="none"/><path d="M5 13.18v4L12 21l7-3.82v-4L12 17l-7-3.82zM12 3L1 9l11 6 9-4.91V17h2V9L12 3z"/></svg>
                  </span>
                </div>
            </div>
          </div>
        </div>
      </div>
  </div>

</template>

<script>

import SweetAlerts from "../../mixins/sweet-alerts/SweetAlerts";

export default {
  name: "content-preview-container",
  mixins: [SweetAlerts],
  props:{
    // a number, telling us at which step we currently are.
    // this is important to know when the course is finished.
    learningProgress: Number,
    // we also receive the complete course object in here,
    // so that we know at which position our module / template is.
    course: {},
    isEditorView: Boolean
  },

  methods:{
    /**
     * Returns us the actual position of the template index, regardless
     * of the module it is in. Will be a continuous number.
     * @param module
     * @param templateIndex
     * @returns {*}
     */
    templateCounter(module, templateIndex){
      // first we have to check if we have more than one module
      const moduleCount = this.course.courseModules.length;
      if (moduleCount > 0){
        // yes we have more than one module. In which module are we?
        const index = this.course.courseModules.indexOf(module);
        // if we are at least in the 2nd module, we need to add all
        // templates of the 1st module and so on.
        if (index > 0){
          // now iterate through all previous modules
          let prevTemplateCount = 0;
          for (let i = 1; i <= index; i++){
            prevTemplateCount = prevTemplateCount + this.course.courseModules[index - i].courseTemplates.length;
          }
          return prevTemplateCount + templateIndex;
        } else {
          // we are still inside the first module, just return the template index
          return templateIndex;
        }
      } else {
        // we only have one module - we can just return the template index.
        return templateIndex;
      }
    },

    startModuleDrag (evt, item) {
      if (this.isEditorView){
        evt.dataTransfer.dropEffect = 'move'
        evt.dataTransfer.effectAllowed = 'move'
        evt.dataTransfer.setData('moduleId', item.id)
      }
    },

    startTemplateDrag (evt, item) {
      if (this.isEditorView){
        evt.dataTransfer.dropEffect = 'move'
        evt.dataTransfer.effectAllowed = 'move'
        evt.dataTransfer.setData('templateId', item.id)
      }
    },

    onTemplateDrop (evt,targetIndex, moduleIndex) {
      if (this.isEditorView){
        // get the id of the dragged template.
        const draggedTemplateId = evt.dataTransfer.getData('templateId');
        // get the item from the list by its id.

        // get the templates list we are currently modifying.
        const selectedTemplates = this.course.courseModules[moduleIndex].courseTemplates;

        let draggedElmntIndex = 0;
        for (let template of selectedTemplates){
          if (template.id === draggedTemplateId){
            // now we found the complete item we dragged. We can splice it from its current
            // position and move it to the target index.
            selectedTemplates.splice(draggedElmntIndex, 1);
            selectedTemplates.splice(targetIndex, 0, template);
          }
          draggedElmntIndex++;
        }
      }
    },

    onModuleDrop(evt, moduleIndex){
      if (this.isEditorView){
        // get the id of the dragged template.
        const draggedModuleId = evt.dataTransfer.getData('moduleId');

        // get the index of the draggedModule
        let indexCounter = 0;
        for (let module of this.course.courseModules){
          if (module.id === draggedModuleId){
            const event = {
              "moveModuleIndex": indexCounter,
              "toModuleIndex": moduleIndex,
              "movedModule": module
            }

            this.$emit("switch-modules", event);
            break;
            // todo: dispatch the event to shift the modules.
          }
          indexCounter++;
        }
      }

    },

    /**
     * When a template gets clicked, we want to access it inside the
     * course gallery. The pre-condition is that our learning progress
     * is greater or equal to the template counter. Otherwise, we have
     * to complete the previous step(s) first.
     * @param module
     * @param template
     * @param templateIndex
     */
    emitTemplateClickedEvent(module, template, templateIndex){
      // if the index exceeds the learning progress,
      // we do not allow move further.
      if (this.templateCounter(module,templateIndex) > this.learningProgress){
        this.showInfoMessage(this.$t('learningProgress'), this.$t('finishPrevStep') + template.title + "." + this.$t('lookForLearningIcon'));
      } else {
        this.$emit('template-clicked', module, template);
      }
    },

    /**
     * Emits delete template event to inform the parent component
     * that this date entry is supposed to be deleted.
    */
    emitModuleUpdatedEvent(module){
      this.$emit('module-updated', module);
    },

    emitDeleteModuleEvent(module){
      this.$emit('delete-module', module);
    },

    emitAddModuleEvent(module){
      this.$emit('add-module', module);
    },

    emitModuleNameChange(module){
      this.$emit('module-name-changed',module);
    },

    /**
     * If a template gets deleted, we will show a dialogue and ask the
     * user to confirm the deletion process. If the deletion process
     * is confirmed, we will emit a delete event.
     */
    deleteTemplate(module, template) {
      this.showConfirmDialogue(this.$t('deleteTemplate'), this.$t('swal.confirmDelete')).then(res => {
        if (res) {
          this.removeTemplateFromModule(module, template);
          this.emitModuleUpdatedEvent(module);
        }
      })
    },

    /**
     * Update the DOM.
     * @param module
     * @param template
     */
    removeTemplateFromModule(module, template){
      const templateIndex = module.courseTemplates.indexOf(template);
      module.courseTemplates.splice(templateIndex,1);
    },
  },

}
</script>

<style scoped>

.module-header-title{
  margin-bottom: 24px;
  display: flex;
  align-items: center;
}

.counter{
  position: absolute;
  left: 0;
  top: 0;
  font-size: 12px;
}

.template-type-wrapper svg{
  width: 50%;
  margin-top: 15px;
}

.template-wrapper:hover{
  border-color: var(--primary-color);
  cursor: pointer;
}

.template-wrapper:hover .template-title{
  text-decoration: underline;
}

.template-type-wrapper{
  position:absolute;
  top: 0;
  left: 0;
  opacity: 0.2;
}

.delete-module-btn{
  font-size: 12px;
  border: 2px solid var(--delete);
  border-radius: 4px;
  color: var(--delete);
  background-color: transparent;
  margin-right:5px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 5px 10px;
}

.delete-module-btn svg{
  fill: var(--delete);
  margin-left: 3px;
}

.delete-module-btn:hover{
  background-color: var(--delete);
  color: var(--bright);
}

.delete-module-btn:hover svg{
  fill: var(--bright);
}

.add-template-btn{
  border: 2px solid var(--primary-color);
  border-radius: 4px;
  color: var(--primary-color);
  background-color: transparent;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 5px 10px;
  font-size: 12px;
}

.add-template-btn svg{
  margin-left: 3px;
}

.add-template-btn:hover{
  background-color: var(--primary-color);
  color: var(--bright);
}

.add-template-btn:hover svg{
  fill: var(--bright);
}

.classIcon{
  position: absolute;
  top: 0;
  right: 0;
}
.close-icon{
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  z-index: 1001;
}

.close-icon:hover{
  opacity: 1;
  cursor: pointer;
}

.template-title{
  font-size: 12px;
  color: var(--primary-color);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.action-btn-container{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.template-container{
  display:flex;
  overflow: auto;
  flex-direction: row;
}

.template-wrapper{
  position: relative;
  border:4px solid var(--brightgrey);
  background-color: var(--bright);
  box-shadow: 0 0 2px rgb(169 191 203);
  height:150px;
  padding:10px;
  box-sizing: border-box;
  width:150px;
  min-width: 150px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  border-radius: 4px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.template-wrapper:last-child{
  margin-right: 0;
}

.template-list{
  padding: 24px;
  border: 3px solid var(--brightgrey);
}

.template-list:nth-child(odd){
  background-color: var(--brightgrey);
}

.template-list:last-child{
  margin-bottom: 80px;
}

.list-counter{
  font-size: 20px;
  margin-right: 24px;
  border: 1px solid grey;
  border-radius: 4px;
  padding: 4px;
}

.list-title{
  color: var(--darkgrey);
  font-size: 24px;
  margin-right: 10px;
}

.list-description{
  display: block;
  font-size: 14px;
  margin-left: 51px;
  overflow: auto;
}

@media screen and (max-width: 800px) {

  .template-list{
    padding: 24px 0;
    border: none;
    border-bottom: 3px solid var(--brightgrey);
  }

  .template-list:nth-child(odd){
    background-color: transparent;
  }

  .module-header-title{
    align-items: flex-start;
    flex-direction: column;
  }

  .list-counter{
    font-size: 16px;
    margin-right: 10px;
    padding: 2px;
    margin-bottom: 5px;
  }

  .list-title{
    font-size: 18px;
  }

  .delete-module-btn, .add-template-btn{
    border: none;
    padding: 0;
  }

  .delete-module-btn span, .add-template-btn span{
    display: none;
  }

  .delete-module-btn svg, .add-template-btn svg {
    width: 30px;
    height: 30px;
  }

  .delete-module-btn svg {
    fill: var(--delete);
  }

  .add-template-btn svg {
    fill: var(--primary-color);
  }

  .delete-module-btn:hover svg {
    fill: var(--delete);
  }

  .add-template-btn:hover svg {
    fill: var(--primary-color);
  }

  .delete-module-btn:hover, .add-template-btn:hover{
    background-color: transparent;
  }

  .delete-module-btn:hover svg, .add-template-btn:hover svg{
    opacity: 0.8;
  }

}

</style>
