<template>
  <a class="option-items" @click="clickCallback">
    <img :src=iconSrc alt="">
    <a>{{label}}</a>
  </a>
</template>

<script>
/**
 * how to show params  in vue
 * @param {label: String}
 */
export default {
  name: "AccountOption",
  props:{
    label: String,
    iconSrc: String,
    clickCallback: Function
  }
}


</script>

<style scoped>
  .option-items{
    display: flex;
    align-items: center;
    padding: 8px 12px;
    border-bottom: 1px solid var(--brightgrey);
  }

  .option-items img{
    margin-right: 12px;
  }

  .option-items:hover{
    box-shadow: 0 0 5px rgb(169 191 203);
  }
</style>
