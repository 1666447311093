<template>
  <div style="overflow-x:auto; margin-top: 12px;">
    <div class="input-container">
      <!--suppress HtmlFormInputWithoutLabel -->
      <div>
        <input type="search" :placeholder="this.$t('placeholderSearch')" v-model="searchQuery">
      </div>
      <div>
        <span class="clickable" style="margin-left: 10px" @click="exportToExcel">
          <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 0 40 40" width="40"><path d="M20 26.875 11.875 18.708 13.833 16.708 18.625 21.5V6.667H21.375V21.5L26.167 16.708L28.125 18.708ZM9.458 33.333Q8.333 33.333 7.5 32.5Q6.667 31.667 6.667 30.542V24.917H9.458V30.542Q9.458 30.542 9.458 30.542Q9.458 30.542 9.458 30.542H30.542Q30.542 30.542 30.542 30.542Q30.542 30.542 30.542 30.542V24.917H33.333V30.542Q33.333 31.667 32.5 32.5Q31.667 33.333 30.542 33.333Z"/></svg>
        </span>
      </div>
    </div>
    <div style="max-height: 60vh; overflow-y:auto">
      <table>
        <thead>
        <tr>
          <th></th>
          <th class="table-header"  v-for="(item, index) in tableRowHeadersData"  :key="item" @click="sort(index)" :class="[sortBy === index ? sortDirection : '']">{{item.label}}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item) of resultQuery" :key="item" >
          <td>
          <span v-if="itemDelete" class="delete-icon" @click="emitDeleteItem(item)">
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#555"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9zm7.5-5l-1-1h-5l-1 1H5v2h14V4h-3.5z"/></svg>
              </span>
            <span v-if="itemEdit" class="view-icon" @click="emitEditItem(item)">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#555"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z"/></svg>
              </span>
            <span v-if="itemView" class="view-icon" @click="emitViewItem(item)">
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#555"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 6c3.79 0 7.17 2.13 8.82 5.5C19.17 14.87 15.79 17 12 17s-7.17-2.13-8.82-5.5C4.83 8.13 8.21 6 12 6m0-2C7 4 2.73 7.11 1 11.5 2.73 15.89 7 19 12 19s9.27-3.11 11-7.5C21.27 7.11 17 4 12 4zm0 5c1.38 0 2.5 1.12 2.5 2.5S13.38 14 12 14s-2.5-1.12-2.5-2.5S10.62 9 12 9m0-2c-2.48 0-4.5 2.02-4.5 4.5S9.52 16 12 16s4.5-2.02 4.5-4.5S14.48 7 12 7z"/></svg>
              </span>
          </td>
          <td v-for="(row) of item.cells" :key="row">{{row}}</td>
          <td v-for="(tag) of item.tags" :key="tag" class="tags">{{tag}}</td>
        </tr>
        </tbody>
      </table>
    </div>

  </div>

</template>

<script>

import * as XLSX from "xlsx";

export default {

  name: "MaterialTable",
  props:{
       tableHeaders: Array,
       tableRows: Array,
       itemView: Boolean,
       itemEdit: Boolean,
       itemDelete: Boolean,
       insideGroupTable:Boolean,
  },

  data(){
    return {
      sortBy: 'name',
      sortDirection: 'asc',
      searchQuery: null,
      tableRowData: [],
      tableRowHeadersData:[],
      itemViewIcon: false,
      itemEditIcon: false,
      itemDeleteIcon: false,
    }
  },

  computed:{
    sortedTableRowData(){
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.tableRowData.sort((p1,p2) => {
        let modifier = 1;
        if(this.sortDirection === 'desc') modifier = -1;
        if (p1[this.sortBy] < p2[this.sortBy]) return -1 * modifier;
        if (p1[this.sortBy] > p2[this.sortBy]) return modifier;
        if (p1.cells[this.sortBy]){
          if(p1.cells[this.sortBy].toString() < p2.cells[this.sortBy].toString()) return -1 * modifier;
          if(p1.cells[this.sortBy].toString() > p2.cells[this.sortBy].toString()) return modifier;
        }
        return 0;
      });
    },
    resultQuery() {
      if (this.searchQuery) {
        return this.sortedTableRowData.filter(item => {
          let isIncluded = false;
          for (let cell of item.cells){
            if (cell){
              isIncluded = cell.toLowerCase().includes(this.searchQuery.toLowerCase());
              if (isIncluded) break;
            }
          }

          // if we have a separate tag section, this key will exist.
          if (item.tags){
            for (let tag of item.tags){
              if (tag){
                isIncluded = tag.toLowerCase().includes(this.searchQuery.toLowerCase());
                if (isIncluded) break;
              }
            }
          }

          return isIncluded;

        })
      } else {
        return this.sortedTableRowData;
      }
    }
  },


  methods:{
    sort(s){
      if(s === this.sortBy) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      }
      this.sortBy = s;
    },

    emitDeleteItem(item){
      this.$emit("item-deleted", item);
    },

    emitEditItem(item){
      this.$emit("item-edited", item);
    },

    emitViewItem(item){
      this.$emit("item-viewed", item);
    },

    // Formatting the cells for Excel.
    createExcelDataFormat(){
      let data = [];
      // Iterating through all displayed items in the table.
      for (let item of this.resultQuery){
        let counter = 0;
        let innerData = {};
        // Iterating through all the cells of the items.
          for (let innerItem of item.cells) {
            // We set the data for the cell according to the label of the Header.
            innerData[this.tableRowHeadersData[counter].label] = innerItem;
            counter++;
          }
          // Pushing the innerData of the item.
        data.push(innerData);
      }
      return data;
    },

    // Exporting our Table into an Excel format
    exportToExcel(){
      this.$swal({
        title: this.$t('exportToExcel'),
        text: this.$t('exportToExcelText'),
        confirmButtonColor: "#007AC2",
        confirmButtonText: this.$t('confirm'),
        showCancelButton: true,
        cancelButtonText: this.$t('button.cancel'),
      }).then((res) => {
        if (res.isConfirmed) {
          let ws = XLSX.utils.json_to_sheet(this.createExcelDataFormat());
          let wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "data")
          let exportFileName = `workbook.xls`;
          XLSX.writeFile(wb, exportFileName)
        }
      });
    }
  },

  mounted() {
    if (this.tableHeaders){
      this.tableRowHeadersData = this.tableHeaders;
    }

    if (this.tableRows){
      this.tableRowData = this.tableRows;
    }

    if(this.itemDelete){
      this.itemDeleteIcon = this.itemDelete;
    }
    if(this.itemView){
      this.itemViewIcon = this.itemView;
    }
    if(this.itemEdit){
      this.itemEditIcon = this.itemEdit;
    }
  },

  // Watcher is needed!
  watch:{
    tableHeaders(){
      if (this.tableHeaders){
        this.tableRowHeadersData = this.tableHeaders;
      }
    },

    tableRows(){
      if (this.tableRows){
        this.tableRowData = this.tableRows;
      }
    }
  }
}
</script>

<style scoped>

.clickable{
  cursor: pointer
}

/*noinspection CssUnusedSymbol*/
.asc:after{
  content: "\25B2";
  font-size: 10px;
}

/*noinspection CssUnusedSymbol*/
.desc:after{
  content: "\25BC";
  font-size: 10px;
}

th{
  cursor: pointer;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  text-align: left;
  padding: 10px;
}

th:first-child{
  min-width: 107px;
}

th {
  border-bottom: 2px solid var(--darkgrey);
}

tr {
  border-bottom: 1px solid var(--brightgrey);
  white-space: nowrap;
}

.table-header{
  cursor: pointer;
}

.view-icon{
  margin-right: 5px;
}

.delete-icon{
  margin-right: 5px;
}

.delete-icon svg:hover{
  fill: red;
  cursor: pointer;
}

.view-icon svg:hover{
  fill: var(--primary-color);
  cursor: pointer;
}

.input-container{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.tags{
  background-color: green;
  color:white;
  font-size:12px;
  text-align: center;
  border-radius:25px;
  /*font-weight: bold;*/
  display: block;
  max-height: 30px;
  margin-bottom: 5px;
  margin-top:5px;
}

/*search filter styling*/
input {
  outline: none;
  border: none;
}

input[type=search] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  font-family: inherit;
  font-size: 100%;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button {
  display: none;
}

input[type=search] {
  background: url(https://static.tumblr.com/ftv85bp/MIXmud4tx/search-icon.png) no-repeat 9px center;
  border: solid 1px var(--brightgrey);
  padding: 5px 10px 5px 32px;


  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;

  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  transition: all .5s;
}

input[type=search]:focus {

  border-color: var(--primary-color);
}

input:-moz-placeholder {
  color: #999;
}
input::-webkit-input-placeholder {
  color: #999;
}

input[type=search] {
  padding-right: 10px;
  color: black;
  cursor: pointer;
}
input[type=search]:hover {
  background-color: #fff;
}
input[type=search]:focus {
  color: #000;
  background-color: #fff;
  cursor: auto;
}

@media screen and (max-width: 600px){
  input[type=search] {
    width: 100%;
    box-sizing: border-box;
    height: 40px;
  }

  .input-container{
    width: 100%;
    justify-content: flex-start;
  }
}

</style>
