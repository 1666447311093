<template>
  <div class="card-container">
    <!-- If the context menu btn should be display and we are admin, and we are
     inside our own domain, then get a dropdown with additional options, like deleting etc. -->
    <div v-if="displayContextMenuBtn && isAdmin && this.courseObject.domain === myDomainName" class="dropdown-container">
        <AppDropdown :dropdown-card-list="computedDropDownList"/>
    </div>
     <div style="padding:5px; display: flex; position: absolute; justify-content: flex-start; flex-direction: row">
      <div class="container-inactive" v-if="courseObject.course.publicationStatus === '10'">
        <span style="padding: 10px"> {{this.$t('inactive')}} </span>
      </div>
      <div v-if="this.courseObject.domain && !this.getDomainName.toLowerCase() === 'codevance'" class="organisation-tag">
        <span style="padding: 10px">{{getDomainName}}</span>
      </div>
      <div class="compulsory" v-if="courseObject.course.isCompulsory">
        <span style="padding: 10px">{{this.$t('compulsory.course')}}</span>
      </div>
    </div>
    <div class="img-container">
      <img style="width: 100%;
                  height: 150px;
                  border-top-right-radius: 5px;
                  border-top-left-radius: 5px;
                  filter: blur(2px);
                  opacity: 0.9;
                  -webkit-filter: blur(2px);" :src=courseObject.course.iconSrc alt="">
      <img style="left: 50%;
                  width: 100%;
                  transform: translate(-50%);
                  position: absolute;
                  top: 0;
                  height: 150px;
                  object-fit: contain;" :src=courseObject.course.iconSrc alt="">
    </div>
    <div @click=this.courseClicked() :title= courseObject.course.title class="inner-card-container">
      <div class="tags-container">
        <div class="tags" v-for="tag of courseObject.course.tags" :key="tag">
          <span>{{tag}}</span>
        </div>
      </div>
      <div class="card-header-container">
        <h3>{{ courseObject.course.title }}</h3>
      </div>
      <div class="card-wrapper">
        <span>
          {{ learningPercentage }}
        </span>
        <span>
          {{ computedDuration}} min
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import AppDropdown from "../../dropdown/AppDropdown";
import SweetAlerts from "@/mixins/sweet-alerts/SweetAlerts";
import {Config} from "../../../config";

export default {
  name: 'Card',
  components: {AppDropdown},
  mixins:[SweetAlerts],
  props: {
    displayContextMenuBtn: Boolean,
    courseObject: Object,
    learningProgress: Object,
    learningPercentage: Number,
    myDomainName:String
  },
  data(){
    return{
      isAdmin: false,
      top: 0,
      dropdownCardList: [],
    }
  },

  methods:{
    courseClicked(){
      this.$emit("courseClicked", this.courseObject.course);
    },
    requestEditCourse(){
      this.$emit("edit-course", this.courseObject.course);
    },
    requestDeleteCourse(){
      if (this.$store.getters.role.toLowerCase() === "admin"){
        this.$emit("delete-course", this.courseObject.course);
      } else {
        this.showErrorMessage(this.$t('rightsError'));
      }
    },
    minTwoDigits(n) {
      return (n < 10 ? '0' : '') + n;
    },
    setPosition(){
      if(this.courseObject.course.publicationStatus === '10'){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.top = 20 +'px';
      }else{
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.top = 0 + 'px';
      }
    },
  },

  computed:{
    computedDuration(){
      return this.courseObject.course.duration ? this.minTwoDigits(this.courseObject.course.duration) : '';
    },
    getDomainName(){
      if (this.courseObject.domain){
        // if we are not in a default domain, then we show if
        // the course is related to GPM or GSAG.
        if (Config.Names.domain.toLowerCase() === "gleason"){
          return this.courseObject.domain.toLowerCase() === "gleason" ? "GPM" : "GSAG";
        }
      }
      return "codevance";
    },
    computedDropDownList(){
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.dropdownCardList = [{
            label: this.$t("course.edit"),
            iconSrc:  require('/public/img/icons/dropdown-icons/edit_black_24dp.svg'),
            clickCallback: () => this.requestEditCourse()
          },
          {
            label: this.$t("deleteCourse"),
            iconSrc:  require('/public/img/icons/dropdown-icons/delete_black_24dp.svg'),
            clickCallback: () => this.requestDeleteCourse()
          },
      ]
    },

  },

  mounted(){
      if (this.$store.getters.role.toLowerCase() === "admin" || this.$store.getters.role.toLowerCase() === "admin_light") this.isAdmin = true;
      this.setPosition();
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.card-header-container{
  height: 70px;
  overflow-x:hidden;
  text-overflow: ellipsis;
  max-height:100px;
}

.card-header-container h3{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: auto;
  text-overflow: ellipsis;
}

.dropdown-container{
  position: absolute;
  top:5px;
  right:5px;
  z-index: 1;
}

  button {
    width: 40px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid black;
    background-color: transparent;
  }

  .card-container{
    border: 1px solid var(--brightgrey);
    -webkit-box-shadow: 0 0 5px rgb(169, 191, 203);
    -moz-box-shadow: 0 0 5px rgb(169, 191, 203);
    box-shadow: 0 0 5px rgb(169, 191, 203);
    border-radius: 8px;
    position: relative;
    z-index: 1;
    background-color: var(--bright);
  }

  .inner-card-container:hover h3{
    text-decoration: underline;
    color: var(--primary-color);
    cursor:pointer;
  }

  .inner-card-container:hover{
    cursor: pointer;
  }

  .img-container{
    height:150px;
    background-color: whitesmoke;
    border-radius:10px;
  }

  .img-container > img{
    height:150px;
    width:100%;
    object-fit: cover;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .inner-card-container{
    padding: 20px;
    background-color: var(--bright);
  }

  .card-wrapper{
    display: flex;
    justify-content: space-between;
  }

  .card-wrapper span {
    display: flex;
    align-items: center;
  }

  h3{
    margin-top: 0;
    margin-bottom: 15px;
  }

  .container-inactive{
    border:1px solid white;
    border-radius:5px;
    z-index: 10;
    background-color: red;
    color:white;
  }

/*noinspection CssInvalidFunction*/
.compulsory{
  border:1px solid white;
  border-radius:5px;
  z-index: 10;
  background-color: orange;
  color:white;
  top:v-bind('top');
}

.organisation-tag{
  border:1px solid white;
  border-radius:5px;
  z-index: 10;
  background-color: #616161;
  color:#ececec;
}

  .tags-container{
    display: flex;
    margin-bottom: 10px;
    height: 24px;
    max-height:24px;
    max-width: 100%;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-y:hidden;
  }

  .tags{
    background-color: green;
    color:white;
    padding:5px;
    font-size:12px;
    border-radius:5px;
    font-weight: bold;
    margin-right: 5px;
  }

  .tags:last-child{
    margin-right:0;
  }

  svg{
    color: var(--darkgrey);
  }

  @media screen and (max-width: 850px) {

    .img-container {
      width: 100%;
    }

    .img-container > img{
      width: 100%;
    }
  }

</style>
